import React, {useEffect, useState,useRef, useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import Select,  { StylesConfig }  from 'react-select'
import chroma from 'chroma-js';
import CaptureIcon from '../../assetes/images/icons/capture.svg'
import selectIcon from '../../assetes/images/icons/complete.svg'
import {changeAvatar, changeAvatarUpload, getAvatarUploadUrl, listAvatar, uploadAvatar} from "./avatarSlice";
import {toast, ToastContainer} from "react-toastify";
import {changeLanguageLMS, getSoProfile, updateAvatarUser} from "../../Features/authSlice";
import {getFileUploadUrlAPI, getFileUploadUrlS3API} from "../../services/FileService";
import WebCamModal from "../modal/WebCamModal";
import WebCamAvatarModal from "../modal/WebCamAvatarModal";
import SwitchSimple from "../switchs/SwitchSimple";
import {createTicket, createTicketV2, getAllLocation, getListTicket} from "./SupportSlice";
import fileUploadIcon from '../../assetes/images/icons/fileUploadIcon.svg'
import closeIcon from '../../assetes/images/icons/closeIcon3.svg'
import loadingIcon from '../../assetes/images/icons/loading.png'
import axios from "axios";
export default function SupportModal({closeModal}){
    const user = useSelector(state=> state.auth.user)
    const dispatch = useDispatch()
    const userInfo = useSelector(state=> state.auth.user)
    const [textSupport,setTextSupport] = useState("")
    const [title,setTitle] = useState('')
    const soProfile = useSelector(state=>state.auth.soProfile)
    const [fileUpload,setFileUpload] = useState([])
    const locations = useSelector(state=>state.support.locations)
    const [otps,setOtps] = useState([])
    const [locationSelect, setLocationSelect] = useState(null)
    const [loading,setLoading] = useState(false)
    const onDrop = useCallback(acceptedFiles => {
        setFileUpload(files => ([...files, ...acceptedFiles ]))

    }, [fileUpload])

    const dot = (color = 'transparent') => ({
        alignItems: 'center',
        display: 'flex',

        ':before': {
            backgroundColor: color,
            borderRadius: 10,
            content: '" "',
            display: 'block',
            marginRight: 8,
            height: 10,
            width: 10,
        },
    });

    const colourStyles = {
        control: (styles) => ({ ...styles, backgroundColor: '#E9EFF7', borderRadius:10 ,outlineStyle:'#E9EFF7', outlineWidth:0, marginTop: 3, marginBottom:6 }),
        input: (styles) => ({ ...styles, ...dot(), borderRadius: 30 }),
        placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
        singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
    };

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept: {
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/png': ['.png']
        }
        ,onDrop
    })
    useEffect(()=>{
        dispatch(getSoProfile())
    },[])

    useEffect(()=>{
        dispatch(getAllLocation())
    },[])

    useEffect(()=>{
        let options = []
        if(locations.length>0){
            for(let local of locations){
                options.push({
                    value:local.id,
                    label: local.name
                })
            }
            setOtps(options)

        }else{
            setOtps([])
        }
    },[locations])

    const {t, i18n}= useTranslation()

    // const handleCreateTicket = ()=>{
    //     dispatch(createTicket(textSupport)).unwrap().then(data=>{
    //         toast.success(t('support.message'))
    //         closeModal()
    //     })
    // }

    const handlePreview =(file)=>{
        return URL.createObjectURL(file)
    }

    const handleRemoveFile = (index)=>{
        let _files = [...fileUpload]
        _files.splice(index,1)
        setFileUpload(_files)
    }

    const handleCreateTicket=async()=>{
        try{
            setLoading(true)
            let fileUrls = []
            if(title ==""){
                setLoading(false)
                return toast.error(t('ticket.ErrrorTitle'))
            }
            if(textSupport==""){
                setLoading(false)
                return toast.error(t('ticket.errorDesription'))
            }
            if(locationSelect== null || locationSelect==undefined){
                setLoading(false)
                return toast.error(t('ticket.errorLocation'))
            }
            if(fileUpload.length >0){
                for(let file of fileUpload){
                    let fileType = file.type
                    let fileUrl = await getFileUploadUrlS3API(file.name, file.type)
                    await axios.put(fileUrl.data.signedRequest,file, {
                        headers: { "Content-Type": file.type },
                    });
                    fileUrls.push({
                        path: fileUrl.data.url,
                        type: file.type
                    })
                }
                dispatch(createTicketV2({
                    title:title,
                    description: textSupport,
                    imageUrls: fileUrls,
                    location:locationSelect
                })).unwrap().then(ticket=>{
                    setLoading(false)
                    dispatch(getListTicket())
                    toast.success(t('ticket.createSuccess'))
                    closeModal()

                }).catch(err=>{
                    setLoading(false)
                    toast.error(t('ticket.createError'))
                    closeModal()
                })
            }else{
                dispatch(createTicketV2({
                    title:title,
                    description: textSupport,
                    location:locationSelect,
                    imageUrls: []
                })).unwrap().then(ticket=>{
                    setLoading(false)
                    dispatch(getListTicket())
                    toast.success(t('ticket.createSuccess'))
                    closeModal()
                }).catch(err=>{
                    setLoading(false)
                    toast.error(t('ticket.createError'))
                    closeModal()
                })
            }
        }catch(err){
            setLoading(false)
        }
    }


    return <div className={'w-full px-5 py-5 '}>
        <div className={'w-full mb-3 justify-center flex text-black2 text-[18px] font-semibold'}><span>{t('support.title')}</span></div>
        <input placeholder={t('ticket.title')} value={title} onChange={(e)=>{setTitle(e.target.value)}} className={'bg-E9EFF7 text-[14px] p-2 w-full rounded-xl  mb-3'}/>
        <textarea value={textSupport} placeholder={t('ticket.detail')} onChange={event => {setTextSupport(event.target.value)}} className={'bg-E9EFF7 min-h-[100px] text-[14px] p-2 w-full rounded-xl'} >
        </textarea>
        <Select
            onChange={option => {
                setLocationSelect(option.value)
            }
            }
            placeholder={t('ticket.selectPlaceHolder')} options={otps}  styles={colourStyles} />
        <div className={'border-dashed  border-2 rounded-xl min-h-[100px] mb-3 p-3 text-[12px]'} {...getRootProps()}>
            <input {...getInputProps()} />
            {
                isDragActive ?
                    <p>Drop the files here ...</p> :
                    <div className={'flex justify-center flex-col items-center'}>
                        <img className={'w-[40px] h-[40px]'} src={fileUploadIcon} />
                        <p className={'text-center'}>Drag 'n' drop some files here, or click to select files</p>
                    </div>

            }

        </div>
        <div className={'grid grid-cols-4 gap-3 mb-3'}>
            {fileUpload && fileUpload.map((file, idx)=><div key={idx} className={'relative'}>
                <img className={'w-full h-[40px] rounded border-2'}  src={handlePreview(file)}/>
                <button
                    onClick={()=>{handleRemoveFile(idx)}}
                    className={'absolute top-0 right-0'}><img src={closeIcon}/></button>
            </div>)}
        </div>
        <div className={'grid grid-cols-2 gap-3'}>
            <button onClick={closeModal} className={'px-3 py-2 bg-white text-black min-w-[100px] border rounded-xl col-span-1'}>
                {t('ticket.cancelBtn')}
            </button>
            <button onClick={handleCreateTicket} className={'px-3 py-2 bg-bgcolor7 text-white min-w-[100px] rounded-xl col-span-1'}>
                {loading?<img   src={loadingIcon} className={'w-4 animate-spin'}/>:<span>{t('support.send')}</span>}
            </button>
        </div>
        <ToastContainer />


    </div>
}
