import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {countUnreadCommentAPI, getTeacherCommentAPI, readCommentAPI} from "../../services/TeacherCommentService";

const initState = {
    state: 'idle',
    comments:[],
    unreadCount:0
}


export const getComments = createAsyncThunk('teacherComment/getComments', async({date,teacherEmail})=>{
    let comments = await getTeacherCommentAPI(date, teacherEmail)
    return comments.data
})

export const countUnreadComment= createAsyncThunk('teacherComment/countUnreadComment', async()=>{
    let numberUnread = await countUnreadCommentAPI()
    return numberUnread.data
})

export const readComment = createAsyncThunk('teacherComment/readComment', async(commentId)=>{
    let readComment= await readCommentAPI(commentId)
    return commentId
})

const teacherCommentSlice= createSlice({
    name: 'teacherComment',
    initialState: initState,
    reducers:{},
    extraReducers: builder => {
        builder.addCase(getComments.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getComments.fulfilled, (state,action)=>{
            state.status= 'idle'
            state.comments= action.payload
        }).addCase(getComments.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(countUnreadComment.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(countUnreadComment.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.unreadCount= action.payload
        }).addCase(countUnreadComment.rejected,(state,action)=>{
            state.status = 'idle'
        }).addCase(readComment.pending, (state,action)=>{
            state.status='pending'
        }).addCase(readComment.fulfilled, (state,action)=> {
            state.status = 'idle'
            let _comments = [...state.comments]
            _comments = _comments.map(x=>{
                if(x.soCommentId== action.payload){
                    x.state= 'read'
                    x.viewAll= true
                }
                return x
            })
            state.comments = [..._comments]
        }).addCase(readComment.rejected, (state,action)=>{
            state.status ='idle'
        })
    }
})

export default teacherCommentSlice.reducer
