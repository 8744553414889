import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    getAllPageViewAPI,
    getLearningHistoryPreviewAPI,
    getLearningHistoryViewAPI, getListLetterPublishViewAPI,
    getPreviewAllPageAPI,
    getProfilePreviewAPI,
    getProfileViewAPI,
    listCertViewAPI, listCertViewPublishAPI, listCurricularPreviewAPI, listCurricularViewAPI,
    viewAdDocAPI, viewAdDocPublishAPI,
    viewCertAPI, viewCertPublishAPI,
    viewGradeSheetAPI,
    viewGradeSheetPublishAPI
} from "../../../services/PortfolioService";

const initialState = {
    status: 'idle',
    pages:[],
    profile:null,
    lh: null,
    certs:null,
    curriculars: null,
    introLetters: null
}

export const getAllPageView = createAsyncThunk('portfolioView/getAllPage', async(id)=>{
    let pages =  await  getAllPageViewAPI(id)
    return pages.data
})

export const getProfileView= createAsyncThunk('portfolioView/getProfileView', async({pageId, portfolioId})=>{
    let profile = await getProfileViewAPI(pageId,portfolioId)
    return profile.data
})

export const getLearningHistoryView = createAsyncThunk('portfolioView/getLearningHistory', async({pageId, portfolioId})=>{
    let lh = await getLearningHistoryViewAPI(pageId, portfolioId)
    return lh.data
})

export const viewGradeSheetPublish = createAsyncThunk('portfolioView/viewGradeSheet', async({type, lmsUserId,schoolYearId, portfolioId})=>{
    let url = await viewGradeSheetPublishAPI(type, schoolYearId, lmsUserId, portfolioId)
    return url.data
})

export const viewAdDocPublish = createAsyncThunk('portfolioView/viewAdDoc', async({docId,lmsUserId, portfolioId})=>{
    let url = viewAdDocPublishAPI(docId, lmsUserId, portfolioId)
    return (await url).data
})

export const listCertsPublish = createAsyncThunk('portfolioView/certs', async({pageId,portfolioId})=>{
    let certs = listCertViewPublishAPI(pageId,portfolioId)
    return (await certs).data
})

export const viewCertPublish = createAsyncThunk('portfolioView/viewCert', async({certId, lmsUserId, portfolioId})=>{
    let url= viewCertPublishAPI(certId, lmsUserId, portfolioId)
    return (await url).data
})


export const listCurricularView = createAsyncThunk('portfolioView/listCurricular', async({pageId, lmsUserId, portfolioId})=>{
    let listCurriculars = await listCurricularViewAPI(pageId,portfolioId, lmsUserId)
    return listCurriculars.data
})

export const getListLetterPublishView = createAsyncThunk('portfolioView/listLetter', async ({pageId, lmsUserId, portfolioId})=>{
    let letters = await  getListLetterPublishViewAPI(pageId,lmsUserId, portfolioId)
    return letters.data
})


const portfolioView= createSlice({
    name:'portfolioView',
    initialState:initialState,
    reducers:{},
    extraReducers: builder => {
        builder.addCase(getAllPageView.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getAllPageView.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(getAllPageView.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.pages=action.payload
        }).addCase(getProfileView.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getProfileView.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(getProfileView.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.profile=action.payload
        }).addCase(getLearningHistoryView.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getLearningHistoryView.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(getLearningHistoryView.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.lh=action.payload
        }).addCase(viewGradeSheetPublish.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(viewGradeSheetPublish.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(viewGradeSheetPublish.fulfilled, (state,action)=>{
            state.status = 'idle'
        }).addCase(viewAdDocPublish.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(viewAdDocPublish.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(viewAdDocPublish.fulfilled, (state,action)=>{
            state.status = 'idle'
        }).addCase(listCertsPublish.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(listCertsPublish.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(listCertsPublish.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.certs= action.payload
        }).addCase(viewCertPublish.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(viewCertPublish.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(viewCertPublish.fulfilled, (state,action)=>{
            state.status = 'idle'
        }).addCase(listCurricularView.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(listCurricularView.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(listCurricularView.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.curriculars= action.payload
        }).addCase(getListLetterPublishView.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getListLetterPublishView.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(getListLetterPublishView.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.introLetters= action.payload
        })
    }
})

export default portfolioView.reducer
