import {axiosPrivate} from "./AxiosService";

export const getTeacherCommentAPI=(date, emailTeacher)=>{
    return axiosPrivate({
        method:"GET",
        url:`/soComment/getComments?date=${date}&teacherEmail=${emailTeacher}`
    })
}

export const countUnreadCommentAPI=()=>{
    return axiosPrivate({
        method:"GET",
        url:`/soComment/countUnreadComment`
    })
}

export const readCommentAPI=(commentId)=>{
    return axiosPrivate({
        method:"PUT",
        url:'/soComment/readComment',
        data:{
            commentId: commentId
        }
    })
}
