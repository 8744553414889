const {axiosPrivate} = require("./AxiosService");
exports.allDiscussionAPI = (courseId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/discussion/alldiscussions?courseid=${courseId}`)
}

exports.createNewDiscussionTopicAPI=(courseId,sectionId, title, description, startAt,endAt,attachment)=>{
    let formData = new FormData()
    formData.append('courseId', courseId)
    formData.append('sectionId', sectionId)
    formData.append('title', title)
    formData.append('description', description)
    formData.append('startAt', startAt)
    formData.append('endAt', endAt)
    if(attachment){
        formData.append('attachment', attachment.file, attachment.fileName)
    }
    return axiosPrivate.post(`${process.env.REACT_APP_BACKEND}/discussion/creatediscussiontopic`, formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}

exports.getDiscussionTopicAPI = (courseId, discussionId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/discussion/discussiontopic?courseid=${courseId}&discussionid=${discussionId}`)
}

exports.postAEntryAPI = (courseId, topicId, message, attachment)=>{
    let formData = new FormData()
    formData.append('courseId', courseId)
    formData.append('topicId', topicId)
    formData.append('message', message)
    if(attachment){
        formData.append('attachment', attachment.file, attachment.fileName)
    }
    return axiosPrivate.post(`${process.env.REACT_APP_BACKEND}/discussion/postaentry`,formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}
exports.getTopicEntriesAPI = (courseId, topicId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/discussion/topicentries?courseid=${courseId}&topicid=${topicId}`)
}


exports.postAReplyAPI = (courseId, topicId, entryId, message, attachment)=>{
    let formData = new FormData()
    formData.append('courseId', courseId)
    formData.append('topicId', topicId)
    formData.append('entryId', entryId)
    formData.append('message', message)
    if(attachment){
        formData.append('attachment', attachment.file, attachment.fileName)
    }
    return axiosPrivate.post(`${process.env.REACT_APP_BACKEND}/discussion/postareply`,formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
}


exports.getEntryRepliesAPI = (courseId, topicId, entryId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/discussion/entryreplies?courseid=${courseId}&topicid=${topicId}&entryid=${entryId}`)
}


exports.changeSubscribeTopicAPI = (courseId, topicId,flag)=>{
    return axiosPrivate.put(`${process.env.REACT_APP_BACKEND}/discussion/changesubscribetopic`, {
        courseId: courseId,
        topicId:topicId,
        flag:flag
    })
}
exports.changeReadStatusAPI = (courseId, topicId,entryId,flag)=>{
    return axiosPrivate.put(`${process.env.REACT_APP_BACKEND}/discussion/changereadstateentry`, {
        courseId: courseId,
        topicId:topicId,
        entryId:entryId,
        flag:flag
    })
}

exports.deleteEntryAPI = (courseId, topicId, entryId)=>{
    return axiosPrivate.delete(`${process.env.REACT_APP_BACKEND}/discussion/deleteEntry?courseId=${courseId}&topicId=${topicId}&entryId=${entryId}`)
}

exports.editEntryAPI = (courseId, topicId, entryId, msg)=>{
    return axiosPrivate.put(`${process.env.REACT_APP_BACKEND}/discussion/editEntry`,{
        courseId: courseId,
        topicId: topicId,
        entryId: entryId,
        msg: msg
    })
}

exports.deleteTopicAPI = (courseId, topicId)=>{
    return axiosPrivate.delete(`${process.env.REACT_APP_BACKEND}/discussion/delTopic?courseId=${courseId}&topicId=${topicId}`)
}



