import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {assignmentInGroupAPI, groupAssignmentAPI} from "../services/AssignmentService";

const initialState={
    courseId:null,
    assignmentGroup:[],
    status: 'ide',
    assignmentStatus:'ide',
    error:null
}

export const getGroupAssignment = createAsyncThunk('assignment/groupassignment', async(dataInput)=>{
    let groupAssignment =await groupAssignmentAPI(dataInput.courseId)
    return {
        groupAssignment:groupAssignment.data,
        courseId: dataInput.courseId
    }
})

export const getAssignmentInGroup = createAsyncThunk('assignment/assignmentingroup',async (dataInput)=>{
    let assignments = await assignmentInGroupAPI(dataInput.courseId, dataInput.groupId)
    return {
        groupId: dataInput.groupId,
        assignments:assignments.data,
        courseId: dataInput.courseId
    }
})

export const assignmentSlice = createSlice({
    name:'assignment',
    initialState:initialState,
    reducers:{

    },
    extraReducers:builder=>{
        builder.addCase(getGroupAssignment.pending, (state, action)=>{
            state.status = 'loading'
        })
            .addCase(getGroupAssignment.rejected, (state,action)=>{
                state.status = 'ide'
                state.error = action.error.mesage
            })
            .addCase(getGroupAssignment.fulfilled, (state,action)=>{
                state.status = 'ide'
                state.assignmentGroup = action.payload.groupAssignment
                state.courseId = action.payload.courseId
            })
            .addCase(getAssignmentInGroup.pending,(state,action)=>{
                state.assignmentStatus='loading'
            })
            .addCase(getAssignmentInGroup.fulfilled,(state, action)=>{
                state.assignmentStatus='ide'
                let groups = [...state.assignmentGroup]
                for(let i=0; i< groups.length; i++){
                    if(groups[i].id == action.payload.groupId){
                        groups[i].assignments = action.payload.assignments
                    }
                }
                state.courseId = action.payload.courseId
                state.assignmentGroup = groups
            })
    }
})
