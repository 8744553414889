import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    addAMessageConversationAPI,
    createConversationAPI, deleteAMessageAPI, deleteConversationAPI,
    getUnreadCountAPI,
    listConversationAPI,
    singleConversationAPI, updateConversationStatusAPI, updateStarConversationAPI
} from "../../services/inboxService";


const initialState={
    listInbox:[],
    inboxSelect:null,
    unReadCount: null,
    status: 'ide',
    error:null,
    statusDetail:'ide',
    messageStatus: 'ide'
}

export const listConversation = createAsyncThunk('inbox/listconservation', async(input)=>{
    let conservations =await listConversationAPI(input.page, input.scope)
    return {
        conservations: conservations.data,
        page: input.page
    }
})

export const getConversationDetail = createAsyncThunk('inbox/singleconversation', async(input)=>{
    let conversation = await singleConversationAPI(input.conversationId)
    return {
        conversation: conversation.data
    }
})

export const createConversation = createAsyncThunk('inbox/createconversation', async(input)=>{
    let conversation = await createConversationAPI(input.recipients,input.subject, input.body,input.attachment_ids, input.sendMode)
    return {
        conversation: conversation.data
    }
})

export const addAMessage = createAsyncThunk('inbox/addamessage', async(input)=>{
    let message = await addAMessageConversationAPI(input.conversationId, input.recipients,  input.body, input.attachment_ids,  input.included_messages)
    return {
        message: message.data
    }
})

export const getUnreadCount = createAsyncThunk('inbox/getunreadcount', async(input)=>{
    let unreadCount = await getUnreadCountAPI()
    return {
        unreadCount: unreadCount.data
    }
})


export const deleteConversation = createAsyncThunk('inbox/deleteconversation', async(input)=>{
    await deleteConversationAPI(input.conversationId)
    return {
        conversationId: input.conversationId
    }
})

export const deleteAMessage = createAsyncThunk('inbox/deleteamessage', async(input)=>{
    await deleteAMessageAPI(input.conversationId, input.messageId)
    return {
        conversationId: input.conversationId,
        messageId: input.messageId
    }
})


export const updateStarConversation = createAsyncThunk('inbox/updatestarconversation', async(input)=>{
    await updateStarConversationAPI(input.conversationId, input.isStarred)
    return {
        conversationId: input.conversationId,
        isStarred: input.isStarred
    }
})

export const updateConversationState = createAsyncThunk('inbox/updateconversationstate', async(input)=>{
    await updateConversationStatusAPI(input.conversationId, input.state)
    return {
        conversationId: input.conversationId
    }
})



export const InboxSlice = createSlice({
    name: 'InboxSlice',
    initialState:initialState,
    reducers:{
        removeInboxSelect:(state)=>{
            state.inboxSelect = null
        }
    },
    extraReducers: builder => {
        builder.addCase(listConversation.pending, (state, action)=>{
            state.status = 'loading'
        }).addCase(listConversation.rejected, (state,action)=>{
            state.status = 'ide'
            state.error = action.error.message
        }).addCase(listConversation.fulfilled, (state, action)=>{
            state.status = 'ide'
            if(action.payload.page==1){
                state.listInbox = [...action.payload.conservations]
            }else{
                state.listInbox = [...state.listInbox, ...action.payload.conservations]
            }
        }).addCase(getConversationDetail.pending, (state, aciton)=>{
            state.statusDetail='loading'
        }).addCase(getConversationDetail.rejected, (state,action)=>{
            state.statusDetail = 'ide'
            state.error = action.error.message
        }).addCase(getConversationDetail.fulfilled, (state, action)=>{
            state.statusDetail = 'ide'
            state.error = null
            state.inboxSelect =  action.payload.conversation
            let _listInbox = [...state.listInbox]
            for(let i = 0; i< _listInbox.length; i++){
                if(_listInbox[i].id == action.payload.conversation.id){
                    _listInbox[i].workflow_state = 'read'
                }
            }
            state.listInbox = [..._listInbox]
        }).addCase(createConversation.pending, (state,action)=>{
            state.messageStatus = 'loading'
        }).addCase(createConversation.rejected, (state,action)=>{
            state.messageStatus = 'ide'
            state.error = action.error.message
        }).addCase(createConversation.fulfilled, (state,action)=>{
            state.messageStatus = 'ide'
        }).addCase(getUnreadCount.pending, (state,action)=>{
            state.status='loading'
        }).addCase(getUnreadCount.rejected, (state,action)=>{
            state.status = 'ide'
            state.error = action.error.message
        }).addCase(getUnreadCount.fulfilled, (state,action)=>{
            state.status = 'ide'
            state.unReadCount= action.payload.unreadCount
        }).addCase(addAMessage.pending, (state,action)=>{
            state.messageStatus = 'loading'
        }).addCase(addAMessage.rejected, (state,action)=>{
            state.messageStatus = 'ide'
            state.error = action.error.message
        }).addCase(addAMessage.fulfilled, (state,action)=>{
            state.messageStatus = 'ide'
        }).addCase(deleteConversation.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(deleteConversation.rejected, (state,action)=>{
            state.status = 'ide'
            state.error = action.error.message
        }).addCase(deleteConversation.fulfilled, (state, action)=>{
            state.status = 'ide'
            let _listInbox = [...state.listInbox]
            for(let i = 0; i< _listInbox.length; i++){
                if(_listInbox[i].id == action.payload.conversationId){
                    _listInbox.splice(i, 1)
                }
            }
            state.listInbox = [..._listInbox]
            state.inboxSelect = null
        }).addCase(deleteAMessage.pending, (state,action)=>{
            state.messageStatus = 'loading'
        }).addCase(deleteAMessage.rejected, (state,action)=>{
            state.status = 'ide'
            state.error = action.error.message
        }).addCase(deleteAMessage.fulfilled, (state,action)=>{
            state.status = 'ide'
        }).addCase(updateStarConversation.pending, (state, action)=>{
            state.status = 'loading'
        }).addCase(updateStarConversation.rejected, (state,action)=>{
            state.status = 'ide'
            state.error = action.error.messsage
        }).addCase(updateStarConversation.fulfilled, (state,action)=>{
            state.status = 'ide'
            let _conversations = state.listInbox
            for(let i = 0; i< _conversations.length; i++){
                if(_conversations[i].id== action.payload.conversationId){
                    _conversations[i].starred = action.payload.isStarred
                }
            }
            state.listInbox = [..._conversations]
        }).addCase(updateConversationState.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(updateConversationState.rejected, (state, action)=>{
            state.status = 'ide'
            state.error = action.error.message
        }).addCase(updateConversationState.fulfilled, (state, action)=>{
            state.status = 'ide'
            state.inboxSelect = null
            let _conversations = state.listInbox
            for(let i = 0; i< _conversations.length; i++){
                if(_conversations[i].id== action.payload.conversationId){
                    _conversations.splice(i,1)
                    break
                }
            }
            state.listInbox = [..._conversations]
        })

    }
})



export const {removeInboxSelect} = InboxSlice.actions
