import axios from "axios";
import {axiosPrivate} from "./AxiosService";

export const listAvatarAPI = ()=>{
    return axios.get(`${process.env.REACT_APP_BACKEND}/avatarlist.json`)
}

export const changeAvatarAPI = (url)=>{
    return  axiosPrivate.post(`${process.env.REACT_APP_BACKEND}/user/changeavatar`, {
        avatarurl: url
    })
}

export const getAvatarUploadUrlAPI= (name)=>{
    return axiosPrivate.post(`${process.env.REACT_APP_BACKEND}/user/getAvatarUploadUrl`,{
        name: name
    })
}

export const changeAvatarUploadAPI = (avatarId)=>{
    return axiosPrivate.post(`${process.env.REACT_APP_BACKEND}/user/changeAvatarUpload`, {
        avatarId: avatarId
    })
}


