import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getConversationFolderAPI, getFileUploadUrlAPI, uploadFileAPI} from "../../services/FileService";

const initialState = {
    conversationFolder: null,
    status :'ide',
    error: null
}


export const getConversationFolder = createAsyncThunk('conversationfile/conversationfolder',async(input)=>{
    let conversationFolder = await getConversationFolderAPI()
    return {conversationFolder: conversationFolder.data}
})

export const uploadFiles = createAsyncThunk('conversationFile/uploadFile', async(input)=>{
    let files = input.files
    let  file_ids= []
    for(let file of files){
        let fileUrl = await getFileUploadUrlAPI(input.folderId, file.fileName)
        let fileUploadRes = await uploadFileAPI(fileUrl.data.upload_params, file.file, fileUrl.data.upload_url)
        file_ids.push(fileUploadRes.data.id)
    }
    return {file_ids:file_ids}
})

export const ConversationFileSlice = createSlice({
    name:'ConversationFileSlice',
    initialState: initialState,
    reducers:{

    },
    extraReducers:builder => {
        builder.addCase(getConversationFolder.pending, (state, action)=>{
            state.status ='loading'
        }).addCase(getConversationFolder.rejected, (state,action)=>{
            state.status = 'ide'
            state.error = action.error.message
        }).addCase(getConversationFolder.fulfilled, (state,action)=>{
            state.error = null
            state.status = 'ide'
            state.conversationFolder = action.payload.conversationFolder
        }).addCase(uploadFiles.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(uploadFiles.fulfilled, (state,action)=>{
            state.status = 'ide'
        }).addCase(uploadFiles.rejected, (state,action)=>{
            state.status = 'ide'
        })
    }
})
