import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    getLearningHistoryPreviewAPI, getListLetterPublishAPI,
    getPreviewAllPageAPI,
    getProfilePreviewAPI, listCertViewAPI, listCurricularPreviewAPI, viewAdDocAPI, viewCertAPI, viewGradeSheetAPI
} from "../../../services/PortfolioService";
import {getListLetterPublish} from "../pageSections/IntroLetterSlice";

const initialState = {
    status: 'idle',
    pages:[],
    profile:null,
    lh: null,
    certs:null,
    curriculars: null,
    introLetters: null
}

export const getAllPagePreview = createAsyncThunk('epPreview/getAllPage', async(id)=>{
    let pages =  await  getPreviewAllPageAPI(id)
    return pages.data
})

export const getProfilePreview= createAsyncThunk('epPreview/getProfilePreview', async(id)=>{
    let profile = await getProfilePreviewAPI(id)
    return profile.data
})

export const getLearningHistory = createAsyncThunk('epPreview/getLearningHistory', async(id)=>{
    let lh = await getLearningHistoryPreviewAPI(id)
    return lh.data
})

export const viewGradeSheet = createAsyncThunk('epPreview/viewGradeSheet', async({type, lmsUserId,schoolYearId})=>{
    let url = await viewGradeSheetAPI(type, schoolYearId, lmsUserId)
    return url.data
})

export const viewAdDoc = createAsyncThunk('epPreview/viewAdDoc', async({docId,lmsUserId})=>{
    let url = viewAdDocAPI(docId, lmsUserId)
    return (await url).data
})

export const listCerts = createAsyncThunk('epPreview/certs', async(pageId)=>{
    let certs = listCertViewAPI(pageId)
    return (await certs).data
})

export const viewCert = createAsyncThunk('epPreview/viewCert', async({certId, lmsUserId})=>{
    let url= viewCertAPI(certId, lmsUserId)
    return (await url).data
})

export const listCurricularPreview = createAsyncThunk('epPreview/listCurricular', async(pageId)=>{
    let listCurriculars = await listCurricularPreviewAPI(pageId)
    return listCurriculars.data
})

export const getIntroLetterPreview = createAsyncThunk('eqPreview/letters',async (pageId)=>{
    let listIntroLetters = await getListLetterPublishAPI(pageId)
    return listIntroLetters.data
})

const epPreview= createSlice({
    name:'epPreview',
    initialState:initialState,
    reducers:{},
    extraReducers: builder => {
        builder.addCase(getAllPagePreview.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getAllPagePreview.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(getAllPagePreview.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.pages=action.payload
        }).addCase(getProfilePreview.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getProfilePreview.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(getProfilePreview.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.profile=action.payload
        }).addCase(getLearningHistory.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getLearningHistory.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(getLearningHistory.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.lh=action.payload
        }).addCase(viewGradeSheet.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(viewGradeSheet.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(viewGradeSheet.fulfilled, (state,action)=>{
            state.status = 'idle'
        }).addCase(viewAdDoc.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(viewAdDoc.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(viewAdDoc.fulfilled, (state,action)=>{
            state.status = 'idle'
        }).addCase(listCerts.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(listCerts.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(listCerts.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.certs= action.payload
        }).addCase(viewCert.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(viewCert.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(viewCert.fulfilled, (state,action)=>{
            state.status = 'idle'
        }).addCase(listCurricularPreview.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(listCurricularPreview.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(listCurricularPreview.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.curriculars= action.payload
        }).addCase(getIntroLetterPreview.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getIntroLetterPreview.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(getIntroLetterPreview.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.introLetters= action.payload
        })
    }
})

export default epPreview.reducer
