const {axiosPrivate} = require("./AxiosService");


exports.listConversationAPI= (page, scope)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/inbox/listconversation?page=${page}&scope=${scope}`)
}

exports.singleConversationAPI = (conversationId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/inbox/getsingleconversation?conversation=${conversationId}`)
}
exports.findRecipientAPI = (search, courseid, context)=>{
    if(courseid) {
        return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/inbox/findrecipient?search=${search}&courseid=${courseid}&context=${context}`)
    }else{
        return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/inbox/findrecipient?search=${search}&context=${context}`)
    }

}

exports.createConversationAPI = (recipients, subject, body, attachment_ids, sendMode)=>{
    return axiosPrivate.post(`${process.env.REACT_APP_BACKEND}/inbox/createconversation`,{
        recipients: recipients,
        subject: subject,
        body: body,
        attachment_ids: attachment_ids,
        sendMode:sendMode
    })
}


exports.getUnreadCountAPI= ()=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/inbox/getunreadcount`)
}


exports.addAMessageConversationAPI = (conversationId,recipients,  body, attachment_ids, sendMode, included_messages)=>{
    return axiosPrivate.post(`${process.env.REACT_APP_BACKEND}/inbox/addamessage`,{
        conversationId: conversationId,
        recipients:recipients,
        body: body,
        attachment_ids: attachment_ids,
        included_messages: included_messages
    })
}


exports.deleteConversationAPI = (conversationId=>{
    return axiosPrivate.post(`${process.env.REACT_APP_BACKEND}/inbox/deleteconversation`, {
        conversationId: conversationId
    })
})

exports.deleteAMessageAPI = (conversationId, messageId)=>{
    return axiosPrivate.post(`${process.env.REACT_APP_BACKEND}/inbox/deleteamessage`,{
        conversationId: conversationId,
        messageId: messageId
    })
}

exports.updateStarConversationAPI = (conversationId, isStarred)=>{
    return axiosPrivate.put(`${process.env.REACT_APP_BACKEND}/inbox/updatestarconversation`, {
        conversationId: conversationId,
        isStarred: isStarred
    })
}

exports.updateConversationStatusAPI = (conversationId, state)=>{
    return axiosPrivate.put(`${process.env.REACT_APP_BACKEND}/inbox/updateconversationstate`, {
        conversationId: conversationId,
        state: state
    })
}
