import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
    changeLanguageAPI,
    changeViewOldCourseAPI,
    getSoProfileAPI, getUserInfoAPI,
    loginApi,
    loginFromLMSApi,
    loginGoogleApi,
    logoutApi,
    refreshTokenApi, swapToV1API,
    turnOffGuideAPI,
    verifyTokenApi
} from '../services/AuthService'

const initialState={
    user:null,
    jwtToken: null,
    jwtRefeshtoken:null,
    status: 'ide',
    error:null,
    refreshError: null,
    soProfile: null,
    tokenExpired:false,
    isK5: false
}




export const getSoProfile = createAsyncThunk('auth/soProfile', async()=>{
    let profile = await getSoProfileAPI()
    return profile.data
})


export const changeLanguageLMS = createAsyncThunk('auth/changeLang', async(lang)=>{
    await changeLanguageAPI(lang)
    return lang
})

export const changeViewOldCourse= createAsyncThunk('auth/changeViewOldCourse', async(flag)=>{
    let res= await changeViewOldCourseAPI(flag)
    return res.data
})


export const login = createAsyncThunk('auth/Login', async (dataInput) =>{
    localStorage.setItem('turnoff', false)
    let data = await loginApi(dataInput.code, dataInput.v2,process.env.REACT_APP_CALLBACK_URL)
    return {
        jwtToken:data.data.access_token,
        jwtRefeshtoken: data.data.refresh_token,
        user:data.data.user,
        lmsV2:data.data.lmsV2
    }

})

export const loginFromLMS = createAsyncThunk('auth/loginFromLMS', async({code, lmsV2Url})=>{
    let data = await loginFromLMSApi(code, lmsV2Url)
    return {
        jwtToken:data.data.jwtToken,
        jwtRefeshtoken: data.data.jwtRefeshtoken,
        user:data.data.user,
        lmsV2:true,
        lmsV2Url: data.data.lmsV2Url
    }
})

export const loginGoogle = createAsyncThunk('auth/loginGoogle', async({code, scope})=>{
    let response = await loginGoogleApi(code, scope)
    return response.data
}
)

export const logOut = createAsyncThunk('auth/logout', async(dataInput)=>{
    localStorage.setItem('turnoff', false)
    let data = await logoutApi(dataInput.token)
    
    return {
        logout: 'ok'
    }
})


export const refreshToken = createAsyncThunk('auth/refreshtoken', async(dataInput)=>{
    let data = await refreshTokenApi(dataInput.token)
    return {
        jwtToken: data.data.access_token
    }
})

export const verifyToken = createAsyncThunk('auth/verifyToken', async()=>{
    let res = await  verifyTokenApi()
    return {
        data: res.data.verify
    }
})

export const swapToV1= createAsyncThunk('auth/swrapToV1', async()=>{
    let res = await swapToV1API()
    return res.data
})

export const getUserInfo = createAsyncThunk('auth/getUserInfo', async()=>{
    let res = await getUserInfoAPI()
    return res.data
})

export const turnOffGuide = createAsyncThunk('auth/turnOffGuide', async()=>{
    let res = await turnOffGuideAPI()
    return res.data
})

export const authSlice = createSlice({
    name: 'auth',
    initialState:initialState,
    reducers: {
        clearToken:(state)=>{
            state.user =null;
            state.jwtToken=null;
            state.tokenExpired= true
        },
        updateAvatarUser:(state, action)=>{
            let user = state.user
            user.avatar_url = action.payload
            state.user = user
        }
    },
    extraReducers:builder => {
        builder.addCase(login.pending, (state, action)=>{
            state.status ='loading'
        })
        .addCase(login.rejected, (state, action)=>{
            state.status = 'ide'
            state.error = action.error.message
            state.jwtRefeshtoken = null
            state.jwtToken = null
            state.user = null
            state.tokenExpired= true
        })
        .addCase(login.fulfilled, (state,action)=>{
            state.status = 'ide'
            state.jwtRefeshtoken= action.payload.jwtRefeshtoken
            state.jwtToken= action.payload.jwtToken
            state.user = action.payload.user
            state.tokenExpired= false
        })
            .addCase(logOut.pending, (state,action)=>{
                state.status='loading'
            })
            .addCase(logOut.rejected, (state,action)=>{
                state.status = 'ide'
                state.user = null
                state.jwtRefeshtoken = null
                state.jwtToken = null
                state.tokenExpired= true
            }).addCase(logOut.fulfilled, (state,action)=>{
                state.status = 'ide'
                state.user = null
                state.jwtRefeshtoken = null
                state.jwtToken = null
                state.tokenExpired= true
            }).addCase(refreshToken.pending,(state, action)=>{
                state.status = 'loading'
                state.refreshError = null
            }).addCase(refreshToken.rejected, (state,action)=>{
                state.status = 'ide'
                state.refreshError = action.error.message
                state.jwtToken = null
                state.jwtRefeshtoken = null
                state.user= null
                state.tokenExpired = true
            }).addCase(refreshToken.fulfilled, (state,action)=>{
                state.status = 'ide'
                state.jwtToken= action.payload.jwtToken
                state.tokenExpired= false
            })
            .addCase(verifyToken.rejected, (state, action)=>{
                state.error = action.error.message
                state.jwtToken = null
                state.jwtRefeshtoken = null
                state.user= null
                state.tokenExpired= true
            })
            .addCase(verifyToken.fulfilled, (state, action)=>{
                state.tokenExpired= false
            }).addCase(getSoProfile.pending,(state,action)=>{
                state.status = 'loading'
            }).addCase(getSoProfile.fulfilled, (state,action)=>{
                state.status = 'idle'
                state.soProfile = action.payload
                if(action.payload 
                    && action.payload.homeRoomClass 
                    && action.payload.homeRoomClass.match(/\d+/)[0] 
                    && action.payload.homeRoomClass.match(/\d+/)[0]  <6){
                        state.isK5= true
                    }else{
                        state.isK5=false
                    }
        }).addCase(getSoProfile.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(changeLanguageLMS.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(changeLanguageLMS.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.user.locale=action.payload
        }).addCase(changeLanguageLMS.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(swapToV1.pending,(state,action)=>{
            state.status = 'pending'
        }).addCase(swapToV1.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(swapToV1.fulfilled, (state,action)=>{
            state.status = 'idle'
        }).addCase(getUserInfo.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getUserInfo.fulfilled, (state,action)=>{
            state.status= 'idle'
            state.user  = action.payload
        }).addCase(getUserInfo.rejected, (state,action)=>{
            state.status= 'idle'
            state.jwtToken = null
            state.user= null
            state.tokenExpired = true
        }).addCase(turnOffGuide.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(turnOffGuide.rejected, (state,action)=>{
            state.status  ="idle"
        }).addCase(turnOffGuide.fulfilled, (state,action)=>{
            state.status = "idle"
            state.user.turnOffGuide= true
        }).addCase(changeViewOldCourse.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(changeViewOldCourse.rejected, (state,action)=>{
            state.status  ="idle"
        }).addCase(changeViewOldCourse.fulfilled, (state,action)=>{
            state.status = "idle"
            state.user.viewOldCourse= action.payload.flag
        }).addCase(loginGoogle.pending, (state,action)=>{
            state.status='pending'
        }).addCase(loginGoogle.rejected,(state,action)=>{
            state.status = 'ide'
            state.error = action.error.message
            state.jwtRefeshtoken = null
            state.jwtToken = null
            state.user = null
            state.tokenExpired= true
        }).addCase(loginGoogle.fulfilled, (state,action)=>{
            state.status = 'ide'
            state.jwtRefeshtoken= action.payload.jwtRefeshtoken
            state.jwtToken= action.payload.jwtToken
            state.user = action.payload.user
            state.tokenExpired= false
        }).addCase(loginFromLMS.pending, (state,action)=>{
            state.status='pending'
        }).addCase(loginFromLMS.rejected,(state,action)=>{
            state.status = 'ide'
            state.error = action.error.message
            state.jwtRefeshtoken = null
            state.jwtToken = null
            state.user = null
            state.tokenExpired= true
        }).addCase(loginFromLMS.fulfilled, (state,action)=>{
            state.status = 'ide'
            state.jwtRefeshtoken= action.payload.jwtRefeshtoken
            state.jwtToken= action.payload.jwtToken
            state.user = action.payload.user
            state.tokenExpired= false
        })

    }
})

export const { clearToken, updateAvatarUser } = authSlice.actions
