import {axiosPrivate} from "./AxiosService";

export const assignmentDetailAPI = (course_id, assignment_id)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/assignment/assignmentdetail?courseid=${course_id}&assignmentid=${assignment_id}&include[]=submission`)
}


export const groupAssignmentAPI = (course_id)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/assignment/assignmentgroups?courseid=${course_id}`)
}


export const assignmentInGroupAPI=(courseId, groupId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/assignment/assginmentingroup?courseid=${courseId}&groupid=${groupId}`)
}


export const allAssignmentInCourseAPI=(courseId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/assignment/allassignmentincourse?courseid=${courseId}`)
}
