import React, {useEffect, useState} from 'react'
import logo1 from '../../assetes/images/logos/logo1.svg'
import notify from '../../assetes/images/icons/notify.svg'
import homeIcon from '../../assetes/images/icons/home.svg'
import courseIcon from '../../assetes/images/icons/course-icon.svg'
import calendarIcon from '../../assetes/images/icons/calendar.svg'
import inboxIcon from '../../assetes/images/icons/inbox.svg'
import humbergurIcon from '../../assetes/images/icons/hambergur.svg'
import closeIcon from '../../assetes/images/icons/close.svg'
import {useDispatch, useSelector} from "react-redux";
import { Fragment } from 'react'
import {Link, useNavigate} from "react-router-dom";
import SidebarLv1 from "./sidebar/SidebarLv1";
import SidebarLv2 from "./sidebar/SidebarLv2";
import UserMenuDropdown from "./UserMenuDropdown";
import Logout from "./Logout";
import {clearToken, getSoProfile, getUserInfo, verifyToken} from "../../Features/authSlice";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {deleteTokenFCM, getTokenFCM, onMessageListener} from "../../configs/firebaseConfig";
import {getNotifies, getUnreadNotification, sendDeviceToken, setPage} from "./NotifySlice";
import NotifyMenuDropdown from "./NotifyMenuDropdown";
import {useTranslation} from "react-i18next";
import NotifySidebar from "./sidebar/NotifySidebar";
import SimpleModal from "../modal/SimpleModal";
import ProfileModal from "./ProfileModal";
import SupportModal from "./SupportModal";
import {countUnreadComment} from "../../pages/TeacherComments/TeacherCommentSlice";

export default function Header ({activepage}){
    const [tokenFound, setTokenFound] = useState(null);
    const dispatch = useDispatch()

    const notifies = useSelector(state=>state.notifyReducer.notifies)
    const page = useSelector(state=> state.notifyReducer.page)
    const [click,setClick] = useState(true)
    const [notifyClick,setNotifyClick] = useState(true)
    const [logoutFlag, setLogoutFlag] = useState(false)
    const userInfo = useSelector(state=> state.auth.user)
    const jwtToken = useSelector(state=>state.auth.jwtToken)
    const tokenExpired = useSelector(state=>state.auth.tokenExpired)
    const [lvSideBar,setlvSideBar] = useState('lv1')
    const { t, i18n } = useTranslation();
    const [openProfileModal, setOpenProfileModal] = useState(false)
    const [openSupportModal, setOpenSupportModal] = useState(false)
    const numberUnread = useSelector(state=>state.notifyReducer.numberUnread)
    const [tokenExpiredModal,setTokenExpiredModal] = useState(false)
    const closeProfileModal = ()=>{
        setOpenProfileModal(false)
    }

    const closeSupportModal = ()=>{
        setOpenSupportModal(false)
    }

    onMessageListener().then(payload => {
        toast.info(payload.notification.body)
        dispatch(countUnreadComment())
        dispatch(getUnreadNotification())
    }).catch(err =>{});

    const checkActive = (page)=>{
        if(activepage === page){
            return 'border-b-4 border-b-fontcolor8'
        }else{
            return 'hover: border-b-fontcolor8 hover:border-b-4'
        }
    }

    useEffect(()=>{
        if(userInfo && userInfo.locale == 'vi'){
            i18n.changeLanguage('vn');
        }else{
            i18n.changeLanguage('en');
        }
    },[userInfo])



    useEffect(()=>{
        getTokenFCM(setTokenFound);
    },[])

    useEffect(()=>{
        if(jwtToken){
            dispatch(getSoProfile())
        }
    },[jwtToken])

    useEffect(()=>{
        if(jwtToken){
            dispatch(getUserInfo())
        }
    },[jwtToken])

    useEffect(()=>{
        if(tokenFound){
            dispatch(sendDeviceToken(tokenFound)).unwrap().then(res=>{

            }).catch(err=>{
                deleteTokenFCM().then(data=>{
                    if(data){
                        getTokenFCM(setTokenFound)
                    }
                }).catch(err=>{
                    // toast.error('nofitication error')
                })
            })
        }
    },[tokenFound])

    useEffect(()=>{
        if(page){
            dispatch(getNotifies(page))
        }
    },[page])

    useEffect(()=>{
        dispatch(getUnreadNotification())
    },[])

    useEffect(()=>{
        dispatch(setPage(1))
    },[])

    useEffect(()=>{
        if(tokenExpired){
            setTokenExpiredModal(true)
        }else{
            setTokenExpiredModal(false)
        }
    },)

    const closeTokenExpireModal = ()=>{
        setTokenExpiredModal(false)
    }

    useEffect(()=>{
       dispatch(verifyToken())
    },[])



    return <nav className="bg-white top-0 z-10 sticky  w-full flex  h-[80px]">
        <div className="container mx-auto">
            <div className="flex justify-between">
                <div className="lg:hidden visible flex items-center">
                    <button id={'btncallspan'} onClick={()=>{setClick(!click)}} type="button"
                            className="inline-flex items-center p-2 ml-3 text-sm rounded-lg visible lg:hidden  "
                            aria-controls="mobile-menu"
                            aria-expanded="false"
                            data-collapse-toggle="mobile-menu">
                        {click?<img alt={'close'}   src={humbergurIcon}/>:<img alt={'close'} src={closeIcon}/>}
                    </button>
                </div>
                <div className="flex space-x-20">
                    <div className={' flex'}>
                        <a href="/" className="flex items-center py-1  lg: justify-center  ">
                            <img src={logo1} alt="Logo" className=" h-[71.23px] w-[90px]"/>
                        </a>
                    </div>
                    <div className="hidden lg:visible  lg:flex flex-row items-center space-x-20">
                        <Link to="/" className={`py-7  px-1  text-sm text-black  border-indigo-500 font-semibold inline-block ${checkActive('home')}`}>
                            <span className={"flex"}><img  src={homeIcon} alt={'home'} className={'pr-2 w-[28px] h-[21px] '} />{t('header.home')}</span>
                        </Link>
                        <Link to="/khoahoc" className={`py-7  px-1  text-sm text-black  border-indigo-500 font-semibold inline-block ${checkActive('course')}`}>
                            <span className={"flex"}><img src={courseIcon} alt={'home'} className={'pr-2  w-[28px] h-[21px]'} />{t('header.courses')}</span>
                        </Link>
                        <Link to="/thoikhoabieu" className={`py-7  px-1  text-sm text-black  border-indigo-500 font-semibold inline-block ${checkActive('schedule')}`}>
                            <span className={"flex"}><img src={calendarIcon} alt={'home'} className={'pr-2  w-[28px] h-[21px]'} />{t('header.schedule')}</span>
                        </Link>
                        <Link to="/inbox" className={`py-7  px-1  text-sm text-black  border-indigo-500 font-semibold inline-block ${checkActive('inbox')}`}>
                            <span className={"flex"}><img src={inboxIcon} alt={'home'} className={'pr-2  w-[28px] h-[21px]'} />{t('header.inbox')}</span>
                        </Link>
                    </div>
                </div>
                <div className="hidden lg:visible lg:flex items-center space-x-8 ">
                    <NotifyMenuDropdown notifies={notifies} numberUnread={numberUnread} />
                    <UserMenuDropdown setOpenSupportModal={setOpenSupportModal} handleLogout = {setLogoutFlag} setOpenProfileModal={setOpenProfileModal} />
                </div>
                <div className="lg:hidden visible flex items-center pr-4">
                    <button className="outline-none mobile-menu-button"
                            onClick={()=>{setNotifyClick(!notifyClick)}}
                    >
                        {notifyClick?<img alt={'notify'}   src={notify}/>:<img alt={'close'} src={closeIcon}/>}

                    </button>
                    {numberUnread && numberUnread >0  ?<span
                        className="absolute top-6 lg:top-1  inline-flex items-center justify-center px-1.5 py-0.5 font-light text-sm leading-none text-white transform translate-x-1/2 -translate-y-1/2  rounded-full bg-fontcolor1">{numberUnread}</span>:<></>}
                </div>
            </div>
            <div className={`${!click?"flex visible lg:hidden ":"hidden"} absolute top-20 justify-between  z-10 items-start pt-[50px] w-full h-screen max-h-fit backdrop-blur-lg`}
                 id="mobile-menu">
                {lvSideBar === 'lv1'?<SidebarLv1 userInfo={userInfo} changeLv={setlvSideBar}/>:<SidebarLv2 userInfo={userInfo} changeLv={setlvSideBar} setLogout={setLogoutFlag} setOpenProfile={setOpenProfileModal} setOpenSupportModal={setOpenSupportModal}/>}
            </div>
            <div className={`${!notifyClick?"flex visible lg:hidden ":"hidden"} absolute top-20 justify-between  z-10 items-start pt-[50px] w-full h-screen backdrop-blur-lg`}
                 id="mobile-menu">
                <NotifySidebar notifies={notifies}  click={notifyClick} setClick={setNotifyClick}/>
                {/*{lvSideBar === 'lv1'?<SidebarLv1 userInfo={userInfo} changeLv={setlvSideBar}/>:<SidebarLv2 userInfo={userInfo} changeLv={setlvSideBar} setLogout={setLogoutFlag}/>}*/}
            </div>

        </div>
        <div className={`${logoutFlag?"flex visible":"hidden"} absolute top-20 justify-between  z-10 items-start pt-[50px] w-full h-screen backdrop-blur-lg`}>
            <Logout logout={setLogoutFlag}/>
        </div>
        <SimpleModal  open={openProfileModal} setOpen={closeProfileModal} >
            <ProfileModal />
        </SimpleModal>
        <SimpleModal  open={openSupportModal} setOpen={closeSupportModal}>
            <SupportModal closeModal={closeSupportModal} />
        </SimpleModal>
        <SimpleModal  open={tokenExpiredModal} setOpen={closeTokenExpireModal}>
            <div className={'w-full px-5 py-5 '}>
                <div className={'w-full mb-3 justify-center flex text-black2 text-[18px] font-semibold'}><span>{t('tokenExpired.title')}</span></div>
                <p className={'text-[14px] py-3 '}>{t('tokenExpired.content')}</p>
                <div className={'flex justify-center'}>
                    <a onClick={()=>{window.location.href=`${process.env.REACT_APP_DOMAIN}/login`}} className={'px-3 hover:cursor-pointer py-2 bg-bgcolor7 text-white min-w-[100px] rounded-xl'}>
                        {t('tokenExpired.button')}
                    </a>
                </div>

            </div>
        </SimpleModal>

        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />
        {/* Same as */}
        <ToastContainer />

    </nav>
}
