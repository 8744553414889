import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Header from "../../components/navbar/Header";
import GoogleChat from '../../components/GoogleChat';
import SWBChat from '../../components/swb/SWBChat';
import Support from '../../components/Support';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUnitargets, setUnitTarget } from './CliseSlice';
import UnitargetStd from './UnitargetStd';
import StudentEvaluate from './StudentEvaluate';
import { toast } from 'react-toastify';
import { Dialog, Transition } from '@headlessui/react';
import StudentEvaluationTimline from './StudentEvaluationTimeline';
export default function Clise(){
  const {t, i18n}= useTranslation()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const unitargets = useSelector(state=>state.clise.unitargets)
  const [isOpen,setIsOpen] = useState(false)
  const [unitTargetSelect,setUnitargetSelect] = useState(null)
  const [page,setPage] = useState('home')


  useEffect(()=>{
    if(page=='home'){
      setLoading(true)
      dispatch(getUnitargets()).unwrap().then(data=>{
        setLoading(false)
      }).catch(err=>{
        toast.error(err.message)
        setLoading(false)
      })
    }
    
  },[page])


  const changeSelect = (unitarget)=>{
    for(let evl of unitarget.evaluations){
      if(evl.isFinal){
        for(let ut of evl.uts){
            if(!ut.isReopen && unitarget._id == ut.unitTargetBlueprintId){
                toast.error(t('This Unit Objective has been final assessed by the teacher. If you want to assess again, please contact teacher to reopen'))
                return
            }
        }
    }
      
  }
    let _unitTargets = JSON.parse(JSON.stringify([...unitargets]))
    for(let i=0; i< _unitTargets.length; i++){
        if(_unitTargets[i]._id == unitarget._id){
            if(_unitTargets[i].check){
                _unitTargets[i].check = false
            }else{
                _unitTargets[i].check = true
            }
        }
    }
    dispatch(setUnitTarget(_unitTargets))
}



  const handleSelectUnitarget = (unitTarget)=>{
    setUnitargetSelect(unitTarget)
    setIsOpen(true)
  }

  const createEvaluation =()=>{
    if(unitargets && unitargets.length >0){
        let check = false
        for(let target of unitargets){
            if(target.check){
                check= true
            }
        }
        if(!check){
            toast.error(t("No unit targets have been selected yet!"))
        }else{
            setPage('evaluation')
        }
    }
  }


  const closeModal=()=>{
    setIsOpen(false)
}



  return <div className={'w-full  min-h-screen flex flex-col h-screen max-h-screen bg-bgcoloz'}>
     <Header activepage={''}/>
     <div className={'container px-3  md:mx-auto py-3  w-full '}>
            <nav className="rounded-md w-full">
                <ol className="list-reset flex font-light">
                    <li><Link to="/" className="text-sm text-black hover:text-blue-700">{t('Course.home')}</Link></li>
                    <li><span className="text-sm text-black mx-2"> {'>'} </span></li>
                    <li><span  className="text-sm text-black">{t('Clise.assessment')}</span></li>
                </ol>
            </nav>
        </div>
        <div className={'container h-full flex-grow mx-auto bg-white  rounded-xl  mb-3 overflow-y-scroll scrollbar-hide p-3'}>
        <div>
             <h3 className={'text-black font-semibold text-[18px] '}>{t('Clise.assessment')}</h3>
          </div>
            {!loading?<>{unitargets&& unitargets.length >0?<>
              {page=='home'?<>
                {unitargets.map(x=><><UnitargetStd  setOpenDetail={handleSelectUnitarget}  uniTarget={x} handleSelect={changeSelect}/></>)}
                <div className='w-full flex justify-end py-5'>
                            <button onClick={createEvaluation} className='px-3 py-2 rounded-md text-white font-semibold bg-btnbg'>{t( "Create Assessment")}</button>
                        </div>

                </>:<>
                <StudentEvaluate unitTargets={unitargets} setPage={setPage} />


                </>}
              </>:<>
                  <h3 className='text-btnbg font-semibold'>{t("No unit targets have been opened as of now. Please contact CLISE subject teachers to check")}</h3>
              </>}
              </>:<>
              <h3 className='text-btnbg font-semibold'>{t("Loading")}...</h3>
              </>}




        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-20" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-[300px]  items-center justify-center p-4 text-center z-10">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full bg-timelineBg max-w-full transform overflow-auto rounded-2xl p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                  
                  </Dialog.Title>
                  <div className="mt-2 z-30">
                   <StudentEvaluationTimline unitarget={unitTargetSelect}  />
                  </div>
                  <div className="mt-4 flex justify-end space-x-3">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent btnbg px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                     {t("Close")}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
        </Transition>


        </div>
     <GoogleChat />
        <SWBChat/>
        <Support />
  </div>
}