import React, {Fragment} from 'react'
import {Menu, Transition} from "@headlessui/react";
import notify from "../../assetes/images/icons/notify.svg";
import AssignmentIcon from '../../assetes/images/icons/assignment.svg'
import QuizIcon from '../../assetes/images/icons/quiz.svg'
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {readNotify, setPage} from "./NotifySlice";
import {useTranslation} from "react-i18next";
import dicussionIcon from "../../assetes/images/icons/discussion.svg";
// import {randomColor} from "../../utils/utilService";
export default function NotifyMenuDropdown({notifies, numberUnread}){
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const status = useSelector(state=>state.notifyReducer.status)
    const page = useSelector(state=>state.notifyReducer.page)
    const randomColor=(id)=> {
        let mod = id % 32
        let color = ""
        switch (mod) {
            case 0:
                color = "#09BD3C"
                break
            case 1:
                color = "#BC2DFF"
                break
            case 2:
                color = "courseGCEDcolor"
                break
            case 3:
                color = "#6F28E3"
                break
            case 4:
                color = "#0099FF"
                break
            case 5:
                color = "#FC4DAB"
                break
            case 6:
                color = "#4680FD"
                break
            case 7:
                color = '#52E2AA'
                break
            case 8:
                color = '#4DDAFC'
                break
            case 9:
                color = '#FFB540'
                break
            case 10:
                color = '#E339DC'
                break
            case 11:
                color = '#FD4646'
                break
            case 12:
                color = '#CCD323'
                break
            case 13:
                color = '#264DA5'
                break
            case 14:
                color = '#BA5454'
                break
            case 15:
                color = '#D39DD1'
                break
            case 16:
                color = '#3CE3C9'
                break
            case 17:
                color = '#DE4949'
                break
            case 18:
                color = '#A5A526'
                break
            case 29:
                color = '#A2237B'
                break
            case 20:
                color = '#FF9400'
                break
            case 21:
                color = '#20D4CA'
                break
            case 22:
                color = '#8541D5'
                break
            case 23:
                color = '#76CC00'
                break
            case 24:
                color = '#FF2600'
                break
            case 25:
                color = '#5C6CFF'
                break
            case 26:
                color = '#00A586'
                break
            case 27:
                color = '#00A4FF'
                break
            case 28:
                color = '#FFD200'
                break
            case 29:
                color = '#FF5A42'
                break
            case 30:
                color = '#B742E1'
                break
            default:
                color = '#00B65B'
                break
        }
        return color
    }
    const getIcon =(type)=>{
        let icon =null

        switch (type){
            case "assignment":
                icon = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
                    <path id="assignment" d="M11.47,0V4.412h-.882V.882H.882V14.117h9.706V12.353h.882V15H0V0Zm1.011,4.541a.441.441,0,0,1,.625,0l1.765,1.765a.441.441,0,0,1,0,.624L9.577,12.224a.444.444,0,0,1-.312.129H7.5a.441.441,0,0,1-.441-.441V10.147a.441.441,0,0,1,.129-.311ZM11.268,7,7.941,10.33v1.141H9.082l3.327-3.327L11.268,7Zm-5.974,2.7v.882H2.647V9.706H5.294ZM7.059,7.941v.882H2.647V7.941H7.059Zm5.735-2.464-.9.9,1.141,1.141.9-.9L12.794,5.477ZM8.823,2.647V6.176H2.647V2.647H8.823Zm-.882.882H3.529V5.294H7.941V3.529Z" fill="#ffffff" fill-rule="evenodd"/>
                </svg>
                break
            case "quiz":
                icon = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14.342" viewBox="0 0 15 14.342">
                    <g id="quiz" transform="translate(0 -42)">
                        <path id="Path_10257" data-name="Path 10257" d="M5.83,53.131l.629.631L4.516,55.7l-.629-.63Zm-1.3-1.3.63.63L2.573,55.056l-.63-.63,2.591-2.591Zm-1.3-1.295.63.63L.63,54.409,0,53.779l3.238-3.238Zm5.506-6.8a5.062,5.062,0,0,1,5.7-1.379l.134.066.066.134a5.061,5.061,0,0,1-1.38,5.7L12.115,49.4l-.02,5.2-1.739,1.739-2.591-2.59L3.248,49.236.657,46.644,2.4,44.9l5.2-.02Zm2.476,6.556L8.394,53.123l1.961,1.96.851-.851Zm2.68-7.2a4.2,4.2,0,0,0-4.526,1.272L4.507,49.236l3.257,3.257,4.866-4.867A4.223,4.223,0,0,0,13.9,43.1ZM6.7,45.78l-3.937.014-.851.851,1.961,1.962L6.7,45.779Z" fill="#ffffff" fill-rule="evenodd"/>
                        <path id="Path_10258" data-name="Path 10258" d="M1283.206,372.472a2.243,2.243,0,0,0-1.666.737l-.316.315,1.355,1.354.315-.314a2.21,2.21,0,0,0,.741-1.665l-.017-.411Z" transform="translate(-1271.214 -327.89)" fill="#ffffff" fill-rule="evenodd"/>
                    </g>
                </svg>
                break
            case "ticket":
                icon = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14.744" viewBox="0 0 20 20">
                    <path id="Vector" d="M10,0a10.011,10.011,0,1,0,7.068,2.932A10,10,0,0,0,10,0Zm0,16.154a1.154,1.154,0,1,1,.816-.338A1.154,1.154,0,0,1,10,16.154Zm.769-4.7v.086a.769.769,0,0,1-1.538,0V10.77A.769.769,0,0,1,10,10,1.923,1.923,0,1,0,8.077,8.077a.769.769,0,0,1-1.538,0,3.462,3.462,0,1,1,4.231,3.375Z" fill="#525252"/>
                </svg>
                break
            default:
                icon = <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14.744" viewBox="0 0 15 14.744">
                    <path id="discussion" d="M5.294,16v2.648h.882V16.883h7.941v6.176H12.352v2.029l-2.029-2.029H9.7V19.53H0v7.941H2.646v3.273l3.266-3.273H9.7v-3.53h.256l3.274,3.274V23.941H15V16ZM2.647,23.059H7.059v-.882H2.647Zm0,1.765H5.295v-.883H2.647ZM.882,20.412H8.824v6.177H5.55L3.529,28.618V26.589H.882V20.412Z" transform="translate(0 -16)" fill="#525252" fill-rule="evenodd"/>
                </svg>
        }
        return icon
    }

    const  handleClick=(notify)=>{
        dispatch(readNotify(notify._id)).unwrap().then(data=>{
            let link = '#'
            switch (notify.type){
                case 'assignment':
                    link = `/khoahoc/${notify.course_id}/kiemtra/${notify.context_id}`
                    break
                case 'quiz':
                    link = `/khoahoc/${notify.course_id}/baitap/${notify.context_id}`
                    break
                case 'teacherComment':
                    link = '/comments'
                    break
                case 'ticket':
                    link = `/tickets/${notify.content}`
                    break
                default:
                    link = '#'
                    break
            }
            navigate(link)
        }).catch(err=>{
            toast.error(err.message)
        })

    }

    const handle_scroll = (e)=>{
        if(e.target.scrollHeight-3 < e.target.scrollTop + e.target.offsetHeight && status!='loading' ){
            dispatch(setPage(page+1))
        }
    }

    return <div>
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <Menu.Button className=" w-full px-4 py-2  text-sm font-medium
                                focus:outline-none">
                        <img alt={'notify'}  src={notify} />
                    </Menu.Button>
                    {numberUnread ?<span
                        className="absolute top-1 right-4 inline-flex items-center justify-center px-1.5 py-0.5 font-light text-sm leading-none text-white transform translate-x-1/2 -translate-y-1/2  rounded-full bg-fontcolor1">{numberUnread}</span>:<></>}
                </div>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                        <Menu.Items  onScroll={e=>{handle_scroll(e)}} className="origin-top-right absolute right-0 mt-2 w-[400px] max-h-[500px] overflow-y-scroll scrollbar-hide rounded-md divide-y divide-gray-100  shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">

                            {notifies.map(x=><div key={x._id} className="py-1 ">
                                <Menu.Item as={'div'} className={'flex  space-x-4 px-3 py-2'}>
                                    <div className={`flex items-center w-[40px]`}>
                                        <div className={` rounded-full p-2 w-[32px]  `} style={{background:randomColor(x.course_id)}}>
                                            {/*{x.type=='assignment'?<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">*/}
                                            {/*    <path id="assignment" d="M11.47,0V4.412h-.882V.882H.882V14.117h9.706V12.353h.882V15H0V0Zm1.011,4.541a.441.441,0,0,1,.625,0l1.765,1.765a.441.441,0,0,1,0,.624L9.577,12.224a.444.444,0,0,1-.312.129H7.5a.441.441,0,0,1-.441-.441V10.147a.441.441,0,0,1,.129-.311ZM11.268,7,7.941,10.33v1.141H9.082l3.327-3.327L11.268,7Zm-5.974,2.7v.882H2.647V9.706H5.294ZM7.059,7.941v.882H2.647V7.941H7.059Zm5.735-2.464-.9.9,1.141,1.141.9-.9L12.794,5.477ZM8.823,2.647V6.176H2.647V2.647H8.823Zm-.882.882H3.529V5.294H7.941V3.529Z" fill="#ffffff" fill-rule="evenodd"/>*/}
                                            {/*</svg>:<svg xmlns="http://www.w3.org/2000/svg" width="15" height="14.342" viewBox="0 0 15 14.342">*/}
                                            {/*    <g id="quiz" transform="translate(0 -42)">*/}
                                            {/*        <path id="Path_10257" data-name="Path 10257" d="M5.83,53.131l.629.631L4.516,55.7l-.629-.63Zm-1.3-1.3.63.63L2.573,55.056l-.63-.63,2.591-2.591Zm-1.3-1.295.63.63L.63,54.409,0,53.779l3.238-3.238Zm5.506-6.8a5.062,5.062,0,0,1,5.7-1.379l.134.066.066.134a5.061,5.061,0,0,1-1.38,5.7L12.115,49.4l-.02,5.2-1.739,1.739-2.591-2.59L3.248,49.236.657,46.644,2.4,44.9l5.2-.02Zm2.476,6.556L8.394,53.123l1.961,1.96.851-.851Zm2.68-7.2a4.2,4.2,0,0,0-4.526,1.272L4.507,49.236l3.257,3.257,4.866-4.867A4.223,4.223,0,0,0,13.9,43.1ZM6.7,45.78l-3.937.014-.851.851,1.961,1.962L6.7,45.779Z" fill="#ffffff" fill-rule="evenodd"/>*/}
                                            {/*        <path id="Path_10258" data-name="Path 10258" d="M1283.206,372.472a2.243,2.243,0,0,0-1.666.737l-.316.315,1.355,1.354.315-.314a2.21,2.21,0,0,0,.741-1.665l-.017-.411Z" transform="translate(-1271.214 -327.89)" fill="#ffffff" fill-rule="evenodd"/>*/}
                                            {/*    </g>*/}
                                            {/*</svg>*/}
                                            {/*}*/}
                                            {getIcon(x.type)}
                                        </div>
                                    </div>
                                    <div className={'flex flex-1 flex-col'}>
                                    <span
                                        onClick={()=>{handleClick(x)}}
                                        className={`${x.read?"font-normal":"font-semibold"} text-[14px] text-black2 hover:cursor-pointer hover:text-fontcolor4`}>{x.context_name}</span>
                                        <span className={'font-normal text-[12px] text-fontcolor2'}>{x.course_name}</span>
                                    </div>
                                </Menu.Item>
                            </div>)}
                            {notifies.length==0 && <div>
                                <Menu.Item as={'div'} className={'flex  space-x-4 px-3 py-2'}>
                                    <div className={`flex items-center w-[40px]`}>

                                    </div>
                                    <div className={'flex flex-1 flex-col'}>
                                    <span>{t('notiDropdow.empty')}</span>
                                    </div>
                                </Menu.Item>
                            </div>}


                        </Menu.Items>


                </Transition>
            </Menu>
        </div>


}

