import React, {useEffect, useState} from 'react'
import vectorIcon from "../assetes/images/icons/Vector2.svg";
export default function Support(){
    let [isHove, setIsHovve] = useState(false)

    const handleGetLink = ()=>{
        window.open('/tickets')
    }



    return <div id={'googleChat'}  className={'fixed  bottom-[100px] md:bottom-[110px] right-[25px] z-50  flex justify-center items-center space-x-3'}>
        <div   className={`bg-649AD0 text-white px-3 py-1  rounded-xl ${isHove?'block':'hidden'}`}>
            <span>Support</span>
        </div>
        <button onClick={handleGetLink} onMouseEnter={()=>{setIsHovve(true)}}
                onMouseLeave={()=>{setIsHovve(false)}}
        ><img className={' w-[30px] md:w-[40px]'} src={vectorIcon}/></button>
    </div>

}
