import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    createNewSupportAPI,
    createNewSupportV2API,
    getAllLocationAPI,
    getListTicketAPI
} from "../../services/SupportService";

const initState = {
    status : 'idle',
    locations: [],
    tickets:[]
}

export const createTicket = createAsyncThunk('support/createTicket',async (content)=>{
    let ticket = await createNewSupportAPI(content)
    return ticket.data
})

export const createTicketV2 = createAsyncThunk('support/createTicketV2', async({title, description, imageUrls, location})=>{
    let ticket = await createNewSupportV2API(title, description,imageUrls)
    return ticket.data
})

export const getAllLocation = createAsyncThunk('support/getAllLocation', async()=>{
    let locations = await getAllLocationAPI()
    return locations.data
})

export const getListTicket = createAsyncThunk('support/getListTicket', async()=>{
    let tickets = await getListTicketAPI()
    return tickets.data
})

const SupportSlice = createSlice({
    name:'support',
    initialState: initState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(createTicket.pending, (state, action)=>{
            state.status = 'loading'
        }).addCase(createTicket.fulfilled, (state,action)=>{
            state.status = 'idle'
        }).addCase(createTicket.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(createTicketV2.pending, (state, action)=>{
            state.status = 'loading'
        }).addCase(createTicketV2.fulfilled, (state,action)=>{
            state.status = 'idle'
        }).addCase(createTicketV2.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(getAllLocation.pending, (state, action)=>{
            state.status = 'loading'
        }).addCase(getAllLocation.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.locations = action.payload
        }).addCase(getAllLocation.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(getListTicket.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(getListTicket.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(getListTicket.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.tickets= action.payload
        })
    }
})

export default SupportSlice.reducer
