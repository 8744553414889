import {axiosPrivate} from "./AxiosService";

export const createNewSupportAPI = (content)=>{
    return axiosPrivate.post(`${process.env.REACT_APP_BACKEND}/ticket/createNewTicket`,{
        content:content
    })
}

export const createNewSupportV2API=(title, description, imageUrls, location)=>{
    return axiosPrivate.post(`${process.env.REACT_APP_BACKEND}/ticket/createTicketV2`,{
        title: title,
        description: description,
        imageUrls: imageUrls,
        location: location
    })
}


export const getAllLocationAPI = ()=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/ticket/getListLocation`)
}


export const getListTicketAPI=()=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/ticket/listTicket`)
}

export const getTicketDetailAPI =(ticketId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/ticket/ticketDetail?ticketId=${ticketId}`,)
}


export const getFollowUpAPI = (ticketId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/ticket/getFollowUp?ticketId=${ticketId}`)
}


export const addFollowUpAPI = (ticketId, message, fileUpload)=>{
    return axiosPrivate.post(`${process.env.REACT_APP_BACKEND}/ticket/addFollowUp`,{
        ticketId:ticketId,
        message: message,
        fileUpload:fileUpload
    })
}

