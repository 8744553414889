import React, {useEffect, useState} from 'react'
import googeChatIcon from '../assetes/images/logos/googleChat.png'
export default function GoogleChat(){
    let [isHove, setIsHovve] = useState(false)

    const handleGetLink = ()=>{
        window.open('https://mail.google.com/chat/u/0/#chat/welcome')
    }



    return <div id={'googleChat'}  className={'fixed  bottom-[200px] md:bottom-[250px] right-[25px] z-50  flex justify-center items-center space-x-3'}>
        <div   className={`bg-649AD0 text-white px-3 py-1  rounded-xl ${isHove?'block':'hidden'}`}>
            <span>Google chat</span>
        </div>
        <button onClick={handleGetLink} onMouseEnter={()=>{setIsHovve(true)}}
                onMouseLeave={()=>{setIsHovve(false)}}
        ><img className={' w-[30px] md:w-[40px]'} src={googeChatIcon}/></button>
    </div>

}
