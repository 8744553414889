import React, { useEffect, useRef, useState , Fragment} from 'react'
import { toast } from 'react-toastify'
import Select from 'react-select'
import { getFileUploadUrlS3CliseAPI } from '../../services/FileService'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import RCE from '../../components/editor/RCE'
import RadioComponent from './RadioComponent'
import DropzoneComponent from './DropzoneComponent'
import { axiosPrivate } from '../../services/AxiosService'
import { Dialog, Transition } from '@headlessui/react';
import closeBtn from '../../assetes/images/icons/close.svg'
export default function StudentEvaluate({unitTargets, setPage, getLtiKey }){
    let [evaluateData,setEvaluateData] = useState([])
    let editorRef = useRef()
    let [files, setFiles] =useState([])
    let [courseOptions,setCourseOptions]= useState([])
    let [assOptions,setAssOptions] = useState([])
    let [courseSelect,setCourseSelect] = useState(null)
    let [loading,setLoading] = useState(false)
    let [assignmentSelect,setAssignmentSelect]= useState([])
    let [listAssignmentEvidence,setListAssignmentEvidence ] = useState([])
    let [isOpen, setIsOpen] = useState(false)
    const {t, i18n} = useTranslation()
    const getUnitTargetDisplay = (unitTarget)=>{
        if(i18n && i18n.language !="vn" && unitTarget.QuestionForStudentEN && unitTarget.QuestionForStudentEN !="" ){
            return unitTarget.QuestionForStudentEN
        }else{
            return unitTarget.QuestionForStudent
        }
       }

    const handleRemoveAE= (idx)=>{
        let _listAE = [...listAssignmentEvidence]
        _listAE.splice(idx, 1)
        setListAssignmentEvidence(_listAE)
    }

    const handleSelectCourse =(option)=>{
        setCourseSelect(option.value)
    }

    const handleSelectAssignment = (options)=>{
        let _ae = []
        for( let op of options){
            _ae.push(op.value)
        }
        setAssignmentSelect(_ae)
    }


    const handleAddAssignment=()=>{
        if(assignmentSelect){
            let listAE = [...listAssignmentEvidence]
            for(let ae of assignmentSelect){
                listAE.push(ae)
            }
            setListAssignmentEvidence(listAE)
            setAssignmentSelect(null)
            setCourseSelect(null)
            setIsOpen(false)
        }
    }

    
    const closeModal=()=>{
        setAssignmentSelect(null)
        setCourseSelect(null)
        setIsOpen(false)
    }

    const handleSelect=(value, index)=>{
        let _eval = JSON.parse(JSON.stringify(evaluateData))
        _eval[index].value = value
        setEvaluateData(_eval)
    }



    const handleSave= async()=>{
        for(let ev of evaluateData){
            if(ev.value== undefined){
                return toast.error(t("You have not rated Unit Target ")+ getUnitTargetDisplay(ev))
            }
        }
        let content =""
        if(editorRef.current){
          content = editorRef.current.getContent()
        }
        if(files.length==0 && content=="" && listAssignmentEvidence.length==0){
            return toast.error(t("You need to provide evidence for list questions above"))
        }
        console.log(files)
        let keyFiles = []
        try{
            for(let file of files){
            
                let fileUrl = await getFileUploadUrlS3CliseAPI(file.name, file.type,  getLtiKey)
                let retuslt = await axios.put(fileUrl.data.signedRequest, file, {
                    headers: { "Content-Type": file.type },
                })
                console.log(retuslt.data)
                keyFiles.push(fileUrl.data.key)
            }
            let evaluate = await axiosPrivate.post('/clise/createEvaluateByStdV2',{
                content:content,
                keysFile: keyFiles,
                unitTargets: evaluateData,
                listAssignment: listAssignmentEvidence,
                userType: 'student'
            })
            console.log(evaluate.data)
            if(evaluate.data.evaluations== null){
                toast.error(t("All unit target have been evaluated in the 3 weeks"))
            }else{
                if(evaluate.data.unitTargetInvalid.length >0){
                    let mtcs= ""
                    for(let ut of evaluate.data.unitTargetInvalid){
                        mtcs+= ut.UnitTargetCode+" "
                    }
                    toast.info(`${t("A assessment have been created! The system does not update the unit target target")}  ${mtcs} ${t(" because they have been evaluated in the 3 weeks")}` )
                }else{
                    toast.success(t("A assessment have been created"))
                }
            }
            
            setPage('home')

        }catch(err){
            console.log(err)
            toast.error(err.message)
        }
    }


    useEffect(()=>{
        let _targets = []
        if(unitTargets.length>0){
            for(let target of unitTargets){
                if(target.check){
                    _targets.push(target)
                }
            }
            setEvaluateData(_targets)
        }
    },[unitTargets])



    useEffect(()=>{
        const getListCourse = async()=>{
            let courses = await axiosPrivate.get(`/clise/getAllAssignmentAsStudentV2`)
            console.log(courses.data)
            setCourseOptions([...courses.data.map(x=>{
                return {
                    label: x.name,
                    value:x
                }
            })])

        }

        getListCourse()
       
    },[])


    useEffect(()=>{
        if(courseSelect){
    
            const getAllAssignment=async()=>{
                setLoading(true)
                let _ass = await axiosPrivate.get(`/clise/getAllAssignmentInCourseAsUserStudent?courseId=${courseSelect.id}`)
                setAssOptions([..._ass.data.map(x=>{
                    return {
                        label: x.assDetail.name,
                        value:x
                    }
                })])
                setLoading(false)
            }
            getAllAssignment()
        }
        
    },[courseSelect])

    return <>
        <div>
            {evaluateData.map((x,i)=><>
                <div>
                        <span>{i+1} {getUnitTargetDisplay(x)}</span>
                        <RadioComponent selected={ x.value} setSelected={(value)=>{
                            handleSelect(value, i)
                        }} />
                    </div>
            </>)}
            <h3 className='font-semibold mt-5'>{t("Evidences")}</h3>
                <RCE  editorRef={editorRef}/>
                <DropzoneComponent files={files} setFiles={setFiles}/>
                <h4 className='font-semibold mt-3'>{t("Link to LMS Assignment")}</h4>
                {listAssignmentEvidence && listAssignmentEvidence.map((ae, idx)=><>
                    <div className='flex space-x-3 w-full justify-start items-center py-2'>
                        <a className='underline text-indigo-600' target='_bank' href={`https://online.vinschool.edu.vn/khoahoc/${ae.assDetail.course_id}/kiemtra/${ae.assDetail.id}`}>{ae.assDetail.name}</a>
                        <button 
                            onClick ={()=>{
                                handleRemoveAE(idx)
                            }}>
                            <img className='w-4 h-4' src={closeBtn}/>
                        </button>
                    </div>
                </>)}
                <button className='px-3 py-2 text-white mt-3 rounded-md font-semibold bg-btnbg' onClick={()=>{setIsOpen(true)}}>{t("Select Assignment")}</button>
        </div>
        <div>
            <div className='flex justify-end space-x-3'>
                <button onClick={handleSave} className='text-white px-3 py-2 font-semibold rounded-md bg-green-500'>{t("Create")}</button> 
                <button className='px-3 py-2 text-white rounded-md font-semibold bg-btnbg' 
                  onClick={()=>{
                      setPage('home')
                  }}
              >{t("Back")}</button> 
             </div>
        </div> 



        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-20" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-visible">
            <div className="flex min-h-[300px] overflow-y-visible items-center justify-center p-4 text-center z-10">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-y-visible rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                  
                  </Dialog.Title>
                  <div className="mt-2 z-30">
                    <h3>{t("Select Course")}</h3>
                  
                    <Select 
                        onChange={handleSelectCourse}
                      options={courseOptions}
                    />
                  </div>
                  {courseSelect && <div className="mt-2  z-30">
                    <h3>{t("Select Assignment")}</h3>
                    {/* {unitargetSelect.map((x,i)=><div >
                        <Checkbox isCheck={x.checked} checkHandle={()=>{handleSelectUnitarget(x,i)}} label={x.UnitTargetCode+"."+x.UnitTargetName} />
                    </div>)} */}
                    <Select 
                        isLoading={loading}
                        isMulti
                      onChange={handleSelectAssignment}
                      options={assOptions}
                    />
                  </div>}


                  <div className="mt-4 flex justify-end space-x-3">
                   
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={handleAddAssignment}
                    >
                      {t("Add assignment")}
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                     {t( "Close")}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
    </Transition>
       
    </>
}