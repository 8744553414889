import React, {useEffect, useState,useRef} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import CaptureIcon from '../../assetes/images/icons/capture.svg'
import selectIcon from '../../assetes/images/icons/complete.svg'
import {changeAvatar, changeAvatarUpload, getAvatarUploadUrl, listAvatar, uploadAvatar} from "./avatarSlice";
import {toast} from "react-toastify";
import {changeLanguageLMS, changeViewOldCourse, getSoProfile, updateAvatarUser} from "../../Features/authSlice";
import {getFileUploadUrlAPI} from "../../services/FileService";
import WebCamModal from "../modal/WebCamModal";
import WebCamAvatarModal from "../modal/WebCamAvatarModal";
import SwitchSimple from "../switchs/SwitchSimple";
export default function ProfileModal(){
    const user = useSelector(state=> state.auth.user)
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const userInfo = useSelector(state=> state.auth.user)
    const avatars = useSelector(state=> state.avatar.listAvatar)
    const changeAvatarStatus = useSelector(state=>state.avatar.status)
    const [updateAvatar, setUpdateAvatar]= useState(false)
    const [imageSelect, setImageSelect] = useState("")
    const inputFile = useRef(null)
    const [file, setFiles] = useState([])
    const [openWebcam, setOpenWebcam] = useState(false)
    const soProfile = useSelector(state=>state.auth.soProfile)
    useEffect(()=>{
        dispatch(listAvatar())
        dispatch(getSoProfile())
    },[])


    const handleUpdateAvatar=()=>{
        if(imageSelect==""){
            toast.error('please select one avatar image')
            return
        }
        dispatch(changeAvatar(imageSelect)).unwrap().then(avatar=>{
            toast.success('your avatar have been updated')
            dispatch(updateAvatarUser(avatar.data.avatar_url))
            setUpdateAvatar(false)
        }).catch(err=>{
            toast.error('something wrong')
        })
    }

    useEffect(()=>{
        if(file.length > 0){
            dispatch(getAvatarUploadUrl(file[0].fileName)).unwrap().then(data=>{
                dispatch(uploadAvatar({
                    upload_params: data.upload_params,
                    file: file[0].file,
                    upload_url: data.upload_url
                })).unwrap().then(avatarFile=>{
                    dispatch(changeAvatarUpload(avatarFile.id)).unwrap().then(avatar=>{
                        setFiles([])
                        toast.success('your avatar have been updated')
                        dispatch(updateAvatarUser(avatar.data.avatar_url))
                        setUpdateAvatar(false)
                    })
                }).catch(err=>{
                    toast.error(err.message)
                })
            }).catch(err=>{
                toast.error(err.message)
            })
        }
    },[file])

    const handleFileSelect = async(event) => {
        if(event.target.files.length > 0){
            let _files =[{
                file: event.target.files[0],
                fileName: event.target.files[0].name,
            }]
            setFiles(_files)
            event.target.files=null

        }

    }

    const handle_open_file = ()=>{
        inputFile.current.click()
    }

    const changeLang=()=>{
        if(userInfo.locale=='vi'){
            dispatch(changeLanguageLMS('en'))
        }else{
            dispatch(changeLanguageLMS('vi'))
        }

    }

    const handleChangeViewOldCourse=()=>{
        if(userInfo.viewOldCourse){
            dispatch(changeViewOldCourse(false))
        }else{
            dispatch(changeViewOldCourse(true))
        }

    }



    return <div className={'w-full px-5 py-5'}>
        {
            !updateAvatar &&<>
                <div className={'text-center pb-5'}>
                    <h4 className={'text-[18px] font-semibold text-black justify-center'}>{t('ProfileModal.title')}</h4>
                </div>
                {user && <div className={'flex  justify-center '}>
                    <div className={'w-[95px] h-[95px] relative'}>
                        <img className={'rounded-full p-3 w-[95px] h-[95px]' } src={user.avatar_url}/>
                        <button
                            onClick={()=>{setUpdateAvatar(true)}}
                            className={'absolute bottom-2 right-2 bg-white rounded-full'}>
                            <img className={'w-[20px] h-[20px] p-0.5 rounded-full '} src={CaptureIcon}/>
                        </button>
                    </div>

                </div>}
                <div className={'flex py-3 border-b-2'}>
                    <div className={'flex  flex-1 justify-start'}>
                        <span className={'text-[14px] text-color32 font-normal'}>{t('ProfileModal.fullName')}</span>
                    </div>
                    <div className={'flex flex-1 justify-start text-[14px]  text-black font-semibold'}><sapn>{soProfile?soProfile.fullName:"..."}</sapn></div>
                </div>
                <div className={'flex py-3 border-b-2'}>
                    <div className={'flex  flex-1 justify-start'}>
                        <span className={'text-[14px] text-color32 font-normal'}>{t('ProfileModal.vsCode')}</span>
                    </div>
                    <div className={'flex flex-1 justify-start text-[14px]  text-black font-semibold'}><sapn>{soProfile? soProfile.vsCode:"..."}</sapn></div>
                </div>
                <div className={'flex py-3 border-b-2'}>
                    <div className={'flex  flex-1 justify-start'}>
                        <span className={'text-[14px] text-color32  font-normal'}>{t('ProfileModal.schoolName')}</span>
                    </div>
                    <div className={'flex flex-1 justify-end text-[14px] align-left text-black font-semibold'}><sapn>{soProfile?soProfile.school:"..."}</sapn></div>
                </div>
                <div className={'flex py-3 border-b-2'}>
                    <div className={'flex  flex-1 justify-start'}>
                        <span className={'text-[14px] text-color32 font-normal'}>{t('ProfileModal.class')}</span>
                    </div>
                    <div className={'flex flex-1 justify-start text-[14px] align-left text-black font-semibold'}><sapn>{soProfile?soProfile.homeRoomClass:"..."}</sapn></div>
                </div>
                <div className={'flex py-3 border-b-2'}>
                    <div className={'flex  flex-1 justify-start'}>
                        <span className={'text-[14px] text-color32 font-normal'}>{t('ProfileModal.address')}</span>
                    </div>
                    <div className={'flex flex-1 justify-end text-[14px] align-left text-black font-semibold'}><sapn>{soProfile?soProfile.address:"..."}</sapn></div>
                </div>
                <div className={'flex py-3 border-b-2'}>
                    <div className={'flex  flex-1 justify-start'}>
                        <span className={'text-[14px] text-color32 font-normal'}>{t('ProfileModal.lang')}</span>
                    </div>
                    <div className={'flex flex-1 justify-end text-[14px] text-black font-semibold'}>
                        <SwitchSimple label={userInfo.locale}  enabled={userInfo.locale == 'vi'} change={changeLang}  />
                    </div>
                </div>
                <div className={'flex py-3 border-b-2'}>
                    <div className={'flex  flex-1 justify-start'}>
                        <span className={'text-[14px] text-color32 font-normal'}>{t('ProfileModal.viewOldCourse')}</span>
                    </div>
                    <div className={'flex flex-1 justify-end text-[14px] text-black font-semibold'}>
                        <SwitchSimple label={userInfo.viewOldCourse?t("ProfileModal.open"):t("ProfileModal.close")}  enabled={userInfo.viewOldCourse} change={handleChangeViewOldCourse}  />
                    </div>
                </div>
            </>
        }
        {updateAvatar && <>
            <div className={'text-center pb-5'}>
                <h4 className={'text-[18px] font-semibold text-black justify-center'}>{t('ProfileModal.title2')}</h4>
            </div>
            <div className={'justify-center flex'}>
                <div className={' grid grid-cols-4 gap-4'}>
                    {avatars.map(x=><>
                        <button
                            onClick={()=>{setImageSelect(x)}}
                            className={'relative'}>
                            <img className={'rounded-full'} src={x}/>
                            {imageSelect && imageSelect==x && <img className={'absolute bottom-0 right-0'} src={selectIcon} />}
                        </button>
                    </>)}
                </div>
            </div>
            <div className={'justify-center flex py-10'}>
                <button
                    onClick={handleUpdateAvatar}
                    disabled={changeAvatarStatus=='loading'}
                    className={'w-full rounded-xl text-white font-semibold text-[14px] bg-bgcolor7 h-[48px] max-w-[300px]'}>
                    {changeAvatarStatus=='loading'?'loading...':t("ProfileModal.confirmBtn")}
                </button>
            </div>
            <div className={'border-b flex justify-center '}>
                <span  className={'mb-[-12px] bg-white px-3 text-[14px]'}>{t("ProfileModal.or")}</span>
            </div>
            <div className={'justify-center flex pt-5'}>
                <input className="cursor-pointer  block opacity-0 pin-r pin-t" type="file" name="vacancyImageFiles"
                       ref={inputFile} onChange={handleFileSelect} accept="image/jpeg" style={{display: 'none'}}/>
                <button
                    onClick={handle_open_file}
                    disabled={changeAvatarStatus=='loading'}
                    className={'w-full rounded-xl text-white font-semibold text-[14px] bg-color33 h-[48px] max-w-[300px]'}>
                    {changeAvatarStatus=='loading'?'loading...':t("ProfileModal.fileChooser")}
                </button>
            </div>
            <div className={'justify-center flex pt-5'}>
                <button
                    onClick={()=>{setOpenWebcam(true)}}
                    disabled={changeAvatarStatus=='loading'}
                    className={'w-full rounded-xl text-white font-semibold text-[14px] bg-color34 h-[48px] max-w-[300px]'}>
                    {changeAvatarStatus=='loading'?'loading...':t("ProfileModal.takePhoto")}
                </button>
            </div>
            <WebCamAvatarModal  open={openWebcam} setOpen={setOpenWebcam} imgSrc={null} setImgSrc={null} fileUpload={file} setFileUpload={setFiles}  />
        </>}
    </div>
}
