import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    changeStateCurricularAPI,
    createCurricularAPI,
    deleteCurricularAPI,
    editCurricularAPI,
    listCurricularAPI
} from "../../../services/PortfolioService";

const initState = {
    status : 'idle',
    curriculars:[]
}

export const listCurricular = createAsyncThunk('curricular/listCurricular', async(id)=>{
    let curriculars = await  listCurricularAPI(id)
    return curriculars.data
})

export const createCurricular = createAsyncThunk('curricular/createCurricular', async ({name, imageBanner, subDescription, content, pageId})=>{
    let curricular = await createCurricularAPI(name, imageBanner, subDescription, content, pageId)
    return curricular.data
})

export const deleteCurricular = createAsyncThunk('curricular/delete', async(id)=>{
    let curricular = await deleteCurricularAPI(id)
    return curricular.data
})

export const editCurricular = createAsyncThunk('curricular/edit', async({id, name, imageBanner, subDescription, content})=>{
    let curricular = await editCurricularAPI(id, name, subDescription, content, imageBanner)
    return curricular.data
})

export const changeStateCurricular = createAsyncThunk('curricular/changeState', async({id, state})=>{
    let curricular = await changeStateCurricularAPI(id, state)
    return curricular.data
})

const curricularSlice = createSlice({
    name: 'curricular',
    initialState: initState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(listCurricular.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(listCurricular.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(listCurricular.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.curriculars = action.payload
        }).addCase(createCurricular.pending, (state,action)=>{
            state.status= 'loading'
        }).addCase(createCurricular.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.curriculars = [...state.curriculars, action.payload]
        }).addCase(createCurricular.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(deleteCurricular.pending, (state,action)=>{
            state.status= 'loading'
        }).addCase(deleteCurricular.fulfilled, (state,action)=>{
            state.status = 'idle'
            let _curriculars = [...state.curriculars]
            for(let i =0; i< _curriculars.length; i++){
                if(_curriculars[i]._id == action.payload._id){
                    _curriculars.splice(i,1)
                    break
                }
            }
            state.curriculars= [..._curriculars]
        }).addCase(deleteCurricular.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(editCurricular.pending, (state,action)=>{
            state.status= 'loading'
        }).addCase(editCurricular.fulfilled, (state,action)=>{
            state.status = 'idle'
            let _curriculars = [...state.curriculars]
            for(let i =0; i< _curriculars.length; i++){
                if(_curriculars[i]._id == action.payload._id){
                    _curriculars[i]=action.payload
                    break
                }
            }
            state.curriculars= [..._curriculars]
        }).addCase(editCurricular.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(changeStateCurricular.pending, (state,action)=>{
            state.status= 'loading'
        }).addCase(changeStateCurricular.fulfilled, (state,action)=>{
            state.status = 'idle'
            let _curriculars = [...state.curriculars]
            for(let i =0; i< _curriculars.length; i++){
                if(_curriculars[i]._id == action.payload._id){
                    _curriculars[i]=action.payload
                    break
                }
            }
            state.curriculars= [..._curriculars]
        }).addCase(changeStateCurricular.rejected, (state,action)=>{
            state.status = 'idle'
        })

    }
})



export default  curricularSlice.reducer
