import {axiosPrivate} from "./AxiosService";

export const listCourseModuleAPI = (course_id)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/module/listcoursemodule?courseId=${course_id}`)
}

export const listItemCourseModuleAPI = (course_id, module_id)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/module/listitemcoursemodule?courseId=${course_id}&moduleId=${module_id}`)
}

export const moduleItemSequenceAPI= (course_id, asset_id, type)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/module/moduleitemsequence?courseid=${course_id}&assetid=${asset_id}&type=${type}`)
}

export const markItemIsReadAPI = (course_id, itemId, moduleId)=>{
    return axiosPrivate.post(`${process.env.REACT_APP_BACKEND}/module/markItemIsRead`,{
        courseId: course_id,
        moduleId: moduleId,
        itemId: itemId
    })
}



export const listCourseModuleAsyncAPI = (course_id)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/module/listModuleAsync?courseId=${course_id}`)
}
