import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getListWeekAPI} from "../../services/CourseService";
import { closeGlobalNotificationAPI, getGlobalNotificationsAPI } from "../../services/AuthService";

const initialState = {
    listWeek:[],
    notifications:[],
    state: 'idle',
    error: null,
    status: 'idle',
    notiStatus: 'idle'
}

export const getListWeek =createAsyncThunk('dashboard/getListWeek', async()=>{
    let listWeek = await getListWeekAPI()
    return listWeek.data
})

export const getGlobalNotifications = createAsyncThunk('dashboard/getGlobalNotifications', async()=>{
    let notifications = await getGlobalNotificationsAPI()
    return notifications.data
})

export const closeGlobalNotification = createAsyncThunk('dashboard/closeNotification', (async(noti)=>{
    let noti_ = await closeGlobalNotificationAPI(noti)
    return noti_.data
}))

const DashboardSlice = createSlice({
    initialState: initialState,
    name: 'dashboard',
    reducers: {},
    extraReducers:builder => {
        builder.addCase(getListWeek.pending, (state,action)=>{
            state.status ='pending'
            state.error = null
        }).addCase(getListWeek.rejected, (state,action)=>{
            state.status = 'idle'
            state.error = action.error
        }).addCase(getListWeek.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.error = null
            state.listWeek = action.payload
        }).addCase(getGlobalNotifications.pending, (state,action)=>{
            state.notiStatus ='pending'
        }).addCase(getGlobalNotifications.rejected, (state,action)=>{
            state.notiStatus = 'idle'
        }).addCase(getGlobalNotifications.fulfilled, (state,action)=>{
            state.notifications = action.payload
            state.notiStatus= 'idle'
        }).addCase(closeGlobalNotification.pending((state,action)=>{
            state.notiStatus = 'pending'
        })).addCase(closeGlobalNotification.fulfilled, (state,action)=>{
            state.notiStatus = 'idle'
            state.notifications=[...state.notifications.filter(x=> {return x.id != action.payload.id})]
        })
    }
})

export default DashboardSlice.reducer
