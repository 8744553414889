import {axiosPrivate} from "./AxiosService";

export const getPageDetailAPI = (courseId, pageId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/page/pagedetail?courseid=${courseId}&pageid=${encodeURIComponent(pageId)}`)
}


export const getIntroPageAPI = (courseId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/page/frontpage?courseId=${courseId}`)
}
