import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    changeAvatarAPI,
    changeAvatarUploadAPI,
    getAvatarUploadUrlAPI,
    listAvatarAPI
} from "../../services/avatarService";
import {uploadFileAPI} from "../../services/FileService";

const initState ={
    listAvatar: [],
    status:'idle'
}

export const listAvatar = createAsyncThunk('avatar/listAvatar', async()=>{
    let listAvatar = await listAvatarAPI()
    return listAvatar.data
})

export const changeAvatar = createAsyncThunk('avatar/changeAvatar', async(url)=>{
    let avatar = await changeAvatarAPI(url)
    return avatar.data
})

export const getAvatarUploadUrl = createAsyncThunk('avatar/getAvataruploadurl', async(name)=>{
    let avatar = await getAvatarUploadUrlAPI(name)
    return avatar.data
})


export const changeAvatarUpload = createAsyncThunk('avatar/changeAvatarUpload', async(avatarId)=>{
    let avatar = await changeAvatarUploadAPI(avatarId)
    return avatar.data
})

export const uploadAvatar = createAsyncThunk('avatar/upload', async(input)=>{
    let avatar =  await uploadFileAPI(input.upload_params, input.file, input.upload_url)
    return avatar.data
})

const AvatarSlice = createSlice({
    name: 'avatar',
    initialState: initState,
    reducers: {},
    extraReducers:builder => {
        builder.addCase(listAvatar.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(listAvatar.fulfilled, (state,action)=>{
            state.status = 'idle'
            let avatars = []
            for(let avatar of action.payload){
                avatars.push(`${process.env.REACT_APP_BACKEND}/${avatar}`)
            }
            state.listAvatar=[...avatars]
        }).addCase(listAvatar.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(changeAvatar.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(changeAvatar.fulfilled, (state,action)=>{
            state.status = 'idle'
        }).addCase(changeAvatar.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(getAvatarUploadUrl.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(getAvatarUploadUrl.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(uploadAvatar.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(uploadAvatar.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(changeAvatarUpload.pending, (state,action)=>{
            state.status= 'loading'
        }).addCase(changeAvatarUpload.rejected, (state, action)=>{
            state.status = 'idle'
        }).addCase(changeAvatarUpload.fulfilled, (state,action)=>{
            state.status = 'idle'
        })
    }
})

export default AvatarSlice.reducer
