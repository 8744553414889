import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    getAssignmentSummitedAPI, getAvgReportByWeekAPI,
    getDiscussionHaveEntryAPI,
    getProgressDataAPI, getProgressDataByWeekAPI, getReportSummaryInCourseAPI, listDiscussionHaveEntryReportByWeekAPI,
    listSubmissionAPI
} from "../../services/reportService";


const initState= {
    status:'idle',
    courses: [],
    enrollments: [],
    progressRequire: 0,
    progressComplete: 0,
    totalTimeActive: 0,
    finalScoreAvg: 0,
    avgProgress :0,
    numAssignmentSummited:0,
    numDiscussionHaveEntry: 0,
    submissions: [],
    reportSummaryInCourse: null,
    itemCount: '0/0',
    lastActivityAt: ""
}

export const getProgressData= createAsyncThunk('report/getProgressData', async()=>{
    let progressData = await getProgressDataAPI()
    return progressData.data
})

export const getProgressDataByWeek = createAsyncThunk('report/getProgressDataByWeek', async(filterWeek)=>{
    let progressData = await getProgressDataByWeekAPI(filterWeek)
    return progressData.data
})

export const getAvgReportByWeek = createAsyncThunk('report/getAvgReportByWeek', async(filterWeek)=>{
    let avgReportByWeek = await getAvgReportByWeekAPI(filterWeek)
    return avgReportByWeek.data
})

export const getAssignmentSummited=createAsyncThunk('report/getAssignmentSummited', async()=>{
    let numberAssign = await getAssignmentSummitedAPI()
    return numberAssign.data
})

export const getDiscussionHaveEntry= createAsyncThunk('report/getDiscussionHaveEntry',async ()=>{
    let numberDiscussion = await  getDiscussionHaveEntryAPI()
    return numberDiscussion.data
})


export const listDiscussionHaveEntryReportByWeek = createAsyncThunk('report/listDiscussionHaveEntryReportByWeek', async(filterWeek)=>{
    let numberDiscussion = await  listDiscussionHaveEntryReportByWeekAPI(filterWeek)
    return numberDiscussion.data
})

export const listSubmission = createAsyncThunk('report/listSubmission',async(courseId)=>{
    let submissions = await listSubmissionAPI(courseId)
    return {
        courseId: courseId,
        submissions: submissions.data
    }
} )

export const getReportSummaryInCourse = createAsyncThunk('report/getReportSummaryInCourse', async (courseId)=>{
    let reportSummaryInCourse  = await  getReportSummaryInCourseAPI(courseId)
    return reportSummaryInCourse.data
})

const ReportSlice = createSlice({
    name:'report',
    initialState: initState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getProgressData.pending, (state,action)=>{
            state.status ='loading'
        }).addCase(getProgressData.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(getProgressData.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.courses= action.payload.courses
            state.enrollments = action.payload.enrollments
            // let totalTimeActive = 0
            // let totalPoints=0
            // let progressRequire= 0
            // let progressComplete =0
            // for(let enrollment of action.payload.enrollments){
            //     if(enrollment.total_activity_time){
            //         totalTimeActive+= enrollment.total_activity_time
            //     }
            //     if(enrollment.grades){
            //         totalPoints+= enrollment.grades.current_score
            //     }
            // }
            // for(let course of action.payload.courses){
            //     if(course.course_progress){
            //         if(course.course_progress.requirement_completed_count){
            //             progressComplete+= course.course_progress.requirement_completed_count
            //         }
            //         if(course.course_progress.requirement_count){
            //             progressRequire+=course.course_progress.requirement_count
            //         }
            //     }
            // }
            // state.totalTimeActive = totalTimeActive
            // state.finalScoreAvg = (totalPoints/action.payload.enrollments.length).toFixed(2)
            // state.progressRequire =progressRequire
            // state.progressComplete = progressComplete
            // if(progressRequire >0){
            //     state.avgProgress = (progressComplete/progressRequire*100).toFixed(2)
            // }
        }).addCase(getProgressDataByWeek.pending, (state,action)=>{
            state.status = 'loadinng'
        }).addCase(getProgressDataByWeek.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(getProgressDataByWeek.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.avgProgress= action.payload.process
            state.itemCount= action.payload.items
        }).addCase(getAvgReportByWeek.pending, (state,action)=>{
            state.status = 'loadinng'
        }).addCase(getAvgReportByWeek.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(getAvgReportByWeek.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.numAssignmentSummited = action.payload.total_assignment? action.payload.total_assignment:0
            state.totalTimeActive = action.payload.total_activity_time?action.payload.total_activity_time:0
            state.finalScoreAvg = action.payload.avg_current_score?action.payload.avg_current_score.toFixed(2):0
            state.lastActivityAt=action.payload.last_activity_at
        }).addCase(getAssignmentSummited.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(getAssignmentSummited.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(getAssignmentSummited.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.numAssignmentSummited=action.payload.numberAssignment
        }).addCase(getDiscussionHaveEntry.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(getDiscussionHaveEntry.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(getDiscussionHaveEntry.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.numDiscussionHaveEntry=action.payload.numDiscussion
        }).addCase(listDiscussionHaveEntryReportByWeek.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(listDiscussionHaveEntryReportByWeek.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(listDiscussionHaveEntryReportByWeek.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.numDiscussionHaveEntry=action.payload.numDiscussion
        }).addCase(listSubmission.pending, (state,action)=>{
            state.status = 'loading'
            state.submissions=[]
        }).addCase(listSubmission.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.submissions= action.payload.submissions
        }).addCase(listSubmission.rejected, (state, action)=>{
            state.status = 'idle'
            state.submissions = []
        }).addCase(getReportSummaryInCourse.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(getReportSummaryInCourse.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.reportSummaryInCourse= action.payload.data
        }).addCase(getReportSummaryInCourse.rejected, (state, action)=>{
            state.status = 'idle'
            state.reportSummaryInCourse = null
        })
    }
})

export  default ReportSlice.reducer
