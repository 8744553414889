import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {listYourCourseApi} from "../../services/CourseService";


const initialState = {
    courses: [],
    courseSelect: null,
    state: 'ide',
    error: null
}

export const getCourses = createAsyncThunk('inboxcourse/getcourses', async(input)=>{
    let courses = await listYourCourseApi()
    return {
        courses: courses.data
    }
})

export const InboxCourseSlice = createSlice({
    name:'InboxCourseSlice',
    initialState: initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getCourses.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(getCourses.rejected, (state,action)=>{
            state.status = 'ide'
            state.error = action.error.message
        }).addCase(getCourses.fulfilled, (state, action)=>{
            state.status = 'ide'
            let _courses = [{
                title: 'Chọn 1 khoá học',
                id: 0
            }]

            for(let item of action.payload.courses){
                _courses.push({
                    title: item.name,
                    id: item.id
                })
            }
            state.courses = _courses
            state.courseSelect = _courses[0]

        })
    }
})
