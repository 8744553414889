import {axiosPrivate} from "./AxiosService";
import axios from "axios";

export const getFileAPI= (course_id, file_id)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/file/getfile?courseid=${course_id}&fileid=${file_id}`)
}

export const getFileUploadUrlAssignemntAPI = (course_id, assignment_id, file_name)=>{
    return axiosPrivate.post(`${process.env.REACT_APP_BACKEND}/file/getfileuploadurlassigment`, {
        courseId: course_id,
        assignmentId:assignment_id,
        fileName:file_name
    })
}

export const getFileUploadUrlAPI = (folderId, fileName)=>{
    return axiosPrivate.post(`${process.env.REACT_APP_BACKEND}/file/getfileuploadurl`,{
        folderId: folderId,
        fileName:fileName
    })
}

export const uploadFileAPI = (params, file, url)=>{
    let formData = new FormData()
    formData.append('Filename', params.Filename)
    formData.append('Policy', params.Policy)
    formData.append('acl', params.acl)
    if(params['content-type']){
        formData.append('content-type', params['content-type'])
    }

    formData.append('key', params.key)
    formData.append('success_action_redirect', params.success_action_redirect)
    formData.append('x-amz-algorithm', params['x-amz-algorithm'])
    formData.append('x-amz-credential', params['x-amz-credential'])
    formData.append('x-amz-date', params['x-amz-date'])
    formData.append('x-amz-signature', params['x-amz-signature'])
    formData.append('file', file)
    return axios.post(url, formData, {
        headers:{
            'Content-Type': 'multipart/form-data'
        }
    })
}


export const getFolderInCourseAPI = (courseId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/file/folderrootincourse?courseid=${courseId}`)
}



export const getFolderInFolderAPI = (folderId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/file/folderinfolder?folderid=${folderId}`)
}


export const getFilesInFolderAPI = (folderId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/file/filesinfolder?folderid=${folderId}`)
}


export const getFilesInCourseAPI = (courseId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/file/getfileincourse?courseid=${courseId}`)
}


export const getConversationFolderAPI = ()=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/file/getconversationfolder`)
}


export const getFileUploadUrlS3API=(fileName, fileType)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/file/generateUploadS3Url?fileName=${fileName}&fileType=${fileType}`)
}

export const getFileUploadUrlS3CliseAPI=(fileName, fileType)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/file/getFileUploadUrlS3Clise?fileName=${fileName}&fileType=${fileType}`)
}
