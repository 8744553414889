import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    getGradeSheetAPI,
    getGradeSheetPublishAPI,
    getLearningHistoryAPI,
    updateGradeSheetViewAPI
} from "../../../services/PortfolioService";

const initialState = {
    status : "idle",
    learningHistory: []
}

export const getLearningHistory = createAsyncThunk('learningHistory/get', async()=>{
    let learningHistory = await getLearningHistoryAPI()
    return learningHistory.data
})

export const getGradeSheet = createAsyncThunk('learningHistory/getGradeSheet', async({schoolYearId, pageId})=>{
    let gradeSheet = await getGradeSheetAPI(schoolYearId, pageId)
    return gradeSheet.data
})

export const getGradeSheetPublish = createAsyncThunk('learningHistory/getGradeSheetPublish', async({schoolYearId, pageId})=>{
    let gradeSheet = await getGradeSheetPublishAPI(schoolYearId, pageId)
    return gradeSheet.data
})

export const updateGradeSheetView =createAsyncThunk('learningHistory/updateGradeSheet', async ({schoolYearId, pageId, type, viewOption})=>{

    let result = await updateGradeSheetViewAPI(schoolYearId,type, viewOption, pageId)
    return result.data

})

const LearningHistorySlice = createSlice({
    name:"learningHistory",
    initialState:initialState,
    reducers:{},
    extraReducers: builder => {
        builder.addCase(getLearningHistory.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getLearningHistory.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.learningHistory= action.payload
        }).addCase(getLearningHistory.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(getGradeSheetPublish.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getGradeSheetPublish.fulfilled, (state,action)=>{
            state.status = 'idle'
        }).addCase(getGradeSheetPublish.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(getGradeSheet.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getGradeSheet.fulfilled, (state,action)=>{
            state.status = 'idle'
        }).addCase(getGradeSheet.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(updateGradeSheetView.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(updateGradeSheetView.fulfilled, (state,action)=>{
            state.status = 'idle'
        }).addCase(updateGradeSheetView.rejected, (state,action)=>{
            state.status = 'idle'
        })
    }
})

export default LearningHistorySlice.reducer
