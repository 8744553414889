import {axiosPrivate} from "./AxiosService";

export const getProgressDataAPI=()=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/report/getProgressData`)
}

export const getProgressDataByWeekAPI = (filterWeek)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/report/getProgressDataByWeek?startDate=${filterWeek.start_date}&endDate=${filterWeek.end_date}`)
}

export const getAvgReportByWeekAPI = (filterWeek)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/report/getAvgReportByWeek?startDate=${filterWeek.start_date}&endDate=${filterWeek.end_date}`)
}

export const listDiscussionHaveEntryReportByWeekAPI=(filterWeek=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/report/listDiscussionHaveEntryReportByWeek?startDate=${filterWeek.start_date}&endDate=${filterWeek.end_date}`)

})

export const getAssignmentSummitedAPI = ()=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/report/getAssignmentSummited`)
}

export const getDiscussionHaveEntryAPI=()=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/report/listDiscussionHaveEntry`)
}

export const listSubmissionAPI = (courseId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/report/listSubmission?courseId=${courseId}`)
}

export const getReportSummaryInCourseAPI=(courseId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/report/getReportSummaryInCourse?courseId=${courseId}`)
}
