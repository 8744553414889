import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUnitargetsAPI } from "../../services/CliseService";

const initState = {
  unitargets:[],
  state: 'idle'
}

export const getUnitargets = createAsyncThunk('clise/unitTargets',async()=>{
  let unitargets = await getUnitargetsAPI()
  return unitargets.data
})

const cliseSlice = createSlice({
  name:"clise",
  initialState: initState,
  reducers:{
    setUnitTarget: (state,action)=>{
      state.unitargets = action.payload
    }
  },
  extraReducers: builder=>{
    builder.addCase(getUnitargets.pending, (state,action)=>{
      state.status = 'pending'
    }).addCase(getUnitargets.rejected, (state,action)=>{
      state.status = 'idle'
    }).addCase(getUnitargets.fulfilled, (state,action)=>{
      state.status = 'idle'
      state.unitargets = action.payload
    })
  }
})
export default cliseSlice.reducer

export const {setUnitTarget} = cliseSlice.actions