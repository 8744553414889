import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    changeCertViewAPI,
    delCertAPI,
    getCertAPI,
    getListCertAPI,
    saveCertAPI
} from "../../../services/PortfolioService";

const initState = {
    status: 'idle',
    certs:[]
}

export const saveCert = createAsyncThunk('cert/save', async ({pageId, certName, certKey})=>{
    let cert =await saveCertAPI(certName,certKey,pageId)
    return cert.data
})

export const getCerts = createAsyncThunk('cert/getList', async(pageId)=>{
    let certs = await getListCertAPI(pageId)
    return certs.data
})
export const delCert = createAsyncThunk('cert/del', async(certId)=>{
    let cert = await delCertAPI(certId)
    return cert.data
})

export const updateCertView = createAsyncThunk('cert/updateCertView', async({certId, viewOption})=>{
    let cert = await changeCertViewAPI(certId, viewOption)
    return cert.data
})
export const getCertById = createAsyncThunk('cert/getCert', async(certId)=>{
    let cert = await getCertAPI(certId)
    return cert.data
})

const certSlice = createSlice({
    name:'cert',
    initialState: initState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(saveCert.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(saveCert.rejected, (state,action)=>{
            state.status= 'idle'
        }).addCase(saveCert.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.certs = [...state.certs, action.payload]
        }).addCase(getCerts.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getCerts.fulfilled,(state,action)=>{
            state.staus = 'idle'
            state.certs= action.payload
        }).addCase(getCerts.rejected, (state,action)=>{
            state.status='idle'
        }).addCase(delCert.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(delCert.fulfilled,(state,action)=>{
            state.staus = 'idle'
            let _certs = [...state.certs]
            for(let i=0; i< _certs.length; i++){
                if(_certs[i]._id == action.payload._id){
                    _certs.splice(i, 1)
                    break
                }
            }
            state.certs = [..._certs]
        }).addCase(delCert.rejected, (state,action)=>{
            state.status='idle'
        }).addCase(updateCertView.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(updateCertView.fulfilled,(state,action)=>{
            state.staus = 'idle'
            let _certs = [...state.certs]
            for(let i=0; i< _certs.length; i++){
                if(_certs[i]._id == action.payload._id){
                    _certs[i] = action.payload
                    break
                }
            }
            state.certs = [..._certs]
        }).addCase(updateCertView.rejected, (state,action)=>{
            state.status='idle'
        }).addCase(getCertById.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getCertById.fulfilled,(state,action)=>{
            state.staus = 'idle'
        }).addCase(getCertById.rejected, (state,action)=>{
            state.status='idle'
        })
    }
})


export default certSlice.reducer
