import React, {useEffect, useState} from 'react'
import SWBIcon from '../../assetes/images/logos/swb.png'
import {useDispatch, useSelector} from "react-redux";
import {createUrl} from "./SWBSlice";
import {toast} from "react-toastify";
export default function SWBChat(){
    let [isHove, setIsHovve] = useState(false)
    const dispatch =useDispatch()
    const url = useSelector(state=>state.swb.url)
    const handleGetLink = ()=>{
        dispatch(createUrl()).unwrap().then(url=>{
            window.open(url.url)
        }).catch(err=>{
            toast.error('Hiện tại bạn không thể sử dụng SWB. Xin liên hệ với quản trị viên để được hỗ trợ')
        })
    }



       return <div id={'chatSWB'}  className={'fixed bottom-[150px] md:bottom-[180px] right-[25px] z-50  flex justify-center items-center space-x-3'}>
           <div   className={`bg-649AD0 text-white px-3 py-1  rounded-xl ${isHove?'block':'hidden'}`}>
               <span>SWB</span>
           </div>
            <button onClick={handleGetLink} onMouseEnter={()=>{setIsHovve(true)}}
                    onMouseLeave={()=>{setIsHovve(false)}}
            ><img className={' w-[30px] md:w-[40px]'} src={SWBIcon}/></button>
        </div>

}
