import Loadable from "react-loadable";
import { Route, Routes } from "react-router-dom";
import CourseDetail from "./pages/CourseDetail";
import LoginCallback from "./pages/LoginCallback";
import { PrivateRoute } from "./PrivateRoute";
import DevPage from "./pages/DevPage";
import Course from "./pages/Course";
import CourseContent from "./pages/CourseContent";
import Login2 from "./pages/Login";
import QuizPage from "./pages/Quizzes";
import AssignmentPage from "./pages/Assignments";
import Inbox from "./pages/Inbox";
import TimeTable from "./pages/TimeTable";
import PageContext from "./pages/pageContent";
import Report from "./pages/reports";
import ListEPortfolio from "./pages/Eportfoliios/ListEportfolio";
import EditPortfolio from "./pages/Eportfoliios/EditPortfolio";
import PreviewEportfolio from "./pages/Eportfoliios/PortfolioPreview";
import PortfolioView from "./pages/Eportfoliios/portfolioView";
import Dashboard from "./pages/Dashboard";
import TeacherComments from "./pages/TeacherComments";
import Announcements from "./pages/Announcements";
import Tickets from "./pages/Tickets";
import TicketDetail from "./pages/TicketDetail";
import AccessDenied from "./pages/AccessDenied";
import Clise from "./pages/Clise/Clise";
import LmsRedirect from "./pages/LmsRedirect";


function App() {

  return (
      <Routes>
        <Route path='/' element={<PrivateRoute/>}>
            <Route  path="/" element={<Dashboard/>}/>
            <Route path={'/inbox'}  element={<Inbox />}/>
            <Route path={'/thoikhoabieu'} element={<TimeTable />}/>
            <Route path={'/khoahoc'} element={<Course/>}/>
            <Route path={'/chitietkhoahoc/:courseid/baitap'} element={<CourseDetail  tab={'quiz'}/>}/>
            <Route path={'/chitietkhoahoc/:courseid/kiemtra'} element={<CourseDetail  tab={'assignment'}/>}/>
            <Route path={'/chitietkhoahoc/:courseid/diem'} element={<CourseDetail  tab={'grade'}/>}/>
            <Route path={'/chitietkhoahoc/:courseid/baigiang'} element={<CourseDetail  tab={'module'}/>}/>
            <Route path={'/chitietkhoahoc/:courseid/baigiang/:moduleId'} element={<CourseDetail  tab={'module'}/>}/>
            <Route path={'/chitietkhoahoc/:courseid/gioithieu'} element={<CourseDetail  tab={'intro'}/>}/>
            <Route path={'/chitietkhoahoc/:courseid/tailieu'} element={<CourseDetail  tab={'file'}/>}/>
            <Route path={'/chitietkhoahoc/:courseid/thongbao'} element={<CourseDetail tab={'announcement'} />} />
            <Route path={'/chitietkhoahoc/:courseid/thongbao/:announcementId'} element={<CourseDetail tab={'announcementDetail'} />} />
            <Route path={'/chitietkhoahoc/:courseid/thaoluan/taomoi'} element={<CourseDetail  tab={'createDiscussion'}/>}/>
            <Route path={'/chitietkhoahoc/:courseid/thaoluan/:discussionId/chitiet'} element={<CourseDetail  tab={'discussionDetail'}/>}/>
            <Route path={'/chitietkhoahoc/:courseid/thaoluan'} element={<CourseDetail  tab={'discussion'}/>}/>
            <Route path={'/chitietkhoahoc/:courseid'} element={<CourseDetail  tab={'intro'}/>}/>
            <Route path={'/chitietkhoahoc/:courseid/module/:moduleid/:type/:itemid'} element={<CourseContent />}/>
            <Route path={'/khoahoc/:courseid/baitap/:quizid'} element={<QuizPage/>} />
            <Route path={'/khoahoc/:courseid/kiemtra/:assignmentid'} element={<AssignmentPage/>} />
            <Route path={'/khoahoc/:courseid/page/:pageid'} element={<PageContext />} />
            <Route path={'/eportfolio'}  element={<ListEPortfolio />} />
            <Route path={'/eportfolio/:id/preview'}  element={<PreviewEportfolio/>}/>
            <Route path={'/eportfolio/:id/edit'} element={<EditPortfolio/>}/>
            <Route  path={'/eportfolio/:id/edit/section/:sectionId'} element={<EditPortfolio />} />
            <Route path={'/report'} element={<Report />}/>
            <Route path={'/comments'} element={<TeacherComments />}/>
            <Route path={'/tickets'} element={<Tickets/>}/>
            <Route path={'/tickets/:ticketId'} element={<TicketDetail/>} />
            <Route path={'/clise'} element={<Clise />}/>
        </Route>
        <Route path="/login" element={<Login2/>}/>
        <Route path="/lmsredirect" element={<LmsRedirect/>}/>
        <Route path={'login/:old'} element={<Login2/>} />
        <Route path='/logincallback' element={<LoginCallback/>} />
          <Route path={'/viewPortfolio/:id'} element={<PortfolioView />}/>
          <Route path="/accessDenied" element={<AccessDenied/>}/>
        <Route path='/*' element={<DevPage/>} />
      </Routes>
  );
}

export default App;
