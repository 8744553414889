/* eslint-disable default-case */
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { axiosPrivate } from '../../services/AxiosService';
import { StarIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';

export default function StudentEvaluationTimline({unitarget}){
    const {t, i18n} = useTranslation()
    let [data,setData] = useState([])
    const genderContentLevel=(level)=>{
        let result=""
        switch(level){
            case 1:
                result=t("Not Yet Met Expectation")
                break
            case 2:
                result=t("Developing")
                break
            case 3:
                result =t( "Met Expectation")
                break
            case 4:
                result=t("Vượt Kỳ Vọng")
                break
        }
        return result
    }
    useEffect(()=>{
        let  getDetail=async()=>{
            let detail = await axiosPrivate.post('/clise/getEvaluationDetailStudentV2', unitarget)
            setData(detail.data)
        }
        getDetail()
    }, [unitarget])
    return <>
    <VerticalTimeline>
        {data.map((x,i)=><VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: `${i%2==0?"rgb(32, 39, 68)":""}`, color: `${i%2==0?"#FF0099":"#000"}` }}
            contentArrowStyle={{ borderRight: '7px solid  rgb(32, 39, 68)' }}
            date={genderContentLevel(x.level)}
            iconStyle={{ background: 'rgb(32, 39, 68)', color: '#fff' }}
            icon={<StarIcon />}
        >
            <h3 className="vertical-timeline-element-title">{t("Creator")}: {x.userCreate}</h3>
            <h4 className="vertical-timeline-element-subtitle">{t("Date created")}: {new Date(x.CreatedAt).toLocaleDateString()}</h4>
            <p dangerouslySetInnerHTML={{__html: x.content?x.content:""}}>
            </p>
            <div>
                <h3>{t("Attachments")}:</h3>
                {x.urls && x.urls.length>0?<>
                    {x.urls.map(y=><div>
                        <a href={y.url} className='underline' target='_blank'>{y.name}</a>
                    </div>)}
                </>:<>
                <span>{t("Empty")}</span></>}
            </div>
            <div>
                <h3>{t("LMS Assignment")}:</h3>
                {x.assUrls && x.assUrls.length>0?<>
                    {x.assUrls.map(y=><div>
                        <a href={y.urlV2} className='underline' target='_blank'>{y.name}</a>
                    </div>)}
                </>:<>
                <span>{t("Empty")}</span></>}
            </div>
        </VerticalTimelineElement>)}
        </VerticalTimeline>
    </>
}