import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom'
import { persistStore } from 'redux-persist'
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';


import {store} from './configs/store';
import { PersistGate } from 'redux-persist/integration/react';
import { I18nextProvider } from 'react-i18next';
import i18n from './configs/118n';
import {setup} from "./services/AxiosService";
let persistor = persistStore(store);



ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <I18nextProvider  i18n={ i18n }>
        <GoogleOAuthProvider clientId="23001056953-qt5cnd5h7rbo08fu55p36ir1u0oodmmb.apps.googleusercontent.com">
        <BrowserRouter>
            <App />
          </BrowserRouter>
          </GoogleOAuthProvider>
          
        </I18nextProvider>

      </PersistGate>
    </Provider>,
  document.getElementById('root')
);

setup(store)
