import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    changeStatePortfolioAPI,
    createEPortfolioAPI,
    deletePortfolioAPI, getListSectionAPI, getPagesInSectionAPI,
    getPortfolioAPI,
    listEPortfolioAPI, updatePageAPI
} from "../../services/PortfolioService";

const initState= {
    listPortfolio : [],
    status: 'idle',
    portfolioSelect:null
}

export const createPortfolio=createAsyncThunk('portfolio/create',async(name)=>{
    let portfolio = await createEPortfolioAPI(name)
    return portfolio.data
})

export const getListPortfolio = createAsyncThunk('portfolio/list', async()=>{
    let portfolios = await listEPortfolioAPI()
    return portfolios.data
})

export const getPortfolioById=createAsyncThunk('portfolio/getPortfolio', async(id)=>{
    let portfolio = await getPortfolioAPI(id)
    return portfolio.data
})

export const deletePortfolio = createAsyncThunk('portfolio/delete', async(id)=>{
    let result = await deletePortfolioAPI(id)
    return id
})

export const getListSection = createAsyncThunk('portfolio/listSection', async(id)=>{
    let sections = await getListSectionAPI(id)
    return {
        portfolioId: id,
        sections: sections.data
    }
})


export const getPagesInSection= createAsyncThunk('portfolio/pagesInSection', async(sectionId)=>{
    let pages = await getPagesInSectionAPI(sectionId)
    return pages.data
})

export const updatePage = createAsyncThunk('portfolio/updatePage', async ({pageId, content})=>{
    let page = await updatePageAPI(pageId, content)
    return page.data
})

export const updatePortfolio = createAsyncThunk('portfolio/updateState', async({id, state})=>{
    let portfolio = await changeStatePortfolioAPI(id, state)
    return portfolio.data
})

const EPortfolioSlice =createSlice({
    name:'ePortfolio',
    initialState: initState,
    reducers: {},
    extraReducers:builder => {
        builder.addCase(createPortfolio.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(createPortfolio.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(createPortfolio.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.listPortfolio = [action.payload,...state.listPortfolio]
        }).addCase(getListPortfolio.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(getListPortfolio.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(getListPortfolio.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.listPortfolio = [...action.payload]
        }).addCase(getPortfolioById.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(getPortfolioById.rejected, (state, action)=>{
            state.status = 'idle'
        }).addCase(getPortfolioById.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.portfolioSelect= action.payload
        }).addCase(deletePortfolio.pending, (state,action)=>{
            state.status ='loading'
        }).addCase(deletePortfolio.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(deletePortfolio.fulfilled, (state,action)=>{
            state.status = 'idle'
            let portfolios = [...state.listPortfolio]
            for(let i =0;i< portfolios.length; i++){
                if(portfolios[i]._id== action.payload){
                    portfolios.splice(i, 1)
                    break
                }
            }
            state.listPortfolio = [...portfolios]
        }).addCase(getListSection.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getListSection.rejected,(state,action)=>{
            state.status= 'idle'
        }).addCase(getListSection.fulfilled, (state,action)=>{
            state.status = 'idle'
            let portfolios = [...state.listPortfolio]
            for(let i=0; i< portfolios.length; i++){
                if(portfolios[i]._id == action.payload.portfolioId){
                    portfolios[i].sections = action.payload.sections
                    break
                }
            }
            state.listPortfolio = [...portfolios]

        }).addCase(getPagesInSection.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getPagesInSection.fulfilled, (state,action)=>{
            state.status = 'idle'
        }).addCase(getPagesInSection.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(updatePage.pending, (state,action)=>{
            state.status='pending'
        }).addCase(updatePage.rejected, (state,action)=>{
            state.status= 'idle'
        }).addCase(updatePage.fulfilled, (state,action)=>{
            state.status = 'idle'
        }).addCase(updatePortfolio.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(updatePortfolio.rejected,(state,action)=>{
            state.status= 'idle'
        }).addCase(updatePortfolio.fulfilled, (state,action)=>{
            state.status = 'idle'
            let portfolios = [...state.listPortfolio]
            for(let i=0; i< portfolios.length; i++){
                if(portfolios[i]._id == action.payload._id){
                    portfolios[i].workflowState= action.payload.workflowState
                    break
                }
            }
            state.listPortfolio = [...portfolios]

        })
    }
})

export default EPortfolioSlice.reducer
