import React, {useEffect, useState} from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {clearToken, refreshToken, verifyToken} from "./Features/authSlice";
import Loading from "./pages/Loading/Loading";

export const PrivateRoute= ({component:Component, ...rest})=>{
    const jwtRefeshtoken =useSelector(state=>state.auth.jwtRefeshtoken)
    const [isAuth,setIsAuth] = useState(false)
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()

    useEffect(()=>{
            dispatch(verifyToken()).unwrap().then(data=>{
                setIsAuth(true)
                setLoading(false)

            }).catch(err=>{
                if(jwtRefeshtoken){
                    dispatch(refreshToken(jwtRefeshtoken)).unwrap().then(data=>{
                        setIsAuth(true)
                        setLoading(false)

                    }).catch(err=>{
                        dispatch(clearToken())
                        setIsAuth(false)
                        setLoading(false)

                    })
                }else{
                    dispatch(clearToken())
                    setIsAuth(false)
                    setLoading(false)

                }


            })

    }, [] )

    if(loading){
        return <Loading/>
    }else{
        if(isAuth) {
            return <Outlet/>
        }else {
            return <Navigate to={'/login'}/>
        }

    }

}
