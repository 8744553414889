import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {listAnnouncementAPI} from "../../services/AnnouncementService";


const initState={
    state: 'idle',
    announcements: [],
    show_announcements_on_home_page:false,
    home_page_announcement_limit:0
}

export const getAnnouncements = createAsyncThunk('announcement/listAnnouncement', async(courseId)=>{
    let announcements = await listAnnouncementAPI(courseId)
    return announcements.data
})


const AnnouncementSlice = createSlice({
    name:'announcement',
    initialState: initState,
    reducers: {
        resetAnnoutcements:(state,action)=>{
            state.announcements = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(getAnnouncements.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getAnnouncements.fulfilled, (state,action)=>{
            state.announcements = action.payload.announcements
            state.home_page_announcement_limit = action.payload.home_page_announcement_limit
            state.show_announcements_on_home_page= action.payload.show_announcements_on_home_page
            state.status = 'idle'
        }).addCase(getAnnouncements.rejected, (state,action)=>{
            state.status = 'idle'
        })
    }
})

export default AnnouncementSlice.reducer

export const { resetAnnoutcements } = AnnouncementSlice.actions