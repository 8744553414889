import React, { useEffect, useState } from 'react'
import checkIcon from '../../assetes/images/icons/select.svg'
import unCheckIcon from '../../assetes/images/icons/unselect.svg'
import { useTranslation } from 'react-i18next'

export default function UnitargetStd({uniTarget, handleSelect, setOpenDetail}){
    const {t, i18n} =useTranslation()
    const [teacherEvaluation, setTeacherEvaluation] = useState(0)
    const [studentEvaluation, setStudentEvaluation] = useState(0)
    const [parentEvaluation, setParentEvaluation] = useState(0)
    const getUnitTargetDisplay = (unitTarget)=>{
        if(i18n && i18n.language !="vn" && unitTarget.QuestionForStudentEN && unitTarget.QuestionForStudentEN !="" ){
            return unitTarget.QuestionForStudentEN
        }else{
            return unitTarget.QuestionForStudent
        }
       }
    useEffect(()=>{
        if(uniTarget && uniTarget.evaluations){
            let teachers = 0
            let stds = 0
            let parents =0
            for(let evalute of uniTarget.evaluations){
                for(let ut of evalute.uts){
                  if(ut.userType== 'teacher' && uniTarget._id == ut.unitTargetBlueprintId){
                    teachers+=1
                  }
                  if(ut.userType== 'student'  && uniTarget._id == ut.unitTargetBlueprintId){
                    stds+=1
                  }
                  if(ut.userType== 'parent'  && uniTarget._id == ut.unitTargetBlueprintId){
                    parents+=1
                  }
                }
               
              }
            setTeacherEvaluation(teachers)
            setStudentEvaluation(stds)
            setParentEvaluation(parents)
        }
    },[uniTarget])
    return <>
    {uniTarget && uniTarget.QuestionForStudent && uniTarget.QuestionForStudent !=""  &&<>
        <div className='rounded px-3 py-2 my-2 flex justify-start items-start bg-bgstdtarget text-[14px]  text-textcolor'>
        <div className='w-[50px]'>
            <button onClick={(e)=>{
                handleSelect(uniTarget)
            }}>
                {!uniTarget.check?<img src={unCheckIcon}  />:<img src={checkIcon}/>}
            </button>
        </div>
        <div className='w-full flex-1'>
             <h3 className='font-semibold text-btnbg'>{uniTarget.UnitTargetCode}-{getUnitTargetDisplay(uniTarget) }</h3>
             <div className=' flex text-[14px] text-textcolor2 font-senmibold  flex-col md:flex-row justify-start pb-2 space-x-0 md:space-x-3'>
                <div>
                    <span>{t("Teacher")}: {teacherEvaluation}</span>
                </div>
                <div>
                    <span>{t("Student")}: {studentEvaluation}</span>
                </div>
                <div>
                    <span>{t("Parent")}: {parentEvaluation}</span>
                </div>
                <div>
                    <button  className='underline' onClick={()=>{setOpenDetail(uniTarget)}}>{t("Detail")}</button>
                </div>
            </div>
        </div>
    </div>
    </>}
    
    </>
}
