import axios from "axios";
import {refreshToken} from "../Features/authSlice";

const _axiosPublic = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND}`,
});
const _axiosPrivate = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND}`,
});

export const setup = (store)=>{

    _axiosPrivate.interceptors.request.use(

        async (config) => {
            if (!config.headers['Authorization']) {
                config.headers['Authorization'] = `Bearer ${store.getState().auth.jwtToken}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    _axiosPrivate.interceptors.response.use(function (response) {
        return response;
    }, async function (error) {
        if(error.response.status ===401 && !error.config._retry){
            error.config._retry = true
            await store.dispatch(refreshToken({token:store.getState().auth.jwtRefeshtoken}))
            error.config.headers['Authorization'] = `Bearer ${store.getState().auth.jwtToken}`
            return _axiosPrivate(error.config)
        }
        return Promise.reject(error);
    });

}


export const axiosPublic = _axiosPublic
export const axiosPrivate = _axiosPrivate
