const {axiosPrivate} = require("./AxiosService");

exports.sendDeviceTokenAPI=(deviceToken)=>{
    return axiosPrivate.post(`${process.env.REACT_APP_BACKEND}/notify/subcriptionNotify`,{
        deviceToken: deviceToken
    })
}

exports.getUnreadNotificationAPI= ()=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/notify/numberUnread`)
}

exports.getNotifiesAPI = (page)=>{
 return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/notify/getNotifies?page=${page}`)
}

exports.readNotifyAPI=(notify_id)=>{
    return axiosPrivate.post(`${process.env.REACT_APP_BACKEND}/notify/readNotify`,{
        notifyId: notify_id
    })
}
