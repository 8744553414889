import {axiosPrivate} from "./AxiosService";

export const listYourEnrollApi = ()=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/enroll/yourenrolls`)
}

export const listYourEnrollInvitedAPI=()=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/enroll/listYourInvited`)
}

export const replyInviteAPI=(courseId, enrollId, state)=>{
    return axiosPrivate.post(`${process.env.REACT_APP_BACKEND}/enroll/replyInvite`,{
        courseId: courseId,
        enrollId: enrollId,
        state:state
    })
}
