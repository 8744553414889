// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage, deleteToken } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyC0w-Eqb9QuEm2nJ_0RgcmBNDAE02R_ba4",
    authDomain: "vinschool-f2567.firebaseapp.com",
    databaseURL: "https://vinschool-f2567.firebaseio.com",
    projectId: "vinschool-f2567",
    storageBucket: "vinschool-f2567.appspot.com",
    messagingSenderId: "991151103865",
    appId: "1:991151103865:web:49e0e0c9e5d93280cf19a8",
    measurementId: "G-PLQS074J25"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);

export const getTokenFCM = (setTokenFound) => {
    return getToken(messaging, {vapidKey: 'BOKYpvQ77RIHQe3LTkUFg0jNF7qnJcj3TBD2dviikGLWs6CAOwaWIf8vA4fltkSQM_ishS7eBWmPN6pQ2joSdrs'}).then((currentToken) => {
        if (currentToken) {
            setTokenFound(currentToken);
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        } else {
            setTokenFound(null);
            // shows on the UI that permission is required
        }
    }).catch((err) => {
        // catch error while creating client token
    });
}

export const deleteTokenFCM = ()=>{
    return deleteToken(messaging)
}


export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });

