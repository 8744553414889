import React, { useEffect, useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { useTranslation } from 'react-i18next'



export default function RadioComponent({selected, setSelected}) {
  

const {t,i18n} =useTranslation()
const plans = [
  {
    name: t("Not Yet Met Expectation"),
    value: '1'  
  },
  {
    name: t("Developing"),
    value: '2',
  },
  {
    name: t("Met Expectation"),
    value: '3',
    },
    {
        name: t("Exceed Expectation"),
        value: '4',
}
]
  return (
    <div className="w-full pb-3">
      <div className="mx-auto w-full ">
        <RadioGroup value={selected} onChange={setSelected}>
          <RadioGroup.Label className="sr-only">{t("Mastery levels")} </RadioGroup.Label>
          <div className=" grid  grid-cols-2 md:grid-cols-4 gap-4">
            {plans.map((plan) => (
              <RadioGroup.Option
                key={plan.name}
                value={plan.value}
                className={({ active, checked }) =>
                  `${
                    active
                      ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300'
                      : ''
                  }
                  ${
                    checked ? 'bg-sky-900 bg-opacity-75 text-white' : 'bg-white'
                  }
                    relative  cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none`
                }
              >
                {({ active, checked }) => (
                  <>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <div className="text-sm">
                          <RadioGroup.Label
                            as="p"
                            className={`font-medium  ${
                              checked ? 'text-white' : 'text-gray-900'
                            }`}
                          >
                            {plan.name}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as="span"
                            className={`inline ${
                              checked ? 'text-sky-100' : 'text-gray-500'
                            }`}
                          >
                            {' '}
                           
                          </RadioGroup.Description>
                        </div>
                      </div>
                      {checked && (
                        <div className="shrink-0 text-white">
                          <CheckIcon className="h-6 w-6" />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </div>
  )
}

function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
