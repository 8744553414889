import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    allDiscussionAPI, changeSubscribeTopicAPI,
    createNewDiscussionTopicAPI,
    deleteTopicAPI,
    getDiscussionTopicAPI
} from "../../../services/DiscussionService";

const initialSate={
    discussions: [],
    discussionSelect: null,
    status : 'ide',
    error:null
}

export const allDiscussion = createAsyncThunk('discussion/alldiscussions',async(dataInput)=>{
   let discussions = await allDiscussionAPI(dataInput.courseId)
    return {
       discussions: discussions.data
    }
})

export const createDiscussionTopic = createAsyncThunk('discussion/creatediscussiontopic', async(dataInput)=>{
    let discussion = await createNewDiscussionTopicAPI(dataInput.courseId,dataInput.sectionId, dataInput.title, dataInput.description, dataInput.startAt,dataInput.endAt,dataInput.attachment)
    return {
        discussion: discussion.data
    }
})

export const getDiscussionTopic= createAsyncThunk('discussion/discussiontopic', async(dataInput)=>{
    let discussion = await getDiscussionTopicAPI(dataInput.courseId, dataInput.discussionId)
    return {
        discussion: discussion.data
    }
})

export const changeSubscribeTopic = createAsyncThunk('discussion/changesubscribetopic', async(dataInput)=>{
    let subscribe = await changeSubscribeTopicAPI(dataInput.courseId, dataInput.topicId, dataInput.flag)
    return {
        subscribe: subscribe,
        flag: dataInput.flag
    }
})

export const deleteDiscussionTopic = createAsyncThunk('discussion/deletetopic',async({courseId, topicId})=>{
    let discussionResult = await deleteTopicAPI(courseId, topicId)
    return {
        topicId: topicId
    }
} )

export const DiscussionSlice = createSlice({
    name:'discussion',
    initialState: initialSate,
    reducers:{
    },
    extraReducers:builder => {
        builder.addCase(allDiscussion.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(allDiscussion.rejected, (state,action)=>{
            state.status = 'ide'
            state.error = action.error.message
        }).addCase(allDiscussion.fulfilled, (state,action)=>{
            state.status = 'ide'
            state.discussions= action.payload.discussions
        }).addCase(createDiscussionTopic.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(createDiscussionTopic.fulfilled, (state,action)=>{
            state.status = 'ide'
            state.discussionSelect = action.payload.discussion
        }).addCase(createDiscussionTopic.rejected, (state,action)=>{
            state.status = 'ide'
            state.error = action.error.message
        }).addCase(getDiscussionTopic.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(getDiscussionTopic.rejected, (state,action)=>{
            state.status = 'ide'
            state.error = action.error.message;
        }).addCase(getDiscussionTopic.fulfilled, (state,action)=>{
            state.status = 'ide'
            state.discussionSelect = action.payload.discussion
        }).addCase(changeSubscribeTopic.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(changeSubscribeTopic.rejected, (state,action)=>{
            state.status = 'ide'
            state.error = action.error.message;
        }).addCase(changeSubscribeTopic.fulfilled, (state,action)=>{
            state.status = 'ide'
            let discussion = state.discussionSelect
            if(action.payload.flag == 'subscribed'){

                discussion.subscribed = true
            }else{
                discussion.subscribed = false
            }
            state.discussionSelect = discussion
        }).addCase(deleteDiscussionTopic.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(deleteDiscussionTopic.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(deleteDiscussionTopic.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.discussions = state.discussions.filter((x)=>{
                if(x.id != action.payload.topicId){
                    return x
                }
            })
        })
    }
})
