import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getPortfolioProfileAPI, updateProfileAPI} from "../../../services/PortfolioService";

const initialState = {
    status : 'idle',
    profile: null
}


export const getPortfolioProfile= createAsyncThunk('portfolioProfile/get', async(pageId)=>{
    let profile = await getPortfolioProfileAPI(pageId)
    return profile.data
})

export const updateProfile= createAsyncThunk('portfolioProfile/update', async({avatar, bio, email, viewEmail, website, viewWebsite, phone, viewPhone,address,viewAddress, profileId})=>{
    let profile = await updateProfileAPI(avatar,bio, email, website,phone, address, viewAddress, viewPhone, viewWebsite, viewEmail, profileId)
    return profile.data
})

const portfolioProfile= createSlice({
    name:'portfolioProfile',
    initialState:initialState,
    reducers:{},
    extraReducers: builder => {
        builder.addCase(getPortfolioProfile.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getPortfolioProfile.fulfilled, (state,action)=>{
            state.profile = action.payload
            state.status = 'idle'
        }).addCase(getPortfolioProfile.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(updateProfile.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(updateProfile.fulfilled, (state,action)=>{
            state.profile = action.payload
            state.status = 'idle'
        }).addCase(updateProfile.rejected, (state,action)=>{
            state.status = 'idle'
        })
    }
})

export default portfolioProfile.reducer
