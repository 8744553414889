import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    changeReadStatusAPI,
    deleteEntryAPI,
    editEntryAPI,
    getEntryRepliesAPI,
    getTopicEntriesAPI,
    postAEntryAPI,
    postAReplyAPI
} from "../../../services/DiscussionService";

const initialSate={
    entries: [],
    entrySelect: null,
    status : 'ide',
    error:null
}


export const postAReply = createAsyncThunk('discussionentries/postareply', async(dataInput)=>{
    let reply = await postAReplyAPI(dataInput.courseId, dataInput.topicId, dataInput.entryId, dataInput.message, dataInput.attachment)
    return {
        reply: reply.data,
        entry: dataInput.entryId
    }
})

export const postAEntry  =createAsyncThunk('discussionentries/postaentry',async(dataInput)=>{
    let entry = await postAEntryAPI(dataInput.courseId,dataInput.topicId, dataInput.message, dataInput.attachment)
    return {
        entry: entry.data
    }
})

export const getAllTopicEntries = createAsyncThunk('discussionentries/getalltopicentries', async(dataInput)=>{
    let entries= await getTopicEntriesAPI(dataInput.courseId, dataInput.topicId)
    return {
        entries :entries.data
    }
})

export const getAllEntryReplies = createAsyncThunk('discussionentries/getallentryreplies', async(dataInput)=>{
    let replies = await getEntryRepliesAPI(dataInput.courseId, dataInput.topicId, dataInput.entryId)
    return {
        entry: dataInput.entryId,
        replies: replies.data
    }
})

export const changeReadState = createAsyncThunk('disscussionentries/changereadstate', async(dataInput)=>{
    let readStatus = await changeReadStatusAPI(dataInput.courseId, dataInput.topicId, dataInput.entryId, dataInput.flag)
    return {
        readStatus: readStatus.data,
        flag: dataInput.flag,
        parent:dataInput.parent,
        entry:dataInput.entryId
    }
})

export const deleteAEntry = createAsyncThunk('discuessionentries/delete', async({courseId, topicId, entryId})=>{
    let result = await deleteEntryAPI(courseId, topicId, entryId)
    return {
        entryId: entryId
    }
})

export const editEntry= createAsyncThunk('discussionEntries/edit', async({courseId, topicId, entryId, msg})=>{
    let entry = await editEntryAPI(courseId, topicId, entryId, msg)
    return entry.data
})

export const deleteReply = createAsyncThunk('discussionEntries/deleteReply',async({courseId, topicId, entryId, replyId})=>{
    let result = await deleteEntryAPI(courseId, topicId, replyId)
    return {
        entryId: entryId,
        replyId: replyId
    }
})

export const editReplyAct = createAsyncThunk('discussionEntries/editreply', async({courseId, topicId, entryId, replyId, msg})=>{
    let reply = await editEntryAPI(courseId, topicId, replyId, msg)
    return {
        entryId: entryId,
        reply: reply.data
    }
    
})

export const DiscussionEntrySlice = createSlice({
    name:'DiscussionEntry',
    initialState: initialSate,
    reducers:{
    },
    extraReducers:builder => {
        builder.addCase(postAEntry.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(postAEntry.rejected, (state, action)=>{
            state.status = 'ide'
            state.error = action.error.message;
        }).addCase(postAEntry.fulfilled, (state,action)=>{
            state.status = 'ide'
            state.entrySelect = action.payload.entry
            let _entries = [...state.entries]
            _entries.push(action.payload.entry)
            state.entries = _entries
        }).addCase(getAllTopicEntries.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(getAllTopicEntries.fulfilled, (state,action)=>{
            state.status = 'ide'
            state.entries = action.payload.entries
        }).addCase(getAllTopicEntries.rejected, (state,action)=>{
            state.status = 'ide'
            state.error = action.error.message;
        }).addCase(postAReply.pending, (state, action)=>{
            state.status = 'loading'
        }).addCase(postAReply.fulfilled, (state,action)=>{
            state.status = 'ide'
            let entryId = action.payload.entry
            let _entries = [...state.entries]
            for(let i = 0; i< _entries.length; i++){
                if(_entries[i].id == entryId){
                    if(_entries[i]['replies']){
                        _entries[i]['replies'] =[action.payload.reply, ..._entries[i]['replies']]
                    }else{
                        _entries[i]['replies'] = [action.payload.reply]
                    }
                }
            }
            state.entries = _entries
        }).addCase(postAReply.rejected, (state,action)=>{
            state.status = 'ide'
            state.error = action.error.message
        }).addCase(getAllEntryReplies.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(getAllEntryReplies.rejected, (state,action)=>{
            state.status = 'ide'
            state.error = action.error.message;
        }).addCase(getAllEntryReplies.fulfilled, (state,action)=>{
            state.status = 'ide'
            let entryId = action.payload.entry
            let _entries = [...state.entries]
            for(let i = 0; i< _entries.length; i++){
                if(_entries[i].id == entryId){
                    _entries[i]['replies']= action.payload.replies
                }
            }
            state.entries = _entries
        }).addCase(changeReadState.pending, (state, action)=>{
            state.status = 'loading'
        }).addCase(changeReadState.rejected, (state,action)=>{
            state.status = 'ide'
            state.error = action.error.message;
        }).addCase(changeReadState.fulfilled, (state,action)=>{
            state.status = 'ide'
            if(action.payload.parent){
                let _entries = [...state.entries]
                for(let i = 0; i< _entries.length; i++){
                    if(_entries[i].id == action.payload.parent){
                        for(let j = 0; j< _entries[i].replies.length; j++){
                            if(_entries[i].replies[j].id == action.payload.entry){
                                _entries[i].replies[j].read_state = action.payload.flag
                            }
                        }
                    }
                }
                state.entries = _entries
            }else{
                let _entries = [...state.entries]
                for(let i = 0; i< _entries.length; i++){
                    if(_entries[i].id == action.payload.entry){
                        _entries[i].read_state =  action.payload.flag
                    }
                }
                state.entries = _entries
            }
        }).addCase(deleteAEntry.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(deleteAEntry.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(deleteAEntry.fulfilled, (state, action)=>{
            state.status= 'idle'
            let _entries = [...state.entries]
            state.entries = _entries.filter((x)=>{
                if(x.id != action.payload.entryId) return x
            })
        }).addCase(editEntry.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(editEntry.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(editEntry.fulfilled, (state,action)=>{
            state.status = 'idle'
            let _entries = [...state.entries]
            state.entries = _entries.map((x)=>{
                if(x.id != action.payload.id){ return x}
                else{
                   x.updated_at=action.payload.updated_at
                   x.message = action.payload.message
                   return x
                }
            })
        }).addCase(deleteReply.pending , (state, action)=>{
            state.status = 'loading'
        }).addCase(deleteReply.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(deleteReply.fulfilled, (state,action)=>{
            state.status = 'idle'
            let _entries = [...state.entries]
            state.entries = _entries.map((x)=>{
                if(x.id != action.payload.entryId){ return x}
                else{
                   x.replies= x.replies.filter(y=>{
                    if(y.id!= action.payload.replyId) return y
                   })
                   return x
                }
            })
        }).addCase(editReplyAct.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(editReplyAct.rejected, (state,action)=>{
            state.status  = 'idle'
        }).addCase(editReplyAct.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.entries = state.entries.map((x)=>{
                if(x.id != action.payload.entryId){ return x}
                else{
                   x.replies= x.replies.map(y=>{
                    if(y.id != action.payload?.reply?.id){ return y}
                    else{
                        y.updated_at=action.payload.reply?.updated_at
                        y.message = action.payload.reply?.message
                        return y
                    }
                   })
                   return x
                }
            })
        })
    }
})
