import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {changeLetterStateAPI, getListLetterPublishAPI, getSOLetterAPI} from "../../../services/PortfolioService";

const initState= {
    status: 'idle',
    soLetter: [],
    soLetterPublish: []
}

export const getIntroLetterSO=createAsyncThunk('introLetter/getLetterFromSO',async (pageId)=>{
    let letters = await getSOLetterAPI(pageId)
    return letters.data
})

export const changeLetterState= createAsyncThunk('introLetter/changeState', async({pageId, soId, state})=>{
    let result = await changeLetterStateAPI(pageId, state, soId)
    return {
        soId: soId,
        state:state
    }
})

export const getListLetterPublish = createAsyncThunk('introLetter/listPublish', async(pageId)=>{
    let result = await getListLetterPublishAPI(pageId)
    return result.data
})

const introLetterSlice = createSlice({
    name: 'introLetter',
    initialState:initState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getIntroLetterSO.pending,(state,action)=>{
            state.status = 'pending'
        }).addCase(getIntroLetterSO.rejected,(state,action)=>{
            state.status = 'idle'
        }).addCase(getIntroLetterSO.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.soLetter = action.payload
        }).addCase(changeLetterState.pending,(state,action)=>{
            state.status = 'pending'
        }).addCase(changeLetterState.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.soLetter = [...state.soLetter.map(letter=>{
                if(letter.id == action.payload.soId){
                    letter.workflowState = action.payload.state
                    return letter
                }else{
                    return letter
                }
            })]
        }).addCase(changeLetterState.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(getListLetterPublish.pending,(state,action)=>{
            state.status = 'pending'
        }).addCase(getListLetterPublish.rejected,(state,action)=>{
            state.status = 'idle'
        }).addCase(getListLetterPublish.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.soLetterPublish = action.payload
        })
    }
})

export default introLetterSlice.reducer
