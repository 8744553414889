import {axiosPrivate, axiosPublic} from "./AxiosService";
export const loginApi = (code,v2, redirect_url)=>{
    return axiosPublic.post(`auth/login`, {
        code: code,
        v2:v2,
        redirect_url: redirect_url
    })
}


export const loginFromLMSApi = (code, lmsV2Url)=>{
    return axiosPublic.post(`auth/loginFromLMS`, {
        code: code,
        lmsV2Url:lmsV2Url
    })
}

export const loginGoogleApi = (code,scope)=>{
    return axiosPublic.post(`auth/loginGoogle`, {
        code:code,
        scope:scope

    })
}

export const logoutApi = (token)=> {
    return axiosPrivate.post(`auth/logout`, {})
}

export const refreshTokenApi = (refreshToken)=>{
    return axiosPublic.post('auth/refreshtoken', {}, {
        headers:{
            'Authorization':`Bearer ${refreshToken}`
        }
    })
}

export const verifyTokenApi = ()=>{
    return axiosPrivate.get('auth/verifytoken')
}


export const getSoProfileAPI = ()=>{
    return axiosPrivate.get('user/soProfile')
}

export const changeLanguageAPI= (lang)=>{
    return axiosPrivate.post('user/changeLang',{
        lang: lang
    })
}

export const changeViewOldCourseAPI= (flag)=>{
    return axiosPrivate.put('user/changeViewOldCourse',{
        flag: flag
    })
}

export const swapToV1API=()=>{
    return axiosPrivate.post(`user/swapToV1`, {
        lmsV2:false
    })
}


export const getSessionTokenWebAPI=(url)=>{
    return axiosPrivate.post('auth/getSessionTokenUrl', {
        url: url
    })
}

export const getUserInfoAPI = ()=>{
    return axiosPrivate.get('auth/userInfo')
}


export const turnOffGuideAPI=()=>{
    return axiosPrivate.put('user/turnoffGuild',{})
}


export const getGlobalNotificationsAPI = ()=>{
    return axiosPrivate.get('user/getGlobalNotification')
}

export const closeGlobalNotificationAPI=(noti_id)=>{
    return axiosPrivate.delete(`user/closeGlobalNotification?noti_id=${noti_id}`)
}