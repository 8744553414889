import React, {useCallback, useState} from 'react'
import Webcam from "react-webcam";
import {dataURItoBlob} from "../../utils/utilService";
import {useTranslation} from "react-i18next";
const videoConstraints = {
    width: 300,
    height: 300,
    facingMode: "user"
};

const WebcamAvatar = ({setOpen,  setFileUpload, fileUpload})=>{
    const {t, i18n} = useTranslation()
    const webcamRef = React.useRef(null);
    const [imgCapture ,setImageCapture] = useState(null)
    const capture = React.useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            setImageCapture(imageSrc)

        },
        [webcamRef]
    );

    const usePhoto = async ()=>{
        let _files = []
        _files =[{
            file: dataURItoBlob(imgCapture),
            fileName:'photo_'+ new Date().getTime() +".jpg",
            type:'capture'
        }]
        setFileUpload(_files)
        setImageCapture(false)
        setOpen(false)
    }

  return (
    <div className=' pt-2'>
        <div className={'flex justify-center'}>
            {imgCapture ==null && <Webcam
                audio={false}
                height={300}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width={300}
                videoConstraints={videoConstraints}
            />}
            {imgCapture && <img className={'rounded-full w-[300px] h-[300px]'} src={imgCapture} alt='img capture'/>}
        </div>
      {imgCapture == null && <div className='w-full flex justify-center space-x-3 p-2'>
        <button className=' justify-end bg-bgcoloz text-black px-4 py-2 rounded-md' onClick={()=>{setOpen(false)}}>{t('WebcamComponent.cancel')}</button>
        <button className=' justify-end bg-indigo-500 text-white px-4 py-2 rounded-md' onClick={capture} >{t('WebcamComponent.capturePhoto')}</button>
    </div>}
    {imgCapture!= null && <div className='w-full flex justify-center space-x-3 p-2'>
        <button className=' justify-end bg-bgcoloz text-black px-4 py-2 rounded-md' onClick={()=>{
            setImageCapture(null)
        }}>{t('WebcamComponent.tryagain')}</button>
        <button className=' justify-end bg-indigo-500 text-white px-4 py-2 rounded-md' onClick={usePhoto}>{t('WebcamComponent.usethisphoto')}</button>
    </div>}

    </div>
  );
}

export default WebcamAvatar
