import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    delAdDocAPI,
    delCertAPI,
    getAdDocsAPI,
    getDocByIdAPI,
    getListCertAPI,
    saveAdDocsAPI
} from "../../../services/PortfolioService";
import {delCert, getCertById} from "./CertSlice";

const initialState={
    adDocs:[],
    status: 'idle'
}


export const saveAdDoc = createAsyncThunk('adDoc/save', async ({pageId, docName, docKey})=>{
    let doc =await saveAdDocsAPI(docName,docKey,pageId)
    return doc.data
})


export const getAdDocs = createAsyncThunk('adDoc/getList', async(pageId)=>{
    let docs = await getAdDocsAPI(pageId)
    return docs.data
})

export const delAdDoc = createAsyncThunk('adDoc/del', async(docId)=>{
    let doc = await delAdDocAPI(docId)
    return doc.data
})

export const getDocById = createAsyncThunk('adDoc/getDocById', async(docId)=>{
    let doc = await getDocByIdAPI(docId)
    return doc.data
})

const docSlice = createSlice({
    name:'adDoc',
    initialState:initialState,
    reducers:{},
    extraReducers: builder => {
        builder.addCase(saveAdDoc.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(saveAdDoc.rejected, (state,action)=>{
            state.status= 'idle'
        }).addCase(saveAdDoc.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.adDocs = [...state.adDocs, action.payload]
        }).addCase(getAdDocs.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getAdDocs.rejected, (state,action)=>{
            state.status= 'idle'
        }).addCase(getAdDocs.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.adDocs =action.payload
        }).addCase(delAdDoc.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(delAdDoc.fulfilled,(state,action)=>{
            state.staus = 'idle'
            let _docs = [...state.adDocs]
            for(let i=0; i< _docs.length; i++){
                if(_docs[i]._id == action.payload._id){
                    _docs.splice(i, 1)
                    break
                }
            }
            state.adDocs = [..._docs]
        }).addCase(delAdDoc.rejected, (state,action)=>{
            state.status='idle'
        }).addCase(getDocById.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getDocById.fulfilled,(state,action)=>{
            state.staus = 'idle'
        }).addCase(getDocById.rejected, (state,action)=>{
            state.status='idle'
        })
    }
})
export default docSlice.reducer
