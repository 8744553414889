import {createAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {listYourCourseApi, listYourCompleteCourseApi} from "../services/CourseService";

const initialState={
    listCourse:[],
    listCompleteCourse:[],
    courseSelect:null,
    status: 'ide',
    error:null
}

export const listYourCourses = createAsyncThunk('course/listyourcourse', async()=>{
    let courses = await listYourCourseApi()
    return {
        courses: courses.data
    }
})

export const listYourCompleteCourses=createAsyncThunk('course/listYourCompleteCourse', async()=>{
    let courses = await listYourCompleteCourseApi()
    return {
        courses: courses.data
    }
})


export const coursesSlice = createSlice({
    name: 'courses',
    initialState:initialState,
    reducers: {

    },
    extraReducers:builder => {
        builder.addCase(listYourCourses.pending, (state, action)=>{
            state.status='loading'
        }).addCase(listYourCourses.rejected, (state,action)=>{
            state.status = 'ide'
            state.error = action.error.message
        }).addCase(listYourCourses.fulfilled, (state,action)=>{
            state.status = 'ide'
            state.listCourse = [...action.payload.courses.filter(course=> {
                if(!course.restrict_enrollments_to_course_dates ){
                    return course
                }else{
                    if(course.end_at && new Date().getTime() < new Date(course.end_at).getTime() && course.start_at && new Date().getTime() > new Date(course.start_at).getTime()){
                        return course
                    }
                    if(course.end_date == null && course.start_at && new Date().getTime() > new Date(course.start_at).getTime()){
                        return course
                    }
                    if(course.start_at == null && course.end_at && new Date().getTime() < new Date(course.end_at).getTime()){
                        return course
                    }
                    if(course.start_at == null && course.end_at== null){
                        return course
                    } 
                }
                
            })]

        }).addCase(listYourCompleteCourses.pending, (state, action)=>{
            state.status='loading'
        }).addCase(listYourCompleteCourses.rejected, (state,action)=>{
            state.status = 'ide'
            state.error = action.error.message
        }).addCase(listYourCompleteCourses.fulfilled, (state,action)=>{
            state.status = 'ide'
            state.listCompleteCourse = [...action.payload.courses.filter(course=> {
                if(!course.restrict_enrollments_to_course_dates ){
                    // if(course.end_at && new Date().getTime() < new Date(course.end_at).getTime() && course.start_at && new Date().getTime() > new Date(course.start_at).getTime()){
                    //     return course
                    // }
                    // if(course.end_date == null && course.start_at && new Date().getTime() > new Date(course.start_at).getTime()){
                    //     return course
                    // }
                    // if(course.start_at == null && course.end_at && new Date().getTime() < new Date(course.end_at).getTime()){
                    //     return course
                    // }
                    // if(course.start_at == null && course.end_at== null){
                    //     return course
                    // }
                    return course
                }else{
                    if(course.end_at && new Date().getTime() < new Date(course.end_at).getTime() && course.start_at && new Date().getTime() > new Date(course.start_at).getTime()){
                        return course
                    }
                    if(course.end_date == null && course.start_at && new Date().getTime() > new Date(course.start_at).getTime()){
                        return course
                    }
                    if(course.start_at == null && course.end_at && new Date().getTime() < new Date(course.end_at).getTime()){
                        return course
                    }
                    if(course.start_at == null && course.end_at== null){
                        return course
                    } 
                }
            })]
        })
    }
})
