import GCED from '../assetes/images/courseIcon/cong_dan_toan_cau.png'
import ICT from '../assetes/images/courseIcon/cong_nghe_thong_tin.png'
import BITCOIN from '../assetes/images/icons/bitcoin.png'
import PE from '../assetes/images/courseIcon/giao_duc_the_chat.png'
import NSCI from '../assetes/images/icons/NSCI.png'
import HISTORY from '../assetes/images/courseIcon/lich_su_dia_ly.png'
import ART from '../assetes/images/courseIcon/my_thuat.png'
import MATH from '../assetes/images/courseIcon/toan.png'
import MUS from '../assetes/images/courseIcon/am_nhac.png'
import DEFAULT_COURSE from '../assetes/images/courseIcon/mac_dinh.png'
export const imageUrl=(course_code)=>{
    if(course_code == undefined || course_code == null) {
        return DEFAULT_COURSE
    }
        if(course_code.includes('GCED')){
        return GCED
    }
    else if (course_code.includes('ICT') || course_code.includes('TI-')){
        return ICT
    }else if (course_code.includes('ESL')  || course_code.includes('TAVN') || course_code.includes('TANN')){
        return DEFAULT_COURSE
    }else if (course_code.includes('CLISE') || course_code.includes('GDPC')){
        return DEFAULT_COURSE
    }else if (course_code.includes('PE') ||course_code.includes('GDTC') ){
        return PE
    }else if (course_code.includes('NSCI') || course_code.includes('SCI') || course_code.includes('KHTN')){
        return NSCI
    }else if (course_code.includes('NSCI')){
        return NSCI
    }else if (course_code.includes('HIS') || course_code.includes('GEO') || course_code.includes('LSDL')){
        return HISTORY
    }else if (course_code.includes('ART') || course_code.includes('MT')){
        return ART
    }else if (course_code.includes('VLA') || course_code.includes('TV')){
        return DEFAULT_COURSE
    }else if(course_code.includes('MATH') || course_code.includes('TO')){
        return MATH
    }else if(course_code.includes('VNS') || course_code.includes('VNH')){
        return DEFAULT_COURSE
    }else if(course_code.includes('MUS') || course_code.includes('AN')){
        return MUS
    }else{
        return DEFAULT_COURSE
    }
}

export const getCourseName=(course_code, course_name, tih)=>{
    if(course_code == undefined || course_code == null) {
        return ""
    }
    course_code= course_code.trim()
    course_code = course_code.replaceAll(".","-")
    course_code = course_code.replaceAll("_", "-")
    let course_code_bk = course_code
  
    if(course_code.split('-').length <3){
        if(course_name && course_name.length>64){
            return course_name.substring(0, 64)+ ' ...'
        }else{
            return course_name
        }
    }
    course_code = course_code.split("-")[2]
    if(course_code.includes('GCED') || course_code_bk.includes('CAM-GCED') ||  course_code_bk.includes('AL-GCED') ||course_code_bk.includes('AS-GCED') || course_code_bk.includes('AL/AS-GCED')){
        return 'subject.GCED'
    }
    else if (course_code.includes('ICT') || course_code_bk.trim().includes('TI-')  ||   course_code_bk.includes('CAM-ICT') ||  course_code_bk.includes('AL-ICT') ||course_code_bk.includes('AS-ICT') || course_code_bk.includes('AL/AS-ICT')){
        return ('subject.ICT')
    }else if (course_code.includes('ESL') ||  course_code.trim().includes('CAM.ESL') || course_code.trim().includes('TAVN') || course_code.trim().includes('TANN')){
        return ('subject.ESL')
    }else if (course_code.includes('CLISE') || course_code.trim().includes('GDPC') ||   course_code_bk.includes('CAM-CLISE') ||  course_code_bk.includes('AL-CLISE') ||course_code_bk.includes('AS-CLISE') || course_code_bk.includes('AL/AS-CLISE')){
        return ('subject.CLISE')
    }else if (course_code.includes('SCI') || course_code.includes('NSCI') || course_code.trim().includes('KHTN')  || course_code_bk.includes('CAM-SCI') ||  course_code_bk.includes('AL-SCI') ||course_code_bk.includes('AS-SCI') || course_code_bk.includes('AL/AS-SCI')){
        if(tih) return 'subject.SCI'
        return ('subject.SCI2')
    }else if (course_code_bk.includes('HIS-GEO') || course_code.trim().includes('LSDL')  || course_code_bk.includes('CAM-HIS-GEO') ||  course_code_bk.includes('AL-HIS-GEO') ||course_code_bk.includes('AS-HIS-GEO') || course_code_bk.includes('AL/AS-HIS-GEO')) {
        return ('subject.HIS-GEO')
    }else if (course_code.includes('HIS')  || course_code_bk.includes('CAM-HIS') ||  course_code_bk.includes('AL-HIS') ||course_code_bk.includes('AS-HIS') || course_code_bk.includes('AL/AS-HIS')) {
        return ('subject.HIS')
    }else if (course_code.includes('GEO') || course_code.trim().includes('GEO') || course_code_bk.includes('CAM-GEO') ||  course_code_bk.includes('AL-GEO') ||course_code_bk.includes('AS-GEO') || course_code_bk.includes('AL/AS-GEO') ){
            return ('subject.GEO')
    }else if (course_code.includes('ART') || course_code.trim().includes('MT') || course_code_bk.includes('CAM-ART') ||  course_code_bk.includes('AL-ART') ||course_code_bk.includes('AS-ART') || course_code_bk.includes('AL/AS-ART') ){
        return ('subject.ART')
    }else if(course_code.trim().includes('TV') || course_code.includes('VLA') || course_code.includes('LITE') || course_code_bk.includes('CAM-VLA') ||  course_code_bk.includes('AL-VLA') ||course_code_bk.includes('AS-VLA') || course_code_bk.includes('AL/AS-VLA')){
        if(tih) return 'subject.VLA'
        return ('subject.VLA2')
    }else if(course_code.includes('MATH') ||course_code.trim().includes('TO') || course_code_bk.includes('CAM-MATH') ||  course_code_bk.includes('AL-MATH') ||course_code_bk.includes('AS-MATH') || course_code_bk.includes('AL/AS-MATH') ){
        return ("subject.MATHS")
    }else if(course_code.includes('VNS') || course_code.trim().includes('VNH') || course_code_bk.includes('CAM-VNS') ||  course_code_bk.includes('AL-VNS') ||course_code_bk.includes('AS-VNS') || course_code_bk.includes('AL/AS-VNS')){
        return ('subject.VNS')
    }else if(course_code.includes('MUS') || course_code.trim().includes('AN')  || course_code_bk.includes('CAM-MUS') ||  course_code_bk.includes('AL-MUS') ||course_code_bk.includes('AS-MUS') || course_code_bk.includes('AL/AS-MUS') ){
        return ('subject.MUS')
    }else if(course_code.includes('GUIDE') || course_code_bk.includes('CAM-GUIDE') ||  course_code_bk.includes('AL-GUIDE') ||course_code_bk.includes('AS-GUIDE') || course_code_bk.includes('AL/AS-GUIDE') ){
        return ('subject.GUIDES')
    }else if(course_code.includes('PHY') || course_code_bk.includes('CAM-PHY') ||  course_code_bk.includes('AL-PHY') ||course_code_bk.includes('AS-PHY') || course_code_bk.includes('AL/AS-PHY') ){
        return ('subject.PHY')
    }else if(course_code.includes('CHEM') || course_code_bk.includes('CAM-CHEM') ||  course_code_bk.includes('AL-CHEM') ||course_code_bk.includes('AS-CHEM') || course_code_bk.includes('AL/AS-CHEM') ){
        return ('subject.CHEM')
    }else if(course_code.includes('BIO') || course_code_bk.includes('CAM-BIO') ||  course_code_bk.includes('AL-BIO') ||course_code_bk.includes('AS-BIO') || course_code_bk.includes('AL/AS-BIO')  ){
        return ('subject.BIO')
    }else if(course_code.includes('TECH') || course_code_bk.includes('CAM-TECH') ||  course_code_bk.includes('AL-TECH') ||course_code_bk.includes('AS-TECH') || course_code_bk.includes('AL/AS-TECH')   ){
        return ('subject.TECH')
    }else if(course_code.includes('CIVIC') || course_code_bk.includes('CAM-CIVIC') ||  course_code_bk.includes('AL-CIVIC') ||course_code_bk.includes('AS-CIVIC') || course_code_bk.includes('AL/AS-CIVIC')  ){
        return ('subject.CIVIC')
    }else if(course_code_bk.includes('AP-STAT')  ){
        return ('subject.AP.STAT')
    }else if(course_code_bk.includes('AP-WHIS')  ){
        return ('subject.AP.WHIS')
    }else if(course_code_bk.includes('AP-HGEO')  ){
        return ('subject.AP.HGEO')
    }else if(course_code_bk.includes('AP-ENGL')  ){
        return ('subject.AP.ENGL')
    }else if(course_code_bk.includes('AP-PSY')  ){
        return ('subject.AP.PSY')
    }else if(course_code_bk.includes('AP-MICRO') || course_code_bk.includes('CAM-MICRO') ||  course_code_bk.includes('AL-MICRO') ||course_code_bk.includes('AS-MICRO') || course_code_bk.includes('AL/AS-MICRO') ){
        return ('subject.AP.MICRO')
    }else if(course_code.includes('CRIT')  || course_code_bk.includes('CAM-CRIT') ||  course_code_bk.includes('AL-CRIT') ||course_code_bk.includes('AS-CRIT') || course_code_bk.includes('AL/AS-CRIT')  ){
        return ('subject.CRIT')
    }else if(course_code.includes('MIL') || course_code_bk.includes('CAM-MIL') ||  course_code_bk.includes('AL-MIL') ||course_code_bk.includes('AS-MIL') || course_code_bk.includes('AL/AS-MIL')  ){
        return ('subject.MIL')
    }else if(course_code.includes('A&D') || course_code_bk.includes('CAM-A&D') ||  course_code_bk.includes('AL-A&D') ||course_code_bk.includes('AS-A&D') || course_code_bk.includes('AL/AS-A&D') ){
        return ('subject.A&D')
    }else if(course_code_bk.includes('CAM-BS') || course_code_bk.includes('AL/AS-BS')   || course_code_bk.includes('AS-BS')  || course_code_bk.includes('AL-BS') || course_code_bk.includes('BS')  ){
        return ('subject.BS')
    }else if(course_code_bk.includes('CAM-LITE')  || course_code.includes('LITE') || course_code_bk.includes('AS-LITE')  ||course_code_bk.includes('AL-LITE') || course_code_bk.includes('AL/AS-LITE') ){
        return ('subject.LITE')
    }else if(course_code_bk.includes('CAM-GP')  || course_code.includes('GP')){
        return ('subject.GP')
    }else if(course_code.includes('ENGLIT')  || course_code_bk.includes('CAM-ENGLIT') ||  course_code_bk.includes('AL-ENGLIT') ||course_code_bk.includes('AS-ENGLIT') || course_code_bk.includes('AL/AS-ENGLIT')){
        return ('subject.ENGLIT')
    }else if(course_code.includes('GP&R') || course_code_bk.includes('CAM-GP&R') ||  course_code_bk.includes('AL-GP&R') ||course_code_bk.includes('AS-GP&R') || course_code_bk.includes('AL/AS-GP&R') ){
        return ('subject.GP&R')
    }else if(course_code.includes('ECON') || course_code_bk.includes('CAM-ECON') ||  course_code_bk.includes('AL-ECON') ||course_code_bk.includes('AS-ECON') || course_code_bk.includes('AL/AS-ECON') ){
        return ('subject.ECON')
    }else if(course_code.includes('PSY')  || course_code_bk.includes('CAM-PSY') ||  course_code_bk.includes('AL-PSY') ||course_code_bk.includes('AS-PSY') || course_code_bk.includes('AL/AS-PSY')  ){
        return ('subject.PSY')
    }else if(course_code.includes('BUS')  || course_code_bk.includes('CAM-BUS') ||  course_code_bk.includes('AL-BUS') ||course_code_bk.includes('AS-BUS') || course_code_bk.includes('AL/AS-BUS') ){
        return ('subject.BUS')
    }else if(course_code.includes('SELFS') || course_code_bk.includes('CAM-SELFS') ||  course_code_bk.includes('AL-SELFS') ||course_code_bk.includes('AS-SELFS') || course_code_bk.includes('AL/AS-SELFS') ){
        return ('subject.SELFS')
    }else if(course_code.includes('SAT')  || course_code_bk.includes('CAM-SAT') ||  course_code_bk.includes('AL-SAT') ||course_code_bk.includes('AS-SAT') || course_code_bk.includes('AL/AS-SAT') ){
        return ('subject.SAT')
    }else if(course_code.includes('ACAW') ||  course_code_bk.includes('CAM-ACAW') ||  course_code_bk.includes('AL-ACAW') ||course_code_bk.includes('AS-ACAW') || course_code_bk.includes('AL/AS-ACAW')){
        return ('subject.ACAW')
    }else if(course_code.includes('CARRER') || course_code_bk.includes('CAM-CARRER') ||  course_code_bk.includes('AL-CARRER') ||course_code_bk.includes('AS-CARRER') || course_code_bk.includes('AL/AS-CARRER') ){
        return ('subject.CARRER')
    }else if(course_code.includes('PHIL') || course_code_bk.includes('CAM-PHIL') ||  course_code_bk.includes('AL-PHIL') ||course_code_bk.includes('AS-PHIL') || course_code_bk.includes('AL/AS-PHIL') ){
        return ('subject.PHIL')
    }else if(course_code.includes('SOC') || course_code_bk.includes('CAM-SOC') ||  course_code_bk.includes('AL-SOC') ||course_code_bk.includes('AS-SOC') || course_code_bk.includes('AL/AS-SOC') ){
        return ('subject.SOC')
    }else if(course_code.includes('READ')){
        return ('subject.READ')
    }else if (course_code.toUpperCase()=='PE' ||  course_code.toUpperCase().trim().includes('GDTC') ||  course_code_bk.includes('CAM-PE') ||  course_code_bk.includes('AL-PE') ||course_code_bk.includes('AS-PE') || course_code_bk.includes('AL/AS-PE')){
        return ('subject.PE')
    }else if(course_code.includes('AI') || course_code_bk.includes('CAM-AI') ||  course_code_bk.includes('AL-AI') ||course_code_bk.includes('AS-AI') || course_code_bk.includes('AL/AS-AI')  ){
        return ('subject.AI')
    }else if(course_code.includes('MS')  || course_code_bk.includes('CAM-MS') ||  course_code_bk.includes('AL-MS') ||course_code_bk.includes('AS-MS') || course_code_bk.includes('AL/AS-MS') ){
        return ('subject.MS')
    }else if(course_code.includes('CS')  || course_code_bk.includes('CAM-CS') || course_code_bk.includes('AS/AL-CS') ||  course_code_bk.includes('AL-CS') ||  course_code_bk.includes('AS-CS')  ){
        return ('subject.CS')
    }else if(course_code.toUpperCase()=='CH' ||course_code_bk.includes('CAM-CH') || course_code_bk.includes('CAM-CH') ||  course_code_bk.includes('AL-CH') ||course_code_bk.includes('AS-CH') || course_code_bk.includes('AL/AS-CH') ){
        return ('subject.CH')
    }else if(course_code.toUpperCase()=='JA'|| course_code_bk.includes('CAM-JA') ||  course_code_bk.includes('AL-JA') ||course_code_bk.includes('AS-JA') || course_code_bk.includes('AL/AS-JA') ){
        return ('subject.JA')
    }else if(course_code.toUpperCase()=='GE'|| course_code_bk.includes('CAM-GE') ||  course_code_bk.includes('AL-GE') ||course_code_bk.includes('AS-GE') || course_code_bk.includes('AL/AS-GE')  ){
        return ('subject.GE')
    }else if(course_code.toUpperCase() =='SP'  || course_code_bk.includes('CAM-SP') ||  course_code_bk.includes('AL-SP') ||course_code_bk.includes('AS-SP') || course_code_bk.includes('AL/AS-SP')  ){
        return ('subject.SP')
    }else if(course_code.toUpperCase()=='FR' || course_code_bk.includes('CAM-FR') ||  course_code_bk.includes('AL-FR') ||course_code_bk.includes('AS-FR') || course_code_bk.includes('AL/AS-FR') ){
        return ('subject.FR')
    }else if(course_code.toUpperCase()=='KO' || course_code_bk.includes('CAM-KO') ||  course_code_bk.includes('AL-KO') ||course_code_bk.includes('AS-KO') || course_code_bk.includes('AL/AS-KO') ){
        return ('subject.KO')
    }else{
        if(course_name && course_name.length>64){
            return course_name.substring(0, 64)+ ' ...'
        }else{
            return course_name
        }

    }
}


export const getCourseNameNotShort=(course_code, course_name, tih)=>{
    if(course_code == undefined || course_code == null) {
        return ""
    }
    course_code= course_code.trim()
    course_code = course_code.replaceAll(".","-")
    course_code = course_code.replaceAll("_", "-")
    let course_code_bk = course_code
   
    if(course_code.split('-').length <3){
        if(course_name && course_name.length>64){
            return course_name.substring(0, 64)+ ' ...'
        }else{
            return course_name
        }
    }
    course_code = course_code.split("-")[2]
    if(course_code.includes('GCED') || course_code_bk.includes('CAM-GCED') ||  course_code_bk.includes('AL-GCED') ||course_code_bk.includes('AS-GCED') || course_code_bk.includes('AL/AS-GCED')){
        return 'subject.GCED'
    }
    else if (course_code.includes('ICT') || course_code_bk.trim().includes('TI-')  ||   course_code_bk.includes('CAM-ICT') ||  course_code_bk.includes('AL-ICT') ||course_code_bk.includes('AS-ICT') || course_code_bk.includes('AL/AS-ICT')){
        return ('subject.ICT')
    }else if (course_code.includes('ESL') ||  course_code.trim().includes('CAM.ESL') || course_code.trim().includes('TAVN') || course_code.trim().includes('TANN')){
        return ('subject.ESL')
    }else if (course_code.includes('CLISE') || course_code.trim().includes('GDPC') ||   course_code_bk.includes('CAM-CLISE') ||  course_code_bk.includes('AL-CLISE') ||course_code_bk.includes('AS-CLISE') || course_code_bk.includes('AL/AS-CLISE')){
        return ('subject.CLISE')
    }else if (course_code.includes('SCI') || course_code.includes('NSCI') || course_code.trim().includes('KHTN')  || course_code_bk.includes('CAM-SCI') ||  course_code_bk.includes('AL-SCI') ||course_code_bk.includes('AS-SCI') || course_code_bk.includes('AL/AS-SCI')){
        if(tih) return 'subject.SCI'
        return ('subject.SCI2')
    }else if (course_code_bk.includes('HIS-GEO') || course_code.trim().includes('LSDL')  || course_code_bk.includes('CAM-HIS-GEO') ||  course_code_bk.includes('AL-HIS-GEO') ||course_code_bk.includes('AS-HIS-GEO') || course_code_bk.includes('AL/AS-HIS-GEO')) {
        return ('subject.HIS-GEO')
    }else if (course_code.includes('HIS')  || course_code_bk.includes('CAM-HIS') ||  course_code_bk.includes('AL-HIS') ||course_code_bk.includes('AS-HIS') || course_code_bk.includes('AL/AS-HIS')) {
        return ('subject.HIS')
    }else if (course_code.includes('GEO') || course_code.trim().includes('GEO') || course_code_bk.includes('CAM-GEO') ||  course_code_bk.includes('AL-GEO') ||course_code_bk.includes('AS-GEO') || course_code_bk.includes('AL/AS-GEO') ){
            return ('subject.GEO')
    }else if (course_code.includes('ART') || course_code.trim().includes('MT') || course_code_bk.includes('CAM-ART') ||  course_code_bk.includes('AL-ART') ||course_code_bk.includes('AS-ART') || course_code_bk.includes('AL/AS-ART') ){
        return ('subject.ART')
    }else if(course_code.trim().includes('TV') || course_code.includes('VLA') || course_code.includes('LITE') || course_code_bk.includes('CAM-VLA') ||  course_code_bk.includes('AL-VLA') ||course_code_bk.includes('AS-VLA') || course_code_bk.includes('AL/AS-VLA')){
        if(tih) return 'subject.VLA'
        return ('subject.VLA2')
    }else if(course_code.includes('MATH') ||course_code.trim().includes('TO') || course_code_bk.includes('CAM-MATH') ||  course_code_bk.includes('AL-MATH') ||course_code_bk.includes('AS-MATH') || course_code_bk.includes('AL/AS-MATH') ){
        return ("subject.MATHS")
    }else if(course_code.includes('VNS') || course_code.trim().includes('VNH') || course_code_bk.includes('CAM-VNS') ||  course_code_bk.includes('AL-VNS') ||course_code_bk.includes('AS-VNS') || course_code_bk.includes('AL/AS-VNS')){
        return ('subject.VNS')
    }else if(course_code.includes('MUS') || course_code.trim().includes('AN')  || course_code_bk.includes('CAM-MUS') ||  course_code_bk.includes('AL-MUS') ||course_code_bk.includes('AS-MUS') || course_code_bk.includes('AL/AS-MUS') ){
        return ('subject.MUS')
    }else if(course_code.includes('GUIDE') || course_code_bk.includes('CAM-GUIDE') ||  course_code_bk.includes('AL-GUIDE') ||course_code_bk.includes('AS-GUIDE') || course_code_bk.includes('AL/AS-GUIDE') ){
        return ('subject.GUIDES')
    }else if(course_code.includes('PHY') || course_code_bk.includes('CAM-PHY') ||  course_code_bk.includes('AL-PHY') ||course_code_bk.includes('AS-PHY') || course_code_bk.includes('AL/AS-PHY') ){
        return ('subject.PHY')
    }else if(course_code.includes('CHEM') || course_code_bk.includes('CAM-CHEM') ||  course_code_bk.includes('AL-CHEM') ||course_code_bk.includes('AS-CHEM') || course_code_bk.includes('AL/AS-CHEM') ){
        return ('subject.CHEM')
    }else if(course_code.includes('BIO') || course_code_bk.includes('CAM-BIO') ||  course_code_bk.includes('AL-BIO') ||course_code_bk.includes('AS-BIO') || course_code_bk.includes('AL/AS-BIO')  ){
        return ('subject.BIO')
    }else if(course_code.includes('TECH') || course_code_bk.includes('CAM-TECH') ||  course_code_bk.includes('AL-TECH') ||course_code_bk.includes('AS-TECH') || course_code_bk.includes('AL/AS-TECH')   ){
        return ('subject.TECH')
    }else if(course_code.includes('CIVIC') || course_code_bk.includes('CAM-CIVIC') ||  course_code_bk.includes('AL-CIVIC') ||course_code_bk.includes('AS-CIVIC') || course_code_bk.includes('AL/AS-CIVIC')  ){
        return ('subject.CIVIC')
    }else if(course_code_bk.includes('AP-STAT')  ){
        return ('subject.AP.STAT')
    }else if(course_code_bk.includes('AP-WHIS')  ){
        return ('subject.AP.WHIS')
    }else if(course_code_bk.includes('AP-HGEO')  ){
        return ('subject.AP.HGEO')
    }else if(course_code_bk.includes('AP-ENGL')  ){
        return ('subject.AP.ENGL')
    }else if(course_code_bk.includes('AP-PSY')  ){
        return ('subject.AP.PSY')
    }else if(course_code_bk.includes('AP-MICRO') || course_code_bk.includes('CAM-MICRO') ||  course_code_bk.includes('AL-MICRO') ||course_code_bk.includes('AS-MICRO') || course_code_bk.includes('AL/AS-MICRO') ){
        return ('subject.AP.MICRO')
    }else if(course_code.includes('CRIT')  || course_code_bk.includes('CAM-CRIT') ||  course_code_bk.includes('AL-CRIT') ||course_code_bk.includes('AS-CRIT') || course_code_bk.includes('AL/AS-CRIT')  ){
        return ('subject.CRIT')
    }else if(course_code.includes('MIL') || course_code_bk.includes('CAM-MIL') ||  course_code_bk.includes('AL-MIL') ||course_code_bk.includes('AS-MIL') || course_code_bk.includes('AL/AS-MIL')  ){
        return ('subject.MIL')
    }else if(course_code.includes('A&D') || course_code_bk.includes('CAM-A&D') ||  course_code_bk.includes('AL-A&D') ||course_code_bk.includes('AS-A&D') || course_code_bk.includes('AL/AS-A&D') ){
        return ('subject.A&D')
    }else if(course_code_bk.includes('CAM-BS') || course_code_bk.includes('AL/AS-BS')   || course_code_bk.includes('AS-BS')  || course_code_bk.includes('AL-BS') || course_code_bk.includes('BS')  ){
        return ('subject.BS')
    }else if(course_code_bk.includes('CAM-LITE')  || course_code.includes('LITE') || course_code_bk.includes('AS-LITE')  ||course_code_bk.includes('AL-LITE') || course_code_bk.includes('AL/AS-LITE') ){
        return ('subject.LITE')
    }else if(course_code_bk.includes('CAM-GP')  || course_code.includes('GP')){
        return ('subject.GP')
    }else if(course_code.includes('ENGLIT')  || course_code_bk.includes('CAM-ENGLIT') ||  course_code_bk.includes('AL-ENGLIT') ||course_code_bk.includes('AS-ENGLIT') || course_code_bk.includes('AL/AS-ENGLIT')){
        return ('subject.ENGLIT')
    }else if(course_code.includes('GP&R') || course_code_bk.includes('CAM-GP&R') ||  course_code_bk.includes('AL-GP&R') ||course_code_bk.includes('AS-GP&R') || course_code_bk.includes('AL/AS-GP&R') ){
        return ('subject.GP&R')
    }else if(course_code.includes('ECON') || course_code_bk.includes('CAM-ECON') ||  course_code_bk.includes('AL-ECON') ||course_code_bk.includes('AS-ECON') || course_code_bk.includes('AL/AS-ECON') ){
        return ('subject.ECON')
    }else if(course_code.includes('PSY')  || course_code_bk.includes('CAM-PSY') ||  course_code_bk.includes('AL-PSY') ||course_code_bk.includes('AS-PSY') || course_code_bk.includes('AL/AS-PSY')  ){
        return ('subject.PSY')
    }else if(course_code.includes('BUS')  || course_code_bk.includes('CAM-BUS') ||  course_code_bk.includes('AL-BUS') ||course_code_bk.includes('AS-BUS') || course_code_bk.includes('AL/AS-BUS') ){
        return ('subject.BUS')
    }else if(course_code.includes('SELFS') || course_code_bk.includes('CAM-SELFS') ||  course_code_bk.includes('AL-SELFS') ||course_code_bk.includes('AS-SELFS') || course_code_bk.includes('AL/AS-SELFS') ){
        return ('subject.SELFS')
    }else if(course_code.includes('SAT')  || course_code_bk.includes('CAM-SAT') ||  course_code_bk.includes('AL-SAT') ||course_code_bk.includes('AS-SAT') || course_code_bk.includes('AL/AS-SAT') ){
        return ('subject.SAT')
    }else if(course_code.includes('ACAW') ||  course_code_bk.includes('CAM-ACAW') ||  course_code_bk.includes('AL-ACAW') ||course_code_bk.includes('AS-ACAW') || course_code_bk.includes('AL/AS-ACAW')){
        return ('subject.ACAW')
    }else if(course_code.includes('CARRER') || course_code_bk.includes('CAM-CARRER') ||  course_code_bk.includes('AL-CARRER') ||course_code_bk.includes('AS-CARRER') || course_code_bk.includes('AL/AS-CARRER') ){
        return ('subject.CARRER')
    }else if(course_code.includes('PHIL') || course_code_bk.includes('CAM-PHIL') ||  course_code_bk.includes('AL-PHIL') ||course_code_bk.includes('AS-PHIL') || course_code_bk.includes('AL/AS-PHIL') ){
        return ('subject.PHIL')
    }else if(course_code.includes('SOC') || course_code_bk.includes('CAM-SOC') ||  course_code_bk.includes('AL-SOC') ||course_code_bk.includes('AS-SOC') || course_code_bk.includes('AL/AS-SOC') ){
        return ('subject.SOC')
    }else if(course_code.includes('READ')){
        return ('subject.READ')
    }else if (course_code.toUpperCase()=='PE' ||  course_code.toUpperCase().trim().includes('GDTC') ||  course_code_bk.includes('CAM-PE') ||  course_code_bk.includes('AL-PE') ||course_code_bk.includes('AS-PE') || course_code_bk.includes('AL/AS-PE')){
        return ('subject.PE')
    }else if(course_code.includes('AI') || course_code_bk.includes('CAM-AI') ||  course_code_bk.includes('AL-AI') ||course_code_bk.includes('AS-AI') || course_code_bk.includes('AL/AS-AI')  ){
        return ('subject.AI')
    }else if(course_code.includes('MS')  || course_code_bk.includes('CAM-MS') ||  course_code_bk.includes('AL-MS') ||course_code_bk.includes('AS-MS') || course_code_bk.includes('AL/AS-MS') ){
        return ('subject.MS')
    }else if(course_code.includes('CS')  || course_code_bk.includes('CAM-CS') || course_code_bk.includes('AS/AL-CS') ||  course_code_bk.includes('AL-CS') ||  course_code_bk.includes('AS-CS')  ){
        return ('subject.CS')
    }else if(course_code.toUpperCase()=='CH' ||course_code_bk.includes('CAM-CH') || course_code_bk.includes('CAM-CH') ||  course_code_bk.includes('AL-CH') ||course_code_bk.includes('AS-CH') || course_code_bk.includes('AL/AS-CH') ){
        return ('subject.CH')
    }else if(course_code.toUpperCase()=='JA'|| course_code_bk.includes('CAM-JA') ||  course_code_bk.includes('AL-JA') ||course_code_bk.includes('AS-JA') || course_code_bk.includes('AL/AS-JA') ){
        return ('subject.JA')
    }else if(course_code.toUpperCase()=='GE'|| course_code_bk.includes('CAM-GE') ||  course_code_bk.includes('AL-GE') ||course_code_bk.includes('AS-GE') || course_code_bk.includes('AL/AS-GE')  ){
        return ('subject.GE')
    }else if(course_code.toUpperCase() =='SP'  || course_code_bk.includes('CAM-SP') ||  course_code_bk.includes('AL-SP') ||course_code_bk.includes('AS-SP') || course_code_bk.includes('AL/AS-SP')  ){
        return ('subject.SP')
    }else if(course_code.toUpperCase()=='FR' || course_code_bk.includes('CAM-FR') ||  course_code_bk.includes('AL-FR') ||course_code_bk.includes('AS-FR') || course_code_bk.includes('AL/AS-FR') ){
        return ('subject.FR')
    }else if(course_code.toUpperCase()=='KO' || course_code_bk.includes('CAM-KO') ||  course_code_bk.includes('AL-KO') ||course_code_bk.includes('AS-KO') || course_code_bk.includes('AL/AS-KO') ){
        return ('subject.KO')
    }else{
        if(course_name && course_name.length>64){
            return course_name.substring(0, 64)+ ' ...'
        }else{
            return course_name
        }
    }
}


export const  dataURItoBlob=(dataURI)=>{
    let binary = atob(dataURI.split(',')[1]);
    let array = [];
    for(let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
}


export const randomColor=(id)=>{
    let mod = id%32
    let color = ""
    switch (mod){
        case 0:
            color="coursecolordefault"
            break
        case 1:
            color="courseMathcolor"
            break
        case 2:
            color="courseGCEDcolor"
            break
        case 3:
            color="courseVNHcolor"
            break
        case 4:
            color="color4"
            break
        case 5:
            color="color5"
            break
        case 6:
            color="color6"
            break
        case 7:
            color='color7'
            break
        case 8:
            color='color8'
            break
        case 9:
            color='color9'
            break
        case 10:
            color='color10'
            break
        case 11:
            color='color11'
            break
        case 12:
            color='color12'
            break
        case 13:
            color='color13'
            break
        case 14:
            color='color14'
            break
        case 15:
            color='color15'
            break
        case 16:
            color='color16'
            break
        case 17:
            color='color17'
            break
        case 18:
            color='color18'
            break
        case 29:
            color='color19'
            break
        case 20:
            color='color20'
            break
        case 21:
            color='color21'
            break
        case 22:
            color='color22'
            break
        case 23:
            color='color23'
            break
        case 24:
            color='color24'
            break
        case 25:
            color='color25'
            break
        case 26:
            color='color26'
            break
        case 27:
            color='color27'
            break
        case 28:
            color='color28'
            break
        case 29:
            color='color29'
            break
        case 30:
            color='color30'
            break
        default:
            color='color31'
            break
    }
    return color
}


export const getTicketStatus = (ticketStatus, t)=>{
    let status = ''
    switch (ticketStatus){
        case 1:
            status=t('ticket.newStatus')
            break
        case 2:
            status= t('ticket.processStatus')
            break
        case 3:
            status= t('ticket.processStatus')
            break
        case 4:
            status=t('ticket.pendingStatus')
            break
        case 5:
            status = t('ticket.solvedStatus')
            break
        case 6:
            status = t('ticket.closeStatus')
            break
        default:
            status =""
            break
    }
    return status
}


export const decodeEntities =(encodedString)=> {
    var translate_re = /&(nbsp|amp|quot|lt|gt);/g;
    var translate = {
        "nbsp":" ",
        "amp" : "&",
        "quot": "\"",
        "lt"  : "<",
        "gt"  : ">"
    };
    return encodedString.replace(translate_re, function(match, entity) {
        return translate[entity];
    }).replace(/&#(\d+);/gi, function(match, numStr) {
        var num = parseInt(numStr, 10);
        return String.fromCharCode(num);
    });
}
