import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    getNotifiesAPI,
    getUnreadNotificationAPI,
    readNotifyAPI,
    sendDeviceTokenAPI
} from "../../services/NofityService";

const initState = {
    status : 'idle',
    page:1,
    notifies:[],
    numberUnread:0
}

export const sendDeviceToken = createAsyncThunk('notify/sendDeviceToken', async(token)=>{
    let res =await sendDeviceTokenAPI(token)
    return res.data
})


export const getUnreadNotification = createAsyncThunk('notify/getUnreadNotify', async()=>{
    let res = await getUnreadNotificationAPI()
    return res.data
})

export const getNotifies = createAsyncThunk('notify/getNotifies', async(page)=>{
    let res = await getNotifiesAPI(page)
    return {
        notifies: res.data,
        page: page
    }
})

export const readNotify = createAsyncThunk('notify/readNotify', async(notifyId)=>{
    await  readNotifyAPI(notifyId)
    return notifyId
})

const NotifySlice = createSlice({
    name:'notify',
    initialState:initState,
    reducers:{
        setPage:(state, action)=>{
            state.page = action.payload
        }
    },
    extraReducers:builder => {
        builder.addCase(sendDeviceToken.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(sendDeviceToken.fulfilled, (state,action)=>{
            state.status = 'idle'
        }).addCase(sendDeviceToken.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(getNotifies.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getNotifies.fulfilled, (state,action)=>{
            state.status = 'idle'
            if(action.payload.page==1){
                state.notifies = [...state.notifies,...action.payload.notifies]
            }else{
                state.notifies= [...state.notifies,...action.payload.notifies]
            }

        }).addCase(getNotifies.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(readNotify.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(readNotify.fulfilled, (state,action)=>{
            state.status = 'idle'
            let notifies = [...state.notifies]
            for(let i =0; i< notifies.length; i++){
                if(notifies[i]._id == action.payload){
                    if(!notifies[i].read){
                        state.numberUnread = state.numberUnread -1
                        notifies[i].read = true
                    }


                    break
                }

            }
            state.notifies = [...notifies]
        }).addCase(readNotify.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(getUnreadNotification.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(getUnreadNotification.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(getUnreadNotification.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.numberUnread = action.payload
        })
    }
})

export const {setPage} = NotifySlice.actions
export default NotifySlice.reducer
