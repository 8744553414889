import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    getFilesInCourseAPI,
    getFilesInFolderAPI,
    getFolderInCourseAPI,
    getFolderInFolderAPI
} from "../../../services/FileService";


const initState = {
    status: 'ide',
    error: 'error',
    files: []
}

export const getFolderInCourse = createAsyncThunk('courseFileSlice/folderincourse', async(dataInput)=>{
    let folders = await getFolderInCourseAPI(dataInput.courseId)
    return {
        folders: folders.data
    }
})

export const getFoldersInFolder = createAsyncThunk('courseFileSlice/folderinfolder', async(dataInput)=>{
    let folders = await getFolderInFolderAPI(dataInput.folderId)
    return {
        folders:folders.data
    }
})

export const getFilesInFolder = createAsyncThunk('courseFileSlice/filesinfolder', async(dataInput)=>{
    let files = await getFilesInFolderAPI(dataInput.folderId)
    return {
        files:files.data
    }
})

export const getFilesInCourse = createAsyncThunk('courseFileSlice/filesincourse', async(dataInput)=>{
    let files = await getFilesInCourseAPI(dataInput.courseId)
    return {
        files:files.data
    }
})


export const CourseFileSlice = createSlice({
    name: 'courseFileSlice',
    initialState: initState,
    reducers:{
        setFile:(state,action)=>{
            state.files = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(getFolderInCourse.rejected,(state,action)=>{
            state.status = 'ide'
            state.error = action.error.message
        }).addCase(getFolderInCourse.pending, (state, action)=>{
            state.status = 'loading'
        }).addCase(getFoldersInFolder.pending, (state,action)=>{
                state.status = 'loading'
        }).addCase(getFoldersInFolder.rejected, (state,action)=>{
            state.status = 'ide'
            state.error = action.error.message
        }).addCase(getFilesInFolder.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(getFilesInFolder.fulfilled,(state,action)=>{
            state.files = action.payload.files
            state.status = 'ide'
        }).addCase(getFilesInFolder.rejected, (state,action)=>{
            state.status = 'ide'
            state.error = action.error.message
        }).addCase(getFilesInCourse.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(getFilesInCourse.fulfilled,(state,action)=>{
            state.files = action.payload.files
            state.status = 'ide'
        }).addCase(getFilesInCourse.rejected, (state,action)=>{
            state.status = 'ide'
            state.error = action.error.message
        })
    }
})


export const { setFile } = CourseFileSlice.actions
