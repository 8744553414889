import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {listYourCourseApi, getCoruseByIdApi} from "../services/CourseService";
import {listCourseModuleAPI, listCourseModuleAsyncAPI, listItemCourseModuleAPI} from "../services/ModuleService";
import {listQuizzesInCourseAPI} from "../services/quizService";
import {courseTabsAPI} from "../services/tabsService";

const initialState={
    couseId: null,
    CourseInfo:null,
    listModules:[],
    listQuizzes:[],
    listModuleStatus: 'ide',
    moduleItemStatus:'ide',
    listQuizStatus:'ide',
    listTabs:[],
    listTabsStatus:'ide',
    error:null
}



export const getListQuizzes = createAsyncThunk('coursedetail/listquizzes', async(dataInput)=>{
    let quizzes = await listQuizzesInCourseAPI(dataInput.course_id)
    return {
        quizzes:quizzes.data
    }
})

export const listCourseModule = createAsyncThunk('coursedetail/listcoursemodule', async(dataInput)=>{
    let courseModule = await listCourseModuleAPI(dataInput.course_id)
    return {
        courseModule: courseModule.data
    }
})


export const listCourseModuleAsync = createAsyncThunk('courseDetail/listCourseModuleAsync', async (courseId)=>{
    let courseModules = await listCourseModuleAsyncAPI(courseId)
    return {
        _courseId: courseId,
        courseModules:courseModules.data
    }
})


export const listItemCourseModule = createAsyncThunk('coursedetail/listitemscoursemodule', async(dataInput)=>{
    let items = await listItemCourseModuleAPI(dataInput.course_id, dataInput.module_id)
    return {
        moduleId: dataInput.module_id,
        items:items.data
    }
})

export const listCourseTabs = createAsyncThunk('coursedetail/listcourseTabs', async (dataInput)=>{
    let tabs = await courseTabsAPI(dataInput.courseId)
    return {
        tabs: tabs.data
    }
})

export const getCourseInfo = createAsyncThunk('coursedetail/getCourseInfo', async (courseId)=>{
    let courseInfo = await getCoruseByIdApi(courseId)
    return courseInfo.data
})

export const courseDetailSlice = createSlice({
    name: 'coursedetail',
    initialState:initialState,
    reducers: {
        setCourseId: (state, action)=>{
            state.couseId = action.payload
        }
    },
    extraReducers: builder => {
        builder.addCase(listCourseModule.pending, (state,action)=>{
            state.listModuleStatus = 'loading'
        })
            .addCase(listCourseModule.rejected, (state,action)=>{
                state.listModuleStatus = 'ide'
                state.error = action.error.message
            })
            .addCase(listCourseModule.fulfilled, (state,action)=>{
                state.listModuleStatus = 'ide'
                state.listModules= action.payload.courseModule
            })
            .addCase(listItemCourseModule.pending, (state,action)=>{
                state.moduleItemStatus='loading'
            })
            .addCase(listItemCourseModule.rejected,(state,action)=>{
                state.moduleItemStatus = 'ide'
                state.error = action.error.message
            })
            .addCase(listItemCourseModule.fulfilled,(state,action)=>{
                let modules = [...state.listModules]
                for(let i = 0 ; i< modules.length; i++){
                    if(modules[i].id == action.payload.moduleId){
                        modules[i].loading='done'
                        modules[i].items= action.payload.items
                    }
                }
                state.listModules = modules
            })
            .addCase(getListQuizzes.pending, (state, action)=>{
                state.listQuizStatus ='loading'
            })
            .addCase(getListQuizzes.fulfilled,(state,action)=>{
                state.listQuizStatus = 'ide'
                state.listQuizzes = action.payload.quizzes
            })
            .addCase(getListQuizzes.rejected, (state,action)=>{
                state.listQuizStatus = 'ide'
                state.error = action.error.message
            })
            .addCase(listCourseTabs.pending, (state, action)=>{
                state.listTabsStatus= 'loading'
            })
            .addCase(listCourseTabs.fulfilled, (state,action)=>{
                state.listTabs = action.payload.tabs
                state.listTabsStatus = 'ide'
            })
            .addCase(listCourseTabs.rejected, (state, action)=>{
                state.error = action.error.message
                state.listTabsStatus = 'ide'
            }).addCase(listCourseModuleAsync.pending, (state,action)=>{
                state.listModuleStatus = 'loading'
            }).addCase(listCourseModuleAsync.rejected, (state,action)=>{
                state.listModuleStatus = 'idle'
                state.error = action.error.message
        }).addCase(listCourseModuleAsync.fulfilled, (state,action)=>{
            state.listModuleStatus= 'idle'
            if(action.payload._courseId == state.couseId){
                state.listModules= action.payload.courseModules
            }
           
        }).addCase(getCourseInfo.pending, (state,action)=>{
            state.listModuleStatus = 'loading'
        }).addCase(getCourseInfo.rejected, (state,action)=>{
            state.listModuleStatus = 'idle'
            state.error = action.error.message
        }).addCase(getCourseInfo.fulfilled, (state,action)=>{
            state.listModuleStatus = 'idle'
            state.CourseInfo = action.payload
        })
    }
})
export const { setCourseId } = courseDetailSlice.actions