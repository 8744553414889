import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {addFollowUpAPI, getFollowUpAPI, getTicketDetailAPI} from "../../services/SupportService";

const initState  = {
    status: 'idle',
    ticket: null,
    followUps:[]
}

export const getTicketDetail = createAsyncThunk('ticketDetail/getDetail', async(ticketId)=>{
    let ticket = await getTicketDetailAPI(ticketId)
    return ticket.data
})

export const getFollowUp = createAsyncThunk('ticketDetail/getFollowUp', async(ticketId)=>{
    let followUps = await getFollowUpAPI(ticketId)
    return followUps.data
})

export const addFollowUp = createAsyncThunk('ticketDetail/addFollowUp', async({ticketId, message, fileUpload})=>{
    let followUp = await addFollowUpAPI(ticketId, message, fileUpload)
    return followUp.data
})

const ticketDetailSlice = createSlice({
    name:'ticketDetail',
    initialState: initState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getTicketDetail.pending, (state,action)=>{
            state.status = 'pending'
        }).addCase(getTicketDetail.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.ticket = action.payload
        }).addCase(getTicketDetail.rejected, (state,action)=>{
            state.staus = 'idle'
        }).addCase(getFollowUp.pending,(state,action)=>{
            state.status = 'pending'
        }).addCase(getFollowUp.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.followUps= action.payload
        }).addCase(getFollowUp.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(addFollowUp.pending,(state,action)=>{
            state.status = 'pending'
        }).addCase(addFollowUp.fulfilled, (state,action)=>{
            state.status = 'idle'
        }).addCase(addFollowUp.rejected, (state,action)=>{
            state.status = 'idle'
        })
    }
})



export default ticketDetailSlice.reducer
