import React, {Fragment} from 'react'
import {Menu, Transition} from "@headlessui/react";
import lionIcon from "../../assetes/images/icons/LION.svg";
import profileIcon from "../../assetes/images/icons/Profile2.svg";
import startIcon from "../../assetes/images/icons/star2.svg";
import settingIcon from "../../assetes/images/icons/setting2.svg";
import vectorIcon from "../../assetes/images/icons/Vector2.svg";
import logoutIcon from "../../assetes/images/icons/logout.svg";
import lmsVinschoolIcon from '../../assetes/images/icons/lmsVinschool.svg'
import eportfoliioIcon from '../../assetes/images/icons/portfolio.svg'
import guildlineIcon from '../../assetes/images/icons/guildeline.svg'
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {swapToV1} from "../../Features/authSlice";
import { useEffect } from 'react';
import { useState } from 'react';
export default function UserMenuDropdown({handleLogout, setOpenProfileModal, setOpenSupportModal}){
    const { t, i18n } = useTranslation();
    const userInfo = useSelector(state=> state.auth.user)
    const dispatch = useDispatch()
    const [guidelineUrl,setGuidelineUrl]= useState("")
    
    useEffect(()=>{
        if(i18n.language =='vn'){
            setGuidelineUrl("https://drive.google.com/file/d/1cZ3gs8X5eFtZ_G5xLpbuPkjFr4uf2HLc/view?usp=sharing")
        }else{
            setGuidelineUrl("https://drive.google.com/file/d/1CTyHbxFYmCKcZIip3HgCTz1YElnZlOh3/view?usp=sharing")
        }
        // if(i18n.language !='vn'){
        //     setGuidelineUrl("https://www.youtube.com/watch?v=yyoOwjjqg14")
        // }else{
        //     setGuidelineUrl("https://www.youtube.com/watch?v=WXd51GKWO8Q")
        // }

    },[])
    
    const onLogout = ()=>{
        handleLogout(true)
    }

    const handleNavigateV1=()=>{
        dispatch(swapToV1()).unwrap().then(data=>{
            window.open('https://lms.vinschool.edu.vn', '_self')
        }).catch(err=>{
            window.open('https://lms.vinschool.edu.vn', '_self')
        })

    }

    return <div>
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button className=" w-full px-4 py-2  text-sm font-medium
                                focus:outline-none">
                    <img alt={'avatar'} className={'w-[50px] h-[50px] rounded-full'}  src={(userInfo && userInfo.avatar_url)?userInfo.avatar_url:lionIcon}  />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top-right z-30 absolute right-0 mt-2 w-44 rounded-md divide-y divide-gray-100  shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        <Menu.Item>
                            <button
                                   onClick={()=>{setOpenProfileModal(true)}}
                                className="block px-4 py-2 text-sm text-gray-700 w-full hover:bg-gray-100">
                                <span className={'flex space-x-3'} ><img alt={'profile'} src={profileIcon} className={' w-6 h-6'} /><span>{t('UserMenuDropdown.profile')}</span></span>
                            </button>
                        </Menu.Item>
                    </div>
                    <div className="py-1">
                        <Menu.Item>
                            <a href="/report" className="block px-4 py-2 text-sm text-gray-700 w-full hover:bg-gray-100">
                                <span className={'flex space-x-3'} ><img alt={'start'} src={startIcon} className={' w-6 h-6'} /><span>{t('UserMenuDropdown.general_assessment')}</span></span>
                            </a>
                        </Menu.Item>
                    </div>
                    {/*<div className={'py-1'}>*/}
                    {/*    <Menu.Item>*/}
                    {/*        <a href="/setting" className="block px-4 py-2 text-sm text-gray-700 w-full hover:bg-gray-100">*/}
                    {/*            <span className='flex space-x-3'><img alt={'setting'}  src={settingIcon} className={'w-6 h-6'}/><span>{t('UserMenuDropdown.setting')}</span></span>*/}
                    {/*        </a>*/}
                    {/*    </Menu.Item>*/}
                    {/*</div>*/}

                    <div className={'py-1'}>
                        
                        <Menu.Item>
                            <a href={'/tickets'} target={"_self"} className="block px-4 py-2 text-sm text-gray-700 w-full hover:bg-gray-100">
                                <span className={'flex space-x-3'}><img alt={'vector'} src={vectorIcon} className={'w-6 h-6'}/><span>{t('UserMenuDropdown.help')}</span></span>
                            </a>
                        </Menu.Item>
                    </div>
                    <div className={'py-1'}>
                        <Menu.Item>
                            <button onClick={handleNavigateV1} className="block px-4 py-2 text-sm text-gray-700 w-full hover:bg-gray-100">
                                <span className={'flex space-x-3'}><img alt={'vector'} src={lmsVinschoolIcon} className={'w-6 h-6'}/><span>{t('UserMenuDropdown.lmsVinschool')}</span></span>
                            </button>
                        </Menu.Item>
                    </div>
                    <div className={'py-1'}>
                        <Menu.Item>
                            <a href="/eportfolio" className="block px-4 py-2 text-sm text-gray-700 w-full hover:bg-gray-100">
                                <span className='flex space-x-3'><img alt={'eportfolio'}  src={eportfoliioIcon} className={'w-6 h-6'}/><span>{t('UserMenuDropdown.eportfolio')}</span></span>
                            </a>
                        </Menu.Item>
                    </div>
                    <div className='py-1'>
                    <Menu.Item>
                            <a href={guidelineUrl} target={"_blank"} className="block px-4 py-2 text-sm text-gray-700 w-full hover:bg-gray-100">
                                <span className={'flex space-x-3'}><img alt={'vector'} src={guildlineIcon} className={'w-6 h-6'}/><span>{t('UserMenuDropdown.Guildline')}</span></span>
                            </a>
                        </Menu.Item>
                    </div>
                    <div className="py-1">
                        <Menu.Item>
                            <button  onClick={onLogout} className="block px-4 py-2 text-sm text-gray-700 w-full hover:bg-gray-100">
                                <span className={'flex space-x-3'}><img alt={'logout'} src={logoutIcon} className={'w-6 h-6'} /><span>{t('UserMenuDropdown.logout')}</span></span>
                            </button>
                        </Menu.Item>
                    </div>

                </Menu.Items>
            </Transition>
        </Menu>
    </div>
}
