import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {createNoteAPI, getNoteAPI} from "../../../services/NoteService";

const initState = {
    note: null,
    state: 'idle'
}

export const createNote =  createAsyncThunk(`note/createNote`, async(input)=>{
    let note = await createNoteAPI(input.note,input.link)
    return note.data
})
export const getNote = createAsyncThunk('note/getNote', async (link)=>{
    let note = await getNoteAPI(link)
    return note.data
})

const NoteSlice = createSlice({
    name:'note',
    initialState: initState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(createNote.pending, (state, action)=>{
            state.status = 'loading'
        }).addCase(createNote.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.note = action.payload
        }).addCase(createNote.rejected, (state,action)=>{
            state.status = 'idle'
            state.note =null
        }).addCase(getNote.pending,(state,action)=>{
            state.status = 'loading'
        }).addCase(getNote.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.note = action.payload
        }).addCase(getNote.rejected, (state,action)=>{
            state.status = 'idle'
            state.note = null
        })
    }
})


export default NoteSlice.reducer
