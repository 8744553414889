const {axiosPrivate} = require("./AxiosService");

exports.createNoteAPI = (note, link)=>{
    return axiosPrivate.post(`${process.env.REACT_APP_BACKEND}/note/createNote`,{
        link: link,
        note:note
    })
}
exports.getNoteAPI = (link)=>{
    return axiosPrivate.post(`${process.env.REACT_APP_BACKEND}/note/getNote`,{
        link: link
    })
}
