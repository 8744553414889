import React, {useEffect, useState} from 'react'
import BackIcon from '../../../assetes/images/icons/Back.svg'
import ProfileIcon from '../../../assetes/images/icons/Profile2.svg'
import StarIcon from '../../../assetes/images/icons/star2.svg'
import SettingIcon from '../../../assetes/images/icons/setting2.svg'
import Vector2 from '../../../assetes/images/icons/Vector2.svg'
import LogutIcon from '../../../assetes/images/icons/logout.svg'
import {useTranslation} from "react-i18next";
import eportfoliioIcon from '../../../assetes/images/icons/portfolio.svg'
import lmsVinschoolIcon from '../../../assetes/images/icons/lmsVinschool.svg'
import {swapToV1} from "../../../Features/authSlice";
import guildlineIcon from '../../../assetes/images/icons/guildeline.svg'
import {useDispatch} from "react-redux";
export default function SidebarLv2({userInfo, changeLv, setLogout, setOpenProfile, setOpenSupportModal}){
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch()

    const [guidelineUrl,setGuidelineUrl]= useState("")
    
    useEffect(()=>{
        if(i18n.language =='vn'){
            setGuidelineUrl("https://drive.google.com/file/d/1cZ3gs8X5eFtZ_G5xLpbuPkjFr4uf2HLc/view?usp=sharing")
        }else{
            setGuidelineUrl("https://drive.google.com/file/d/1CTyHbxFYmCKcZIip3HgCTz1YElnZlOh3/view?usp=sharing")
        }
        // if(i18n.language !='vn'){
        //     setGuidelineUrl("https://www.youtube.com/watch?v=yyoOwjjqg14")
        // }else{
        //     setGuidelineUrl("https://www.youtube.com/watch?v=WXd51GKWO8Q")
        // }

    },[])

    const handleNavigateV1=()=>{
        dispatch(swapToV1()).unwrap().then(data=>{
            window.open('https://lms.vinschool.edu.vn', '_self')
        }).catch(err=>{
            window.open('https://lms.vinschool.edu.vn', '_self')
        })

    }
    return<div className={'w-full'}>
        <span onClick={()=>{changeLv('lv1')}} className={'flex px-16 mb-5 text-sm font-light  w-full items-center hover:text-fontcolor1'}><img  src={BackIcon} className={'pr-2 '}/>  Quay lại</span>
        <ul className="flex flex-col list-none lg:flex-row lg:space-x-8 lg:mt-0 lg:text-sm lg:font-medium w-full ">
        <li className={'  mx-5  mb-5   rounded-3xl  w-full'}>
            <button onClick={()=>{setOpenProfile(true)}}
               className={' '}
               aria-current="page">
                            <span className={'flex items-center text-black font-semibold hover:text-fontcolor1'}>
                                <div className={' p-3  rounded-full mx-3 my-1'}>
                                    <img alt={'profile'} src={ProfileIcon} className={'text-bgColor11'} />
                                </div>
                                {t('sidebarlv2.profile')}
                            </span>
            </button>
        </li>
        <li className={'mx-5  mb-5   rounded-3xl w-full '}>
            <a href="/report"
               className={' '}
               aria-current="page">
                            <span className={'flex text-black items-center font-semibold hover:text-fontcolor1'}>
                                <div className={' p-3  rounded-full mx-3 my-1'}>
                                    <img alt={'rate'} src={StarIcon} className={''} />
                                </div>
                                {t("sidebarlv2.general_assessment")}
                            </span>
            </a>
        </li>
            <li className={'mx-5  mb-5   rounded-3xl w-full '}>
                <button onClick={handleNavigateV1}
                    className={' '}
                    aria-current="page">
                            <span className={'flex text-black items-center font-semibold hover:text-fontcolor1'}>
                                <div className={' p-3  rounded-full mx-3 my-1'}>
                                    <img alt={'rate'} src={lmsVinschoolIcon} className={''} />
                                </div>
                                {t("UserMenuDropdown.lmsVinschool")}
                            </span>
                </button>
            </li>
            <li className={'mx-5  mb-5  rounded-3xl w-full '}>
                <a href="/eportfolio"
                   className={' '}
                   aria-current="page">
                            <span className={'flex text-black items-center font-semibold hover:text-fontcolor1'}>
                                <div className={' p-3  rounded-full mx-3 my-1'}>
                                    <img alt={'rate'} src={eportfoliioIcon} className={''} />
                                </div>
                                {t("UserMenuDropdown.eportfolio")}
                            </span>
                </a>
            </li>

        {/*<li className={'  mx-5  mb-5 w-2/3  rounded-3xl w-full '}>*/}
        {/*    <a href="/setting"*/}
        {/*       className={'w-full '}*/}
        {/*       aria-current="page">*/}
        {/*                    <span className={'flex text-black items-center inline  font-semibold hover:text-fontcolor1 w-full'}>*/}
        {/*                        <div className={' p-3  rounded-full mx-3 my-1'}>*/}
        {/*                            <img alt={'setting'} src={SettingIcon} className={''} />*/}
        {/*                        </div>*/}
        {/*                        {t('sidebarlv2.setting')}*/}
        {/*                    </span>*/}
        {/*    </a>*/}
        {/*</li>*/}
        <li className={'  mx-5  mb-5 w-2/3  rounded-3xl '}>
            <a  href={'/tickets'} target={'_self'}
               className={' '}
               aria-current="page">
                            <span className={'flex text-black items-center font-semibold hover:text-fontcolor1'}>
                                <div className={' p-3  rounded-full mx-3 my-1'}>
                                    <img alt={'help'} src={Vector2} className={''} />
                                </div>
                                {t("sidebarlv2.help")}
                            </span>
            </a>
        </li>
        <li className={'  mx-5  mb-5 w-2/3  rounded-3xl '}>
            <a  href={guidelineUrl} target={'_self'}
               className={' '}
               aria-current="page">
                            <span className={'flex text-black items-center font-semibold hover:text-fontcolor1'}>
                                <div className={' p-3  rounded-full mx-3 my-1'}>
                                    <img alt={'help'} src={guildlineIcon} className={''} />
                                </div>
                                {t("sidebarlv2.guideline")}
                            </span>
            </a>
        </li>
        <li className={'  mx-5  mb-5 w-2/3  rounded-3xl '}>
            <a href="#"
               onClick={()=>{
                   setLogout(true)
               }
               }
               className={' '}
               aria-current="page">
                            <span className={'flex items-center text-black font-semibold hover:text-fontcolor1'}>
                                <div className={' p-2  rounded-full mx-3 my-1'}>
                                    <img alt={'logout'} src={LogutIcon} className={'w-7'} />
                                </div>
                                {t('sidebarlv2.logout')}
                            </span>
            </a>
        </li>
    </ul>
    </div>
}
