import {axiosPrivate, axiosPublic} from "./AxiosService";

export const createEPortfolioAPI = (name)=>{
    return axiosPrivate.post(`${process.env.REACT_APP_BACKEND}/portfolio/createPortfolio`,{
        name: name
    })
}

export const listEPortfolioAPI = ()=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/portfolio/listPortfolio`)
}

export const getPortfolioAPI=(portfolioId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/portfolio/getPortfolio?portfolioId=${portfolioId}`)
}

export const deletePortfolioAPI = (portfolioId)=>{
    return axiosPrivate.delete(`${process.env.REACT_APP_BACKEND}/portfolio/deletePortfolio?portfolioId=${portfolioId}`)
}

export const getListSectionAPI = (portfolioId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/portfolio/listSection?portfolioId=${portfolioId}`)
}

export const changeStatePortfolioAPI = (portfolioId, state)=>{
    return axiosPrivate.put(`${process.env.REACT_APP_BACKEND}/portfolio/updatePortfolio`,{
        id: portfolioId,
        state:state
    })
}

export const getPagesInSectionAPI = (sectionId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/portfolio/pagesInSection?sectionId=${sectionId}`)
}

export const updatePageAPI = (pageId, content)=>{
    return axiosPrivate.put(`${process.env.REACT_APP_BACKEND}/portfolio/updatePage`,{
        pageId: pageId,
        content: content
    })
}

export const listCurricularAPI = (eportfolioId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/portfolio/listCurricular?id=${eportfolioId}`)

}

export const createCurricularAPI = (name, imageBanner, subDescription, content, pageId)=>{
    return axiosPrivate.post(`${process.env.REACT_APP_BACKEND}/portfolio/createCurricular`,{
        name: name,
        imgBanner: imageBanner,
        subDescription: subDescription,
        content:content,
        pageId: pageId
    })
}

export const deleteCurricularAPI = (id)=>{
    return axiosPrivate.delete(`${process.env.REACT_APP_BACKEND}/portfolio/curricular?id=${id}`)
}

export const editCurricularAPI = (id, name, subDescription, content, imageBanner)=>{
    return axiosPrivate.put(`${process.env.REACT_APP_BACKEND}/portfolio/curricular`,{
        name: name,
        _id: id,
        subDescription: subDescription,
        content: content,
        imageBanner: imageBanner
    })
}

export const changeStateCurricularAPI = (id, state)=>{
    return axiosPrivate.put(`${process.env.REACT_APP_BACKEND}/portfolio/changStateCurricular`,{
        _id: id,
        state: state
    })
}

export const getPortfolioProfileAPI = (pageId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/portfolio/profile?pageId=${pageId}`)
}

export const updateProfileAPI = (avatar, bio, email, website, phone, address, viewAddress, viewPhone, viewWebsite, viewEmail, profileId)=>{
    return axiosPrivate.put(`${process.env.REACT_APP_BACKEND}/portfolio/profile`,{
        profileId: profileId,
        avatar:avatar,
        email:email,
        bio:bio,
        website:website,
        phone:phone,
        address:address,
        viewAddress:viewAddress,
        viewPhone:viewPhone,
        viewEmail:viewEmail,
        viewWebsite:viewWebsite
    })
}

export const getLearningHistoryAPI=()=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/portfolio/getLearningHistory`)
}

export const saveCertAPI = (certName, certKey, pageId)=>{
    return axiosPrivate.post(`${process.env.REACT_APP_BACKEND}/portfolio/saveCertificate`,{
        pageId: pageId,
        certName: certName,
        certKey: certKey
    })
}

export const getListCertAPI = (pageId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/portfolio/getCerts?pageId=${pageId}`)
}
export const getCertUploadUrlAPI = (fileName, fileType)=>{
    return  axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/portfolio/generateCertUploadUrl?fileName=${fileName}&fileType=${fileType}`)
}

export const delCertAPI= (certId)=>{
    return axiosPrivate.delete(`${process.env.REACT_APP_BACKEND}/portfolio/delCert?certId=${certId}`)
}
export const changeCertViewAPI =(certId, viewOption)=>{
    return axiosPrivate.put(`${process.env.REACT_APP_BACKEND}/portfolio/updateCertStatus`,{
        certId: certId,
        workflowState: viewOption
    })
}
export const getCertAPI = (certId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/portfolio/getCertById?certId=${certId}`)
}


export const getGradeSheetAPI= (schoolYearId, pageId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/portfolio/gradeSheet?id=${schoolYearId}&pageId=${pageId}`)
}

export const getGradeSheetPublishAPI= (schoolYearId, pageId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/portfolio/gradeSheetPublish?id=${schoolYearId}&pageId=${pageId}`)
}

export const updateGradeSheetViewAPI=(schoolYearId,type,viewOption, pageId)=>{
    return axiosPrivate.post(`${process.env.REACT_APP_BACKEND}/portfolio/updateGradeSheetView`,{
        pageId: pageId,
        schoolYearId:schoolYearId,
        type: type,
        viewOption:viewOption
    })
}

export const saveAdDocsAPI = (docName, docKey, pageId)=>{
    return axiosPrivate.post(`${process.env.REACT_APP_BACKEND}/portfolio/saveDoc`, {
        pageId: pageId,
        docName: docName,
        docKey: docKey
    })
}
export const getAdDocsAPI=(pageId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/portfolio/getAdDocs?pageId=${pageId}`)
}
export const delAdDocAPI=(docId)=>{
    return axiosPrivate.delete(`${process.env.REACT_APP_BACKEND}/portfolio/delAdDoc?docId=${docId}`)
}

export const getDocByIdAPI=(docId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/portfolio/getDocById?docId=${docId}`)
}

export const getPreviewAllPageAPI = (portfolioId)=>{
    return axiosPrivate(`${process.env.REACT_APP_BACKEND}/portfolio/getPreviewAllPage?portfolioId=${portfolioId}`)
}
export const getProfilePreviewAPI = (pageId)=>{
    return axiosPrivate(`${process.env.REACT_APP_BACKEND}/portfolio/profilePreview?pageId=${pageId}`)
}

export const getLearningHistoryPreviewAPI= (pageId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/portfolio/learningHistoryPreview?pageId=${pageId}`)
}

export const viewGradeSheetAPI = (type, schoolYearId, lmsUserId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/portfolio/viewGradeSheet?type=${type}&schoolYearId=${schoolYearId}&lmsUserId=${lmsUserId}`)
}

export const viewAdDocAPI = (docId, lmsUserId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/portfolio/viewAdDoc?docId=${docId}&lmsUserId=${lmsUserId}`)
}

export const listCertViewAPI = (pageId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/portfolio/listCertView?pageId=${pageId}`)
}

export const viewCertAPI = (certId, lmsUserId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/portfolio/viewCert?certId=${certId}&lmsUserId=${lmsUserId}`)
}

export const listCurricularPreviewAPI = (pageId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/portfolio/listCurricularPreview?pageId=${pageId}`)
}


export const getAllPageViewAPI = (portfolioId)=>{
    return axiosPublic(`${process.env.REACT_APP_BACKEND}/portfolio/getAllPageViewPortfolio?portfolioId=${portfolioId}`)
}
export const getProfileViewAPI = (pageId, portfolioId)=>{
    return axiosPublic(`${process.env.REACT_APP_BACKEND}/portfolio/getProfileView?pageId=${pageId}&portfolioId=${portfolioId}`)
}


export const getLearningHistoryViewAPI= (pageId, portfolioId)=>{
    return axiosPublic.get(`${process.env.REACT_APP_BACKEND}/portfolio/getLearningHistoryView?pageId=${pageId}&portfolioId=${portfolioId}`)
}


export const viewGradeSheetPublishAPI = (type, schoolYearId, lmsUserId, portfolioId)=>{
    return axiosPublic.get(`${process.env.REACT_APP_BACKEND}/portfolio/viewGradeSheetPublish?type=${type}&schoolYearId=${schoolYearId}&lmsUserId=${lmsUserId}&portfolioId=${portfolioId}`)
}


export const viewAdDocPublishAPI = (docId, lmsUserId, portfolioId)=>{
    return axiosPublic.get(`${process.env.REACT_APP_BACKEND}/portfolio/viewAdDocPublish?docId=${docId}&lmsUserId=${lmsUserId}&portfolioId=${portfolioId}`)
}


export const listCertViewPublishAPI = (pageId,portfolioId)=>{
    return axiosPublic.get(`${process.env.REACT_APP_BACKEND}/portfolio/listCertViewPublish?pageId=${pageId}&portfolioId=${portfolioId}`)
}

export const viewCertPublishAPI = (certId, lmsUserId,portfolioId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/portfolio/viewCertPublish?certId=${certId}&lmsUserId=${lmsUserId}&portfolioId=${portfolioId}`)
}

export const listCurricularViewAPI = (pageId, portfolioId, lmsUserId)=>{
    return axiosPublic.get(`${process.env.REACT_APP_BACKEND}/portfolio/listCurricularView?pageId=${pageId}&portfolioId=${portfolioId}&lmsUserId=${lmsUserId}`)
}


export const getSOLetterAPI = (pageId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/portfolio/introletterFromSO?pageId=${pageId}`)
}

export const changeLetterStateAPI=(pageId, state, soId)=>{
    return axiosPrivate.post(`${process.env.REACT_APP_BACKEND}/portfolio/changeLetterState`,{
        pageId: pageId,
        state:state,
        soId:soId
    })
}

export const getListLetterPublishAPI = (pageId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/portfolio/getListLetterPublish?pageId=${pageId}`, )
}

export const getListLetterPublishViewAPI = (pageId, lmsUserId, portfolioId)=>{
    return axiosPublic.get(`${process.env.REACT_APP_BACKEND}/portfolio/getListLetterPublishView?pageId=${pageId}&id=${portfolioId}&lmsUserId=${lmsUserId}`)
}
