import { useState } from 'react'
import { Switch } from '@headlessui/react'

export default function SwitchSimple({enabled, change, label }) {

    return (
        <div className={'flex space-x-2'}>
            <span>{label}</span>
            <Switch
                checked={enabled}
                onChange={change}
                className={`${
                    enabled ? 'bg-blue-600' : 'bg-gray-200'
                } relative inline-flex h-6 w-11 items-center rounded-full`}
            >
            <span
                className={`${
                    enabled ? 'translate-x-6' : 'translate-x-1'
                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
            </Switch>
        </div>

    )
}
