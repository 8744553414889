import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {listPlannerAPI, listYourTimeTableApi} from "../services/TimeTableService";

const initialState={
    timeTables:[],
    list_planner:[],
    status: 'ide',
    error:null
}

export const listYourTimeTable = createAsyncThunk('timetable/listtimetable', async (dataInput)=>{
   let timetables = await  listYourTimeTableApi( dataInput.date)
    return {
       timetables: timetables.data
    }
})

export const listPlanner = createAsyncThunk('timetable/listPlanner', async (dataInput)=>{
    let _listPlanner =await listPlannerAPI(dataInput.start_date, dataInput.end_date)
    return {list_planner: _listPlanner.data}
})

export const timeTableSlice = createSlice({
    name: 'timeTable',
    initialState:initialState,
    reducers: {

    },
    extraReducers:builder => {
        builder.addCase(listYourTimeTable.pending, (state,action)=>{
            state.status = 'loading'
        })
            .addCase(listYourTimeTable.rejected, (state,action)=>{
                state.status = 'ide'
                state.error = action.error.message
            })
            .addCase(listYourTimeTable.fulfilled, (state,action)=>{
                state.status = 'ide'
                state.timeTables = action.payload.timetables
            })
            .addCase(listPlanner.pending, (state,action)=>{
                state.status = 'loading'
            })
            .addCase(listPlanner.rejected, (state,action)=>{
                state.status = 'ide'
                state.error = action.error.message
            })
            .addCase(listPlanner.fulfilled,(state, action)=>{
                state.status = 'ide'
                state.list_planner = action.payload.list_planner
            })

    }
})
