import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getPageDetailAPI} from "../../services/PageService";


const initState = {
    page: null,
    status:'idle'
}

export const getPageDetail = createAsyncThunk('pageContent/getPageDetail', async(input)=>{
    let page = await getPageDetailAPI(input.courseId, input.pageId)
    return page.data
})

export const pageContentSlice= createSlice({
    name:'pageContentSlice',
    initialState: initState,
    reducers:{},
    extraReducers:builder => {
        builder.addCase(getPageDetail.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(getPageDetail.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.page = action.payload
        }).addCase(getPageDetail.rejected, (state,action)=>{
            state.status = 'idle'
        })
    }
})

export default pageContentSlice.reducer
