import {createAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {listYourEnrollApi, listYourEnrollInvitedAPI, replyInviteAPI} from "../services/enrollService";

const sortByDate = arr => {
    const sorter = (a, b) => {
        return -1 *( new Date(a.last_activity_at).getTime() - new Date(b.last_activity_at).getTime());
    }
    arr.sort(sorter);
};


const initialState={
    listEnroll:[],
    enrollInvited:[],
    enrollSelect:null,
    status: 'ide',
    error:null
}


export const listEnroll = createAsyncThunk('enroll/listEnroll', async ()=>{
    let listEnroll = await listYourEnrollApi()
    return {
        listEnroll: listEnroll.data
    }
})

export const listEnrollInvited= createAsyncThunk('enroll/enrollInvited', async()=>{
    let enrollInvited= await listYourEnrollInvitedAPI()
    return enrollInvited.data
})

export const replyEnrollInvite = createAsyncThunk('enroll/replyEnroll', async({courseId, enrollId, state})=>{
    let result = await replyInviteAPI(courseId, enrollId, state)
    return enrollId
})

export const enrollSlice = createSlice({
    name: 'enrolls',
    initialState:initialState,
    reducers: {

    },
    extraReducers:builder => {
            builder.addCase(listEnroll.pending, (state,action)=>{
                state.status = 'loading'
            })
            .addCase(listEnroll.rejected, (state, action)=>{
                state.status = 'ide'
                state.error = action.error.message
            })
            .addCase(listEnroll.fulfilled, (state,action)=>{
                state.status = 'ide'
                sortByDate(action.payload.listEnroll)
                state.listEnroll = action.payload.listEnroll
            }).addCase(listEnrollInvited.pending, (state,action)=>{
                state.status = 'pending'
            }).addCase(listEnrollInvited.rejected, (state,action)=>{
                state.status = 'idle'
            }).addCase(listEnrollInvited.fulfilled, (state,action)=>{
                state.status = 'idle'
                state.enrollInvited= action.payload
            }).addCase(replyEnrollInvite.pending, (state,action)=>{
                state.status = 'pending'
            }).addCase(replyEnrollInvite.rejected,(state,action)=>{
                state.status = 'idle'
            }).addCase(replyEnrollInvite.fulfilled, (state,action)=>{
                state.status = 'idle'
                let inviteEnroll = [...state.enrollInvited]
                for(let i=0; i< inviteEnroll.length; i++){
                    if(inviteEnroll[i].id== action.payload){
                        inviteEnroll.splice(i, 1)
                        break
                    }
                }
                state.enrollInvited= [...inviteEnroll]
            })
    }
})
