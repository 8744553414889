import {axiosPrivate} from "./AxiosService";

export const listYourCourseApi = ()=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/course/yourcourse`)
}

export const listYourCompleteCourseApi = ()=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/course/yourcompletecourse`)
}

export const getCoruseByIdApi = (courseId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/course/coursedetail?courseId=${courseId}`)
}


export const processInfoAPI = (course_id, start_date, end_date)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/module/getProcessByWeek?courseId=${course_id}&startDate=${start_date}&endDate=${end_date}`)
}

export const getListWeekAPI = ()=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/course/listWeek`)
}
