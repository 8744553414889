import React from 'react'
import homeIcon from "../../../assetes/images/icons/home.svg";
import courseIcon from "../../../assetes/images/icons/course-icon.svg";
import calendarIcon from "../../../assetes/images/icons/calendar.svg";
import inboxIcon from "../../../assetes/images/icons/inbox.svg";
import lionIcon from "../../../assetes/images/icons/LION.svg";
import {useTranslation} from "react-i18next";

export default function SidebarLv1({userInfo, changeLv}){
    const { t, i18n } = useTranslation();
    return <ul className="flex flex-col list-none lg:flex-row lg:space-x-8 lg:mt-0 lg:text-sm lg:font-medium w-full ">
        <li className={'  mx-5  mb-5 w-2/3  rounded-3xl '}>
            <a href="/"
               className={' '}
               aria-current="page">
                            <span className={'flex items-center text-black font-semibold hover:text-fontcolor1'}>
                                <div className={' p-3  rounded-full mx-3 my-1'}>
                                    <img alt={'home'} src={homeIcon} className={''} />
                                </div>
                                {t('sidebarlv1.home')}
                            </span>
            </a>
        </li>
        <li className={'mx-5  mb-5 w-2/3  rounded-3xl '}>
            <a href="/khoahoc"
               className={' '}
               aria-current="page">
                            <span className={'flex text-black items-center font-semibold hover:text-fontcolor1'}>
                                <div className={' p-3  rounded-full mx-3 my-1'}>
                                    <img alt={'course'} src={courseIcon} className={''} />
                                </div>
                                {t('sidebarlv1.courses')}
                            </span>
            </a>
        </li>
        <li className={'  mx-5  mb-5 w-2/3  rounded-3xl '}>
            <a href="/thoikhoabieu"
               className={' '}
               aria-current="page">
                            <span className={'flex text-black items-center  font-semibold hover:text-fontcolor1'}>
                                <div className={' p-3  rounded-full mx-3 my-1'}>
                                    <img alt={'calendar'} src={calendarIcon} className={''} />
                                </div>
                                {t('sidebarlv1.schedule')}
                            </span>
            </a>
        </li>
        <li className={'  mx-5  mb-5 w-2/3  rounded-3xl '}>
            <a href="/inbox"
               className={' '}
               aria-current="page">
                            <span className={'flex text-black items-center font-semibold hover:text-fontcolor1'}>
                                <div className={' p-3  rounded-full mx-3 my-1'}>
                                    <img alt={'inbox'} src={inboxIcon} className={''} />
                                </div>
                                {t('sidebarlv1.inbox')}
                            </span>
            </a>
        </li>
        <li className={'  mx-5  mb-5 w-2/3  rounded-3xl '}>
            <a href="#"
               onClick={()=>{
                changeLv('lv2')
               }
               }
               className={' '}
               aria-current="page">
                            <span className={'flex items-center text-black font-semibold hover:text-fontcolor1'}>
                                <div className={' p-2  rounded-full mx-3 my-1'}>
                                    <img alt={'avatar'} src={(userInfo && userInfo.avatar_url)?userInfo.avatar_url:lionIcon} className={'w-7 rounded-full'} />
                                </div>
                                {t('sidebarlv1.account')}
                            </span>
            </a>
        </li>
    </ul>
}
