import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getUrlAPI} from "../../services/swbService";

const initState={
    status: 'idle',
    url: null
}

export const createUrl = createAsyncThunk('swb/geturl', async()=>{
    let url = getUrlAPI()
    return (await url).data
})
const SwbSlice = createSlice({
    name: 'swb',
    initialState: initState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(createUrl.pending,(state,action)=>{
            state.status = 'loading'
        }).addCase(createUrl.rejected, (state,action)=>{
            state.status = 'idle'
        }).addCase(createUrl.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.url = action.payload
        })

    }
})

export default SwbSlice.reducer

