import {axiosPrivate} from "./AxiosService";

export const listYourTimeTableApi = ( date)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/schedule/yourschedule?date=${date}`)
}


export const listPlannerAPI = (start_date, end_date)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/schedule/planner?start_date=${start_date}&end_date=${end_date}`)
}
