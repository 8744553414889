import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {listCourseSectionAPI} from "../../../services/SectionService";


const initialState = {
    sections: [],
    status: 'ide',
    error: null,
}

export const listCourseSection = createAsyncThunk('dicussionsection/listcorusesection', async(dataInput)=>{
    let sections= await listCourseSectionAPI(dataInput.courseId)
    return {
        sections: sections.data
    }
})


export const DiscussionSectionSlice = createSlice({
    name: 'DiscussionSectionSlice',
    initialState: initialState,
    reducers: {

    },
    extraReducers: builder => {
        builder.addCase(listCourseSection.pending, (state,action)=>{
            state.status = 'loading'
        })
            .addCase(listCourseSection.rejected, (state,action)=>{
                state.status = 'ide'
                state.error = action.error.message
            })
            .addCase(listCourseSection.fulfilled, (state, action)=>{
                state.status = 'ide'
                state.sections = action.payload.sections
            })
    }
})
