import {configureStore} from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {combineReducers} from "redux";
import thunk from 'redux-thunk'
import {authSlice} from '../Features/authSlice';
import {coursesSlice} from "../Features/coursesSlice";
import {enrollSlice} from "../Features/enrollSlice";
import {timeTableSlice} from "../Features/timeTableSlice";
import {courseDetailSlice} from "../Features/courseDetailSlice";
import {assignmentSlice} from "../Features/assignmentSlice";
import {gradeSlice} from "../pages/CourseDetail/Grade/gradeSlice";
import {AssignmentSubmissionSlice} from "../pages/CourseContent/Assignment/AssingmentSubmissionSlice";
import {CourseFileSlice} from "../pages/CourseDetail/Files/CourseFileSlice";
import {DiscussionSlice} from "../pages/CourseDetail/Dicussions/DiscussionSlice";
import {DiscussionSectionSlice} from "../pages/CourseDetail/Dicussions/DicussionSectionSlice";
import {DiscussionEntrySlice} from "../pages/CourseDetail/Dicussions/DiscussionEntrySlice";
import {InboxSlice, inboxSlice} from "../pages/Inbox/InboxSlice";
import {InboxCourseSlice} from "../pages/Inbox/InboxCourseSlice";
import {InboxContactSlice} from "../pages/Inbox/InboxContractSlice";
import {ConversationFileSlice} from "../pages/Inbox/ConversationFileSlice";
import PageContextSliceReducer from '../pages/pageContent/PageContentSlice'
import NotifyReducer from '../components/navbar/NotifySlice'
import NoteReducer from '../components/sidebar/NoteToolBar/NoteSlice'
import AvatarReducer from '../components/navbar/avatarSlice'
import IntroReducer from '../pages/CourseDetail/Intro/IntroSlice'
import SupportReducer from '../components/navbar/SupportSlice'
import ReportReducer from '../pages/reports/reportSlice'
import EPortfolioReducer from '../pages/Eportfoliios/EPortfolioSlice'
import DashboardReducer from '../pages/Dashboard/DashboardSlice'
import CurricularReducer from '../pages/Eportfoliios/pageSections/CurricularSlice'
import PortfolioProfileReducer from '../pages/Eportfoliios/pageSections/PortfolioProfileSlice'
import LearningHistoryReducer from '../pages/Eportfoliios/pageSections/LearningHistorySlice'
import certReducer from '../pages/Eportfoliios/pageSections/CertSlice'
import adDocReducer from '../pages/Eportfoliios/pageSections/DocSlice'
import epPreviewReducer from '../pages/Eportfoliios/PortfolioPreview/PreviewSlice'
import portfolioViewReducer from '../pages/Eportfoliios/portfolioView/PortfolioViewSlice'
import introLetterReducer from '../pages/Eportfoliios/pageSections/IntroLetterSlice'
import swbReducer from '../components/swb/SWBSlice'
import teacherCommentReducer from '../pages/TeacherComments/TeacherCommentSlice'
import announcementReducer from '../pages/Announcements/AnnouncementSlilce'
import ticketDetailReducer from '../pages/TicketItem/TicketDetailSlice'
import cliseReducer from '../pages/Clise/CliseSlice'
const persistConfig = {
    key: 'lms-v2',
    storage,
    whitelist: ['auth']
};
const reducers = combineReducers({
    auth:authSlice.reducer,
    course:coursesSlice.reducer,
    enroll:enrollSlice.reducer,
    timetable:timeTableSlice.reducer,
    coursedetail: courseDetailSlice.reducer,
    assignment:assignmentSlice.reducer,
    grade: gradeSlice.reducer,
    assignmentSubmission: AssignmentSubmissionSlice.reducer,
    courseFileSlice: CourseFileSlice.reducer,
    DiscussionSlice: DiscussionSlice.reducer,
    DiscussionSectionSlice: DiscussionSectionSlice.reducer,
    DiscussionEntrySlice:DiscussionEntrySlice.reducer,
    InboxSlice: InboxSlice.reducer,
    InboxCourseSlice: InboxCourseSlice.reducer,
    InboxContactSlice: InboxContactSlice.reducer,
    ConversationFileSlice: ConversationFileSlice.reducer,
    pageContentSlice: PageContextSliceReducer,
    notifyReducer:NotifyReducer,
    noteReducer:NoteReducer,
    avatar:AvatarReducer,
    intro: IntroReducer,
    support: SupportReducer,
    report:ReportReducer,
    portfolio:EPortfolioReducer,
    dashboard:DashboardReducer,
    curricular: CurricularReducer,
    portfolioProfile: PortfolioProfileReducer,
    learningHistory:LearningHistoryReducer,
    cert:certReducer,
    adDoc:adDocReducer,
    epPreview:epPreviewReducer,
    portfolioView:portfolioViewReducer,
    introLetter: introLetterReducer,
    swb: swbReducer,
    teacherComment: teacherCommentReducer,
    announcement: announcementReducer,
    ticketDetail: ticketDetailReducer,
    clise:cliseReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    middleware:[thunk]
})
