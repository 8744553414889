import {axiosPrivate} from "./AxiosService";

export const submitAssignmentAPI = (course_id, assignment_id, assingmentData)=>{
    return axiosPrivate.post(`${process.env.REACT_APP_BACKEND}/submission/submitassignment`, {
        courseid: course_id,
        assignmentid: assignment_id,
        textbody: assingmentData.body,
        url: assingmentData.url,
        file_ids: assingmentData.file_ids,
        type: assingmentData.type,
        comment:assingmentData.comment
    })
}

export const getQuizSubmissionAPI = (courseId, quizId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/submission/quizsubmissions?courseid=${courseId}&quizid=${quizId}`)
}


export const getAllQuizSubmissionAPI = (courseId, quizId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/submission/allquizsubmissions?courseid=${courseId}&quizid=${quizId}`)
}


export const getQuizSubmissionHistoryAPI =(submissionId, version)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/submission/quizsubmissionhistory?quizsubmissionid=${submissionId}&version=${version}`)
}


export const createQuizSubmissionAPI=(courseId, quizId, accessCode)=>{
    return axiosPrivate.post(`${process.env.REACT_APP_BACKEND}/submission/createquizsubmission`, {
        courseId:courseId,
        quizId: quizId,
        accessCode:accessCode
    })
}


export const getSubmissonQuestionAPI = (submissionid, courseId, quizId, attempt)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/submission/getquizsubmissionquestion?submissionid=${submissionid}&courseId=${courseId}&quizId=${quizId}&attempt=${attempt}`)
}


export const answerQuestionsAPI = (submissionId, validateToken,accessCode,quizQuestions,attempt)=>{
    return axiosPrivate.post(`${process.env.REACT_APP_BACKEND}/submission/answerquestions`, {
        submissionId:submissionId,
        validateToken:validateToken,
        accessCode:accessCode,
        quizQuestions:quizQuestions,
        attempt:attempt
    })
}

export const getQuestiontextAPI =(quesitonId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/submission/questiontext?questionid=${quesitonId}`)
}

export const getQuizSubmissionTimeAPI = (courseId, quizId, submissionId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/submission/quizsubmissiontime?courseid=${courseId}&quizid=${quizId}&submissionid=${submissionId}`)
}

export const getAssignmentSubmissionDetailAPI = (courseId, assignmentId)=>{
    return axiosPrivate.get(`${process.env.REACT_APP_BACKEND}/submission/assignmentsubmissiondetail?courseid=${courseId}&assignmentid=${assignmentId}`)
}

export const completeQuizSubmissionAPI = (courseId, quizId, submissionId, validationToken, attempt, accessCode)=>{
    return axiosPrivate.post(`${process.env.REACT_APP_BACKEND}/submission/completequizsubmission`,{
        courseId: courseId,
        quizId:quizId,
        submissionId:submissionId,
        validationToken:validationToken,
        attempt:attempt,
        accessCode:accessCode
    })
}


export const changeFlagAPI = (quizSubmissionId, questionId, validationToken, attempt, accessCode, flag)=>{
    return axiosPrivate.post(`${process.env.REACT_APP_BACKEND}/submission/changeflag`,{
        quizSubmissionId: quizSubmissionId,
        questionId:questionId,
        validationCode:validationToken,
        attempt:attempt,
        flag: flag,
        accessCode: accessCode
    })
}
