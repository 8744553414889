import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getGradePeriodsForCourseAPI} from "../../../services/grade";
import {allAssignmentInCourseAPI} from "../../../services/AssignmentService";


const initialState={
    courseId:null,
    gradePeriods:[],
    assignments:[],
    status: 'ide',
    assignmentStatus:'ide',
    error:null
}


export const getAllGradePeriodsForCourse=createAsyncThunk('grade/gradeperiodsforcoruse', async(dataInput)=>{
    let gradePeriods = await getGradePeriodsForCourseAPI(dataInput.courseId)
    return {
        courseId: dataInput.courseId,
        gradePeriods: gradePeriods.data.grading_periods
    }
})

export const getAllAssignmentsForCourse = createAsyncThunk('grade/assignments', async (dataInput)=>{
    let assignments = await allAssignmentInCourseAPI(dataInput.courseId)
    return {
        assignments: assignments.data
    }
})

export const gradeSlice = createSlice({
    name: 'grade',
    initialState: initialState,
    reducers:{

    },
    extraReducers:builder => {
        builder.addCase(getAllGradePeriodsForCourse.pending, (state,action)=>{
            state.status = 'loading'
        })
            .addCase(getAllGradePeriodsForCourse.fulfilled,(state,action)=>{
                state.status = 'ide'
                state.courseId=action.payload.courseId
                state.gradePeriods=action.payload.gradePeriods
            })
            .addCase(getAllGradePeriodsForCourse.rejected,(state,action)=>{
                state.status= 'ide'
                state.error = action.error.message
            })
            .addCase(getAllAssignmentsForCourse.pending, (state,action)=>{
                state.status = 'loading'
            })
            .addCase(getAllAssignmentsForCourse.fulfilled,(state,action)=>{
                state.status = 'ide'
                state.assignments= action.payload.assignments
            })
            .addCase(getAllAssignmentsForCourse.rejected,(state, action)=>{
                state.status = 'ide'
                state.error = action.error.message
            })
    }
})
