import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getAssignmentSubmissionDetailAPI} from "../../../services/SubmissionService";

const initialState={
    courseId: null,
    assignmentId:null,
    submission:null,
    error:null,
    status:'ide'
}


export const getAssignmentSubmission = createAsyncThunk('assignmentsubmission/getassignmentsubmissiondetail', async(dataInput)=>{
    let assignmentSubmission = await getAssignmentSubmissionDetailAPI(dataInput.courseId, dataInput.assignmentId)
    return {
        submission: assignmentSubmission.data,
        courseId: dataInput.courseId,
        assignmentId: dataInput.assignmentId
    }
})

export const AssignmentSubmissionSlice = createSlice({
    name:'assignmentSubmission',
    initialState:initialState,
    reducers:{

    },
    extraReducers: builder => {
        builder.addCase(getAssignmentSubmission.pending, (state,action)=>{
            state.status = 'loading'
        })
            .addCase(getAssignmentSubmission.fulfilled, (state,action)=>{
                state.status= 'ide'
                state.courseId = action.payload.courseId
                state.assignmentId = action.payload.assignmentId
                state.submission = action.payload.submission
            })
            .addCase(getAssignmentSubmission.rejected, (state,action)=>{
                state.status= 'ide'
                state.error = action.error.message
            })
    }
})


