import React, {useTransition} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {logOut} from "../../Features/authSlice";
import {useNavigate} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from "react-i18next";
export default function Logout({logout}){
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch()
    const refreshToken = useSelector(state=>state.auth.jwtRefeshtoken)
    const navigate = useNavigate()
    const handle_logout = ()=>{
        dispatch(logOut(refreshToken)).unwrap().then((data)=>{
            window.open('/login','_self')
        }).catch(err=>{
            // toast.error(err.message)
            window.open('/login', '_self')
        })
    }
    return <div className={'flex w-full h-screen justify-center items-center'}>
        <div className={'bg-white rounded-2xl flex-col justify-center p-5 min-w-[350px] '}>
            <h1 className={'font-semibold text-lg mx-auto text-center pb-3'}>{t('Logout.confirmLogout')}</h1>
            <div className={'flex space-x-2 w-full'}>
                <button onClick={handle_logout} className={'px-5 py-2 bg-fontcolor4 text-white font-semibold rounded-xl w-1/2'}>{t('Logout.confirm')}</button>
                <button onClick={()=>{logout(false)}}  className={'px-5 py-2 bg-fontcolor5 text-black font-semibold rounded-xl w-1/2'}>{t('Logout.reject')}</button>
            </div>
        </div>

    </div>
}
