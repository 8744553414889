import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getIntroPageAPI} from "../../../services/PageService";

const initState = {
    page: null,
    status:'idle'
}

export const getPageIntro = createAsyncThunk('intro/getpage',async(courseId)=>{
    let page = await getIntroPageAPI(courseId)
    return page.data
})

const IntroSlice = createSlice({
    name:'intro',
    initialState: initState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getPageIntro.pending, (state,action)=>{
            state.status = 'loading'
        }).addCase(getPageIntro.fulfilled, (state,action)=>{
            state.status = 'idle'
            state.page= action.payload
        }).addCase(getPageIntro.rejected, (state,action)=>{
            state.status = 'idle'
        })
    }

})

export default IntroSlice.reducer
