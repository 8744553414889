import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import closeIcon from '../../assetes/images/icons/close2.svg'
import { useTranslation } from 'react-i18next';
const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

export default function DropzoneComponent ({files, setFiles}) {
  const {t, i18n} = useTranslation()
    const {getRootProps, getInputProps} = useDropzone({
      accept: {
            'image/*': [],
            'video/*':[],
            'audio/*':[],
            'application/vnd.ms-excel':[],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document':[],
            'application/docx':[],
            'application/pdf':[],
            'text/plain':[],
            'application/msword':[],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':[]

      },
      onDrop: acceptedFiles => {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file),
          fileObject: file,
          fileType: file.type
        })));
      }
    });

    const handleRemoveFile =(index)=>{
      let _files = [...files]
      _files.splice(index,1)
      setFiles(_files)
    }
    
    const thumbs = files.map((file, ind) => (
      <div style={thumb} key={file.name}>
        <div className='relative' style={thumbInner}>
        
          {file.type.includes('image')?<img
            src={file.preview}
            style={img}
            // Revoke data uri after image is loaded
            onLoad={() => { URL.revokeObjectURL(file.preview) }}
          />:<>{file.name}</>}
          <button onClick={()=>{handleRemoveFile(ind)}} className='absolute right-0 top-0'>
            <img  src={closeIcon} />
          </button>
        </div>
      </div>
    ));
  
    useEffect(() => {
      // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
      return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);
  
    return (
      <section className="container pt-2">
        <div  {...getRootProps({className: 'dropzone'})}>
          <input {...getInputProps()} />
          <p className='border  border-dashed px-3 py-3 flex justify-center w-full'>{t("Drag and drop some files here or click to select files")}</p>
        </div>
        <aside style={thumbsContainer}>
          {thumbs}
        </aside>
      </section>
    );
  }