import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {findRecipientAPI} from "../../services/inboxService";

const initialState = {
    contacts : [],
    status : 'ide',
    error: null
}

export const findRecipients= createAsyncThunk('inboxcontact/findrecipients', async(input)=>{
    let recipients = await findRecipientAPI(input.search, input.courseid, input.context)
    return {
        recipients: recipients.data
    }
})
export const InboxContactSlice= createSlice({
    name: 'InboxContactSlice',
    initialState: initialState,
    reducers: {

    },
    extraReducers: builder=>{
        builder.addCase(findRecipients.pending,(state,action)=>{
            state.status = 'loading'
        }).addCase(findRecipients.fulfilled, (state,action)=>{
            state.status = 'ide'
            state.contacts = action.payload.recipients
            state.error = null
        }).addCase(findRecipients.rejected, (state, action)=>{
            state.status = 'ide'
            state.error = action.error.message
        })
    }
})

