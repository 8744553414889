import React  from 'react';
import { Editor } from '@tinymce/tinymce-react';
import {getFileUploadUrlS3API} from "../../services/FileService";
import axios from "axios";

export default function RCE({editorRef, onBlur, content}) {
    const image_upload_handler_callback = async (blobInfo, success, failure, progress) => {
        try{
            let fileType=blobInfo.blob().type
            let fileUrl = await getFileUploadUrlS3API(blobInfo.filename(), fileType)

            await axios.put(fileUrl.data.signedRequest, blobInfo.blob(), {
                headers: { "Content-Type": fileType },
            });
            success(fileUrl.data.url);

        }catch (err){
            failure(err.messages)
        }
    };
    return (
        <>

            <Editor
                onBlur={onBlur}
                tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue={content?content:"<p></p>"}
                init={{
                    skin:"newskin",
                    images_upload_handler: image_upload_handler_callback,
                    height: 300,
                    menubar: true,
                    plugins: [
                        'eqneditor advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar: 'undo redo |  eqneditor link image | formatselect | ' +
                        'bold italic backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    content_style: `
                      body {
                          background: #ecf3f5;
                      }`
                }}
            />
        </>
    );
}
